import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import MenuCategory from './components/MenuCategory';
import MenuButton from './components/MenuButton';
import { toggleSidebar } from '../../../actions/sidebar-actions';

import './styles/_sidebar.scss';

const Sidebar = ({ history }) => {
    const [isOpenId, setIsOpenId] = useState('');
    const showSidebar = useSelector(state => state.sidebar.showSidebar);
    const menuItems = useSelector(state => state.sidebar.data.menu || []);
    const openUrl = history.location.pathname;
    const dispatch = useDispatch();

    const toggleOpen = id => {
        if (isOpenId === id) {
            return setIsOpenId('');
        }
        return setIsOpenId(id);
    };

    const toggleSidebarTimeout = () => {
        setTimeout(() => {
            if (!showSidebar) return;
            dispatch(toggleSidebar());
        }, 400);
    };

    return (
        <aside
            data-test="sidebar"
            onMouseLeave={() => toggleSidebarTimeout()}
            className={`sidebar-menu flex-column show-sidebar-${showSidebar}`}
        >
            {menuItems
                .filter(item => !item.tags || item.tags.includes('client'))
                .map(item => (
                    <React.Fragment key={item.key}>
                        {item.subMenus.length ? (
                            <MenuCategory
                                openUrl={openUrl}
                                menuItem={item}
                                isOpenId={isOpenId}
                                sendBackId={id => toggleOpen(id)}
                            />
                        ) : (
                            <MenuButton
                                item={item}
                                openUrl={openUrl}
                                className="menu-item"
                            />
                        )}
                    </React.Fragment>
                ))}
        </aside>
    );
};

export default Sidebar;

Sidebar.propTypes = {
    history: PropTypes.object,
};

Sidebar.defaultProps = {
    history: {},
};
