import * as types from '../constants/Types';

const initialState = {
    withdrawFundCreateLoading: false,
    withdrawFund: [],
    withdrawFundResponse: {},
};

const WithdrawFundReducer = (state: any = initialState, action: any = {}): any => {
    switch (action.type) {
        case types.PAYIN_WITHDRAW_FUND_CREATE_RESET: {
            return { 
                ...state, 
                withdrawFundCreateLoading: false,
                withdrawFundResponse: {},
            };
        }

        case types.PAYIN_WITHDRAW_FUND_CREATE_REQUEST: {
            return { ...state, withdrawFundCreateLoading: true, };
        }

        case types.PAYIN_WITHDRAW_FUND_CREATE_RECEIVE: {
            return { 
                ...state,
                withdrawFundResponse: action.payload,
                withdrawFundCreateLoading: false,
            };
        }
        
        case types.PAYIN_WITHDRAW_FUND_HISTORY_REQUEST: {
            return { ...state, withdrawFundHistoryLoading: true, };
        }

        case types.PAYIN_WITHDRAW_FUND_HISTORY_RECEIVE: {
            return { ...state, 
                withdrawFund: action.payload,
                withdrawFundHistoryLoading: false,
            };
        }

        default:
            return state;
    }
};

export default WithdrawFundReducer;
