import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import debounce from 'lodash/debounce';

class Pagination extends Component {
    debouncedData = debounce((start, limit) => {
        this.props.getPaginationData(
            start,
            limit.value,
            this.state.currentPage
        );
    }, 300);

    constructor(props) {
        super(props);
        const params = new URLSearchParams(this.props.history.location.search);

        this.state = {
            currentPage:
                Number(params.get('page')) > 0 ? Number(params.get('page')) : 1,
            pageSize:
                params.get('pageSize') === '50' ||
                    params.get('pageSize') === '100'
                    ? {
                        value: Number(params.get('pageSize')),
                        label: Number(params.get('pageSize')),
                    }
                    : props.pageSize
                        ? { value: props.pageSize, label: props.pageSize }
                        : { value: 50, label: 50 },
            perPage: props.perPage && props.perPage?.length
                ? props.perPage.map(page => ({ value: page, label: page }))
                : [
                    { value: 50, label: '50' },
                    { value: 100, label: '100' },
                ],
        };
        this.props.getPaginationData(
            this.getStart(),
            this.state.pageSize.value,
            this.state.currentPage
        );
    }

    componentDidUpdate(prevProps, prevState) {
        // update url when page number changes
        const previousParams = this.convertSearchParams(
            this.props.history.location.search.replace('?', '')
        );
        const params = new URLSearchParams(this.props.history.location.search);

        if (prevState.currentPage !== this.state.currentPage) {
            this.props.history.push({
                pathname: this.props.history.location.pathname,
                search: new URLSearchParams({
                    page: this.state.currentPage,
                    pageSize: this.state.pageSize.value,
                    ...(params.get('historyPage')
                        ? { historyPage: Number(params.get('historyPage')) }
                        : {}),
                    ...previousParams,
                }).toString(),
            });

            this.debouncedData(this.getStart(), this.state.pageSize);
        }

        // if page url is bigger than page total, change page url to last page
        if (prevProps.dataTotal !== this.props.dataTotal) {
            if (this.state.currentPage > this.getPageCount()) {
                this.setState({
                    currentPage:
                        this.getPageCount() !== 0 ? this.getPageCount() : 1,
                });
            }
        }

        if (prevProps.start !== this.props.start && this.props.start === 0) {
            this.setState({
                currentPage: 1,
            });

        }

        if (prevState.pageSize !== this.state.pageSize) {
            this.setState({ currentPage: 1 });

            this.props.history.push({
                pathname: this.props.history.location.pathname,
                search: new URLSearchParams({
                    page: this.state.currentPage,
                    pageSize: this.state.pageSize.value,
                    ...(params.get('historyPage')
                        ? { historyPage: Number(params.get('historyPage')) }
                        : {}),
                    ...previousParams,
                }).toString(),
            });
        }
    }

    getStart() {
        const start = this.state.pageSize.value * (this.state.currentPage - 1);
        return start > 0 ? start : 0;
    }

    onChange = pageSize => {
        this.setState({ pageSize });
        this.props.handleChange(pageSize);
    };

    convertSearchParams = queryString => {
        const params = new URLSearchParams(decodeURI(queryString));
        const entries = params.entries();

        const result = {};
        for (const entry of entries) {
            const key = entry[0];
            const val = entry[1];
            result[key] = val;
        }
        delete result.page;
        return result;
    };

    getPageCount = () =>
        Math.ceil(this.props.dataTotal / this.state.pageSize.value);

    onChangePage = page => {
        this.setState({
            currentPage: page.selected + 1,
        });
    };

    render() {
        const { dataTotal, loading } = this.props;
        const { pageSize, perPage, currentPage } = this.state;
        return (
            <React.Fragment>
                {dataTotal > 0 && !loading && (
                    <div className="vertical-center flex-1">
                        <p className="show-of mr-4">
                            {`${dataTotal || 0} results, showing`}
                        </p>
                        <div className="vertical-center pagination-select">
                            <Select
                                id="paginationSize"
                                name="perPage"
                                isSearchable={false}
                                className="basic-select bold"
                                classNamePrefix="select"
                                defaultValue={perPage[0]}
                                options={perPage}
                                styles={{
                                    container: provided => ({
                                        ...provided,
                                        width: '80px',
                                    }),
                                }}
                                value={pageSize}
                                onChange={value => this.onChange(value)}
                            />
                        </div>
                        <div className="v-separator ml-3 mr-3" />
                        <ReactPaginate
                            containerClassName="pagination"
                            forcePage={currentPage - 1}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={1}
                            pageCount={this.getPageCount()}
                            onPageChange={this.onChangePage}
                            previousLabel={
                                <i className="fa fa-angle-double-left bold" />
                            }
                            nextLabel={
                                <i className="fa fa-angle-double-right bold" />
                            }
                        />
                    </div>
                )}
            </React.Fragment>
        );
    }
}

Pagination.propTypes = {
    perPage: PropTypes.array,
    pageSize: PropTypes.number,
    dataTotal: PropTypes.number,
    history: PropTypes.object,
    loading: PropTypes.bool,
    getPaginationData: PropTypes.func,
    handleChange: PropTypes.func,
};

Pagination.defaultProps = {
    perPage: [],
    pageSize: 50,
    dataTotal: 0,
    history: {},
    loading: false,
    getPaginationData: () => { },
    handleChange: () => { },
};

export default Pagination;
