import {
    GET_BANKS_DETAILS_FAIL,
    GET_BANKS_DETAILS_SUCCESS,
    GET_BANKS_DETAILS,
} from '../../actions/types';

const initialState = {
    banks: [],
    loading: false,
    error: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case GET_BANKS_DETAILS:
            return { ...state, loading: true };
        case GET_BANKS_DETAILS_SUCCESS:
            return { ...state, loading: false, banks: action.payload };
        case GET_BANKS_DETAILS_FAIL:
            return { ...state, loading: false, error: action.error };
        default:
            return state;
    }
}
