import React from 'react';
import PropTypes from 'prop-types';

const InfoCard = ({ data, subClassName = '', parentClassName = '' }) => (
    <div id="infoCard" className={`flex ${parentClassName}`}>
        <div className={`info-card flex ${subClassName}`}>
            {data.map((item, index) => (
                <div key={index} className="item">
                    <p className="title">{item.title}</p>
                    <p className={`value bold ${item.style}`}>{item.value}</p>
                </div>
            ))}
        </div>
    </div>
);

InfoCard.propTypes = {
    data: PropTypes.array,
    style: PropTypes.string,
    subClassName: PropTypes.string,
    parentClassName: PropTypes.string,
};

export default InfoCard;
