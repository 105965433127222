import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import MenuButton from './MenuButton';
import { R_CLIENT_DASHBOARD } from '../../../../AppRouteConstants';

const MenuCategory = ({ openUrl, menuItem, isOpenId, sendBackId }) => {
    const categoryIsOpen = isOpenId === menuItem._id;
    return (
        <div data-test="menuCategory">
            <div className="category-header vertical-center horizontal-right">
                <button
                    type="button"
                    className={`menu-item full-width category active-${categoryIsOpen}`}
                    onClick={() => sendBackId(menuItem._id)}
                    data-test={menuItem.key}
                >
                    {menuItem.label}
                </button>
                <FontAwesomeIcon
                    icon={faChevronDown}
                    className={
                        categoryIsOpen ? 'fa-chevron-up' : 'fa-chevron-down'
                    }
                />
            </div>
            <div
                className={`submenu-container ${
                    categoryIsOpen ? 'category-is-open' : ''
                }`}
            >
                {categoryIsOpen &&
                    menuItem.subMenus.map(item => (
                        <React.Fragment key={item._id}>
                            <MenuButton
                                item={item}
                                openUrl={openUrl}
                                className="submenu-item"
                            />
                        </React.Fragment>
                    ))}
            </div>
        </div>
    );
};

export default MenuCategory;

MenuCategory.propTypes = {
    openUrl: PropTypes.string,
    menuItem: PropTypes.object,
    isOpenId: PropTypes.string,
    sendBackId: PropTypes.func,
};

MenuCategory.defaultProps = {
    openUrl: R_CLIENT_DASHBOARD,
    menuItem: {},
    isOpenId: '',
    sendBackId: () => {},
};
