import React, { FC } from 'react';
import '../scss/components/_alertMessage.scss';

type alertType = 'success' | 'error';
interface IAlertMessage {
    parentClassName: string;
    message: string;
    type: alertType;
    withBorderBottom?: boolean;
}

export const AlertMessage: FC<IAlertMessage> = ({
    parentClassName = '',
    message = '',
    withBorderBottom = false,
    type,
}) => (
    <div className={`message-container ${parentClassName}`}>
        <div className={`message-icon ${type}`} />
        <div className="message-text">{message}</div>
        {withBorderBottom && <div className="message-border" />}
    </div>
);
