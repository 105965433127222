import React, { useEffect, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import * as userActions from '../../actions/user-actions';
import * as sidebarActions from '../../actions/sidebar-actions';
import * as CommonActions from '../../actions/constants-actions';
import * as headerActions from '../../actions/header-actions';
import { urls as menus } from './MenuConstants';
// sidebar nav config

import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';
import Footer from './Footer/Footer';
import { Spinner } from '../../components';
// routes config
import { loggedInRoutes } from '../../routes';
const ChangePassword = lazy(() => import('../PasswordChange/PasswordChange'));

const actions = {
    ...userActions,
    ...sidebarActions,
    ...CommonActions,
    ...headerActions,
};

const Title = ({ title, children }: any) => {
    useEffect(() => {
        document.title = title ? `${title} | Nium` : 'Nium';
    }, [title]);
    return children;
};

const RootContainer = ({ passChanged, isClientChanging }: any) => {
    if (passChanged === false) return <ChangePassword />;
    if (passChanged && !isClientChanging) {
        return (
            <Suspense fallback={<Spinner type="global" />}>
                <Switch>
                    {loggedInRoutes.map((route, idx) =>
                        route.component ? (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                render={props => (
                                    <Title title={route.name}>
                                        <route.component {...props} />
                                    </Title>
                                )}
                            />
                        ) : null
                    )}
                </Switch>
            </Suspense>
        );
    }
    return <div className="spinner" />;
};

interface IDefaultLayoutProps {
    getClientsList(): any;
    getSidebarData(): any;
    getCommonConstants(): any;
    getCountries(): any;
}

class DefaultLayout extends React.Component<IDefaultLayoutProps> {
    token: any;
    constructor(props: any) {
        super(props);
        this.token = localStorage.getItem('csrfToken');
        if (!this.token && window.location.pathname !== 'identification') {
            props.history.push('/login', { from: props.location.pathname });
        } else {
            this.props.getClientsList();
            this.props.getSidebarData();
            this.props.getCommonConstants();
            if (props.supplier_payments_customer) {
                this.props.getCountries();
            }
        }
    }

    render() {
        const {
            generated_password_changed,
            clientChanging,
            history,
            supplier_payments_customer,
            sidebar
        }:any = this.props;

        if (sidebar?.data?.menu?.length > 0) {
            const url_menus: any = menus[window.location.pathname];
            let flag = 0;
            for (let menu in url_menus) {
                for (let key in sidebar.data.menu) {
                    if (sidebar.data.menu[key].url === (url_menus[menu])) {
                        flag = 1;
                    } else {
                        for(let subKey in sidebar.data.menu[key].subMenus) {
                            if (sidebar.data.menu[key].subMenus[subKey].url === (url_menus[menu])) {
                                flag = 1;
                            }
                        }
                    }
                }
                if (flag !== 1 && menu === ((url_menus.length - 1).toString())) {
                    history.push('/r/dashboard/client');
                }
            }
        }
        
        return (
            <div className="app">
                <Header
                    supplier_payments_customer={supplier_payments_customer}
                />
                <Sidebar history={history} />
                <div className="app-body">
                    <main className="main">
                        <Container fluid>
                            <RootContainer
                                passChanged={generated_password_changed}
                                isClientChanging={clientChanging}
                            />
                        </Container>
                    </main>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        generated_password_changed: state.userInfo.generated_password_changed,
        clientChanging: state.header.clientChanging,
        supplier_payments_customer:
            state.header.activeClient.supplier_payments_customer,
        sidebar: state.sidebar,
    };
};

export default connect(mapStateToProps, actions)(DefaultLayout);
