import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchInput, { createFilter } from 'react-search-input';
import UserMenuCategory from './UserMenuCategory';
import iconSearch from '../../../../../../assets/img/icons/icon-search.svg';
import { redirectTo } from '../../../../../../actions/common-actions';
import { logout } from '../../../../../../actions/user-actions';

const ShowUserMenu = () => {
    const clientsList = useSelector(state => state.header.clientsList || []);
    const [searchTerm, setSearchTerm] = useState('');

    const dispatch = useDispatch();
    const showCompanyProfile = useSelector(
        state => state.sidebar.showCompanyProfile
    );
    const filteredClients = clientsList.filter(
        createFilter(searchTerm, ['client_name'])
    );

    const onLogout = () => {
        dispatch(
            logout(() => {
                dispatch(redirectTo('/login'));
            })
        );
    };

    return (
        <div className="user-dropdown-menu flex-column mt-1">
            {clientsList.length > 14 ? (
                <div className="search-input">
                    <SearchInput
                        value={searchTerm}
                        onChange={name => setSearchTerm(name)}
                        placeholder="Search"
                    />
                    <div className="full-width horizontal-right vertical-center">
                        <img
                            className="search-icon"
                            src={iconSearch}
                            alt="search"
                        />
                    </div>
                </div>
            ) : (
                <div className="mt-2" />
            )}
            <button
                type="button"
                data-test="user-profile-button"
                className="dropdown-button"
                onClick={() =>
                    dispatch(redirectTo('/r/settings/user/myProfile'))
                }
            >
                User Profile
            </button>
            {showCompanyProfile && (
                <button
                    type="button"
                    data-test="company-profile-button"
                    className="dropdown-button"
                    onClick={() =>
                        dispatch(redirectTo('/r/settings/companyProfile'))
                    }
                >
                    Company Profile
                </button>
            )}
            {clientsList.length > 0 && (
                <UserMenuCategory
                    filteredClients={filteredClients}
                    onToggle={() => setSearchTerm('')}
                />
            )}
            <button
                type="button"
                data-test="logout-button"
                className="dropdown-button"
                onClick={() => onLogout()}
            >
                Logout
            </button>
        </div>
    );
};

export default ShowUserMenu;
