import * as types from '../constants/Types';

const initialState = {
    payersList: {
        records: [],
        total: 0,
    },
    payersLoading: false,
    payersLookupLoading: false,
    payersLookupResponse: [],
    isTaggedPayer: false,
    showAddPayerForm: false,
    paymentRequestCurrentPayer: null,
    payers: [],
    createPayerRequesting: false,
    payerInput: '',
    showPayersList: false,
};

const PayerReducer = (state: any = initialState, action: any = {}): any => {
    switch (action.type) {
        case types.PAYIN_PAYERS_LIST_REQUEST: {
            return { ...state, payersLoading: true, payers: [] };
        }

        case types.PAYIN_PAYERS_LIST_RECEIVE: {
            const { payersList } = action.payload;
            return {
                ...state,
                payersList,
                payersLoading: false,
            };
        }

        case types.PAYIN_PAYERS_LOOKUP_REQUEST: {
            return { ...state, payersLookupLoading: true, payersLookupResponse: [] };
        }

        case types.PAYIN_PAYERS_LOOKUP_RECEIVE: {
            const { payersList } = action.payload;
            return {
                ...state,
                payersLookupResponse: payersList,
                payersLookupLoading: false,
            };
        }

        case types.PAYMENT_REQUEST_SET_CURRENT_PAYER: {
            const { newPayer, isTaggedPayer } = action.payload;
            return {
                ...state,
                paymentRequestCurrentPayer: newPayer,
                isTaggedPayer,
            };
        }

        case types.SHOW_ADD_PAYER_FORM: {
            return {
                ...state,
                showAddPayerForm: action.payload,
            };
        }

        case types.PAYER_INPUT: {
            return {
                ...state,
                payerInput: action.payload,
            };
        }

        case types.SHOW_PAYERS_LIST: {
            return {
                ...state,
                showPayersList: action.payload,
            };
        }

        case types.CREATE_PAYER_REQUEST: {
            return { ...state, createPayerRequesting: true };
        }

        case types.CREATE_PAYER_SUCCESS: {
            const payerName =
                action.payload.type === types.PAYIN_PAYER_TYPE_COMPANY
                    ? action.payload.companyName
                    : action.payload.payerName;
            const newPayer = { ...action.payload, payerName };
            return {
                ...state,
                payers: [...state.payers, newPayer],
                createPayerRequesting: false,
            };
        }

        case types.ADD_PAYER_LOCALLY: {
            return {
                ...state,
                payers: [],
            };
        }

        case types.CREATE_PAYER_FAILURE: {
            return {
                ...state,
                createPayerRequesting: false,
            };
        }

        case types.PAYIN_ALL_PAYERS_FETCHED: {
            const payers = (action.payload || []).map(
                ({ type, companyName = '', name = '', ...rest }: any) => ({
                    ...rest,
                    type,
                    companyName,
                    name,
                    payerName:
                        type &&
                            type.toUpperCase() === types.PAYIN_PAYER_TYPE_COMPANY
                            ? companyName
                            : name,
                })
            );
            return {
                ...state,
                payers,
                payersLoading: false,
            };
        }

        case types.EMPTY_PAYIN_CLIENT_DATA: {
            return {
                ...state,
                payers: []
            };
        }

        default:
            return state;
    }
};

export default PayerReducer;
