const rfiResponseReducer = (
    state = {
        loading: false,
        refetchData: false,
        loadingDataTable: false,
        loadingGlobal: false,
        submitSuccess: false,
        cancelSuccess: false,
        parentState: null,
        reqCurrentInfo: [],
        requestDocNames: [],
        selectedTransaction: [],
        pagination: { items: [] },
    },
    action = {}
) => {
    switch (action.type) {
        case 'REFETCH_DATA':
            return { ...state, refetchData: true };
        case 'CHANGE_PAGE_SIZE_REQUEST':
            return { ...state, loading: true, refetchData: true };
        case 'RFI_RESPONSE_DATA_REQUEST':
            return {
                ...state,
                loading: true,
                loadingDataTable: true,
                refetchData: false,
                submitSuccess: false,
                cancelSuccess: false,
            };
        case 'RFI_RESPONSE_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                loadingDataTable: false,
                pagination: {
                    items: action.payload.data,
                    total: action.payload.recordsFiltered,
                },
            };
        case 'RFI_RESPONSE_TRANSACTION_DATA_REQUEST':
            return {
                ...state,
                loading: true,
            };
        case 'RFI_RESPONSE_TRANSACTION_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                selectedTransaction: action.payload,
            };
        case 'COMPLIANCE_RFI_GET_RFIDOCSDETAILS_REQUEST':
            return { ...state, loadingDataTable: true };
        case 'COMPLIANCE_RFI_GET_RFIDOCSDETAILS_SUCCESS':
            return {
                ...state,
                loadingDataTable: false,
                reqCurrentInfo: action.payload,
            };
        case 'RFI_RESPONSE_DOC_NAMES_SUCCESS':
            return { ...state, requestDocNames: action.payload.data };
        case 'RFI_RESPONSE_SUBMIT_REQUEST':
            return { ...state, loadingGlobal: true };
        case 'RFI_RESPONSE_SUBMIT_SUCCESS':
            return { ...state, loadingGlobal: false, submitSuccess: true };
        case 'RFI_RESPONSE_CANCEL_TRANSACTION_REQUEST':
            return { ...state, loadingGlobal: true };
        case 'RFI_RESPONSE_CANCEL_TRANSACTION_SUCCESS':
            return { ...state, loadingGlobal: false, cancelSuccess: true };
        case 'RFI_RESPONSE_SAVE_PARENT_STATE':
            return { ...state, parentState: action.payload };
        case 'ERROR':
            return { ...state, loadingGlobal: false };
        default:
            return state;
    }
};

export default rfiResponseReducer;
