import axios from 'axios';
import { DispatchType } from './reduxInterface';
import {
    GET_SIDEBAR_DATA,
    SESSIONID_EXPIRED,
    TOGGLE_SIDEBAR,
    OPEN_SIDEBAR,
    CLOSE_SIDEBAR,
} from './types';

export function getSidebarData() {
    return async function (dispatch: DispatchType) {
        try {
            const res = await axios.get('/user/menu');

            const settings = res.data.menu.find(
                (o: any) => o.key === 'settings'
            );
            const hasCompanyProfile =
                settings &&
                settings.subMenus &&
                settings.subMenus.some((x: any) => x.key === 'companyProfile');
            dispatch({
                type: GET_SIDEBAR_DATA,
                payload: {
                    ...res,
                    showCompanyProfile: hasCompanyProfile,
                },
            });
        } catch (err) {
            localStorage.removeItem('csrfToken');
            dispatch({
                type: SESSIONID_EXPIRED,
            });
        }
    };
}

export function toggleSidebar() {
    return function (dispatch: DispatchType) {
        dispatch({
            type: TOGGLE_SIDEBAR,
        });
    };
}

export function openSidebar() {
    return function (dispatch: DispatchType) {
        dispatch({
            type: OPEN_SIDEBAR,
        });
    };
}

export function closeSidebar() {
    return function (dispatch: DispatchType) {
        dispatch({
            type: CLOSE_SIDEBAR,
        });
    };
}
