const clientDashboardReducer = (
    state = {
        data: [],
        loading: '',
        loading_dataTabe: '',
    },
    action = {}
) => {
    switch (action.type) {
        case 'GET_CLIENT_DASHBOARD_DATA_REQUEST':
            return { ...state, loading: true };
        case 'GET_CLIENT_DASHBOARD_DATA_SUCCESS':
            return { ...state, loading: false, data: action.payload.accounts };
        default:
            return state;
    }
};

export default clientDashboardReducer;
