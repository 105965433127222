import React, { FC, ReactElement } from 'react';
import Style from './Modal.module.scss';

interface IModalBody {
    children: ReactElement | string;
    className?: string;
}

export const ModalBody: FC<IModalBody> = ({ children, className = '' }) => (
    <div className={`${Style.modal_body} ${className}`}>{children}</div>
);
