import React from 'react';

interface ISpinnerProps {
    type?:
        | 'regular'
        | 'global'
        | 'table'
        | 'inline'
        | 'btn'
        | 'circular'
        | 'circular-inline';
}

export const Spinner = ({ type }: ISpinnerProps) => {
    switch (type) {
        case 'regular':
            return <div className="spinner centered" />;
        case 'global':
            return (
                <div className="general-spinner">
                    <Spinner />
                </div>
            );
        case 'table':
            return (
                <div className="dot-spinner">
                    <div className="bounce1" />
                    <div className="bounce2" />
                    <div className="bounce3" />
                </div>
            );
        case 'inline':
            return <div className="spinner inline" />;
        case 'btn':
            return <div className="button-loading">...</div>;
        case 'circular':
            return <div className="circular-spinner" />;
        case 'circular-inline':
            return <div className="circular-spinner inline" />;
        default:
            return <div className="spinner" />;
    }
};
