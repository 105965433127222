export interface IPageSize {
    value: number | string;
    label: string;
}
export const pageSizes: IPageSize[] = [
    { value: '10', label: '10' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
];

export const supportedFileTypes = [
    'PDF',
    'PNG',
    'CSV',
    'DOC',
    'DOCX',
    'JPEG',
    'JPG',
    'TIFF',
    'BMP',
    'XLS',
    'XLSX',
];

export const transactionStatusMapper: any = {
    TRANSACTION_RECEIVED: 'IN PROCESS',
    PAYMENT_RECONCILIATION_PENDING: 'IN PROCESS',
    PAYMENT_RECONCILIATION_APPROVED: 'IN PROCESS',
    PAYMENT_RECONCILIATION_AUTO_APPROVED: 'IN PROCESS',
    COMPLIANCE_SCREENING: 'IN PROCESS',
    COMPLIANCE_VERIFICATION: 'IN PROCESS',
    COMPLIANCE_AUTO_APPROVED: 'IN PROCESS',
    COMPLIANCE_APPROVED: 'IN PROCESS',
    COMPLIANCE_BYPASSED: 'IN PROCESS',
    SENT_TO_BANK_FOR_VERIFICATION: 'IN PROCESS',
    BANK_VERIFICATION_APPROVED: 'IN PROCESS',
    FINANCE_PENDING: 'IN PROCESS',
    COMPLIANCE_REJECTED: 'REJECTED',
    FINANCE_REJECTED: 'REJECTED',
    BANK_VERIFICATION_REJECTED: 'REJECTED',
    REJECTED: 'REJECTED',
    FINANCE_APPROVED: 'APPROVED',
    COMPLETED: 'APPROVED',
    APPROVED: 'APPROVED',
    TECHNICAL_ERROR: 'ERROR',
    CANCELLED: 'CANCELLED',
    RFI_RAISED: 'RFI RAISED',
    RFI_RESPONDED: 'RFI RESPONDED',
    REFUNDED: 'REFUNDED'
};

export const paymentStatus = {
    PENDING: 'PENDING',
};

export const payerTypes = {
    COMPANY: 'COMPANY',
    INDIVIDUAL: 'INDIVIDUAL',
};

export enum vraApproachType {
    PER_CURRENCY = 'PER_CURRENCY',
    PER_PAYER = 'PER_PAYER',
    UNSET = 'UNSET',
}
export interface IWalletCurrency {
    currencyCode?: string;
    label?: currencyLabelType[];
}
export type currencyLabelType = 'LOCAL' | 'GLOBAL';
export const localLabel: currencyLabelType[] = ['LOCAL'];
export const globalLabel: currencyLabelType[] = ['GLOBAL'];
export const globalLocalLabel: currencyLabelType[] = ['GLOBAL', 'LOCAL'];

/**
 *
 * creates a string representation of currency and string
 * for example: INR and label: ['Local'] ==> INR-LOCAL
 * for example: INR and label: ['Local',"GLOBAL"] ==> INR-LOCAL,GLOBAL
 * @param currency
 * @param label
 */
export const getCurrencyLabelString = (
    currency: string,
    label: currencyLabelType[]
) =>
    (currency ? currency + '-' : '') +
    label
        ?.slice()
        ?.sort()
        .toString()
        ?.toUpperCase() ?? '';
export const maxBankAllowed = 2;

export const vraStatus = {
    REQUESTED: 'REQUESTED',
    COMPLETED: 'COMPLETED'
}

export interface IReceiveCurrency {
    currency: string;
    isActive?: boolean;
    label: currencyLabelType[];
    status?: string;
}
