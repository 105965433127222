import React, { FC } from 'react';
import { Card } from '.';
import '../scss/components/_card-button.scss';

type buttonClickEvent = (event: React.MouseEvent<HTMLInputElement>) => void;

interface ICardButton {
    onClick: buttonClickEvent;
    parentClassName?: string;
    buttonText?: string;
    dataName?: string;
}
const CardButton: FC<ICardButton> = ({
    onClick,
    parentClassName = '',
    buttonText = '',
    dataName = '',
}) => {
    return (
        <Card
            parentClassName={`card-button-container ${parentClassName}`}
            isActive
            onClick={onClick}
        >
            <div className="card-button">
                <div className="card-button-icon">
                    <span>+</span>
                </div>
                <div className="card-button-text" data-test-id={dataName}>
                    {buttonText}
                </div>
            </div>
        </Card>
    );
};

export default CardButton;
