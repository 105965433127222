import * as types from '../constants/Types';
import { showPopup } from '../../../../actions/common-actions';
import { ADD_BANK } from '../constants/Types';
import { getRequest, postRequest } from '../../../../utils/Request';
import { dispatchError } from '../../../../actions/constants-actions';
import { DispatchType } from '../../../../actions/reduxInterface';

export const getSavedBankAccountList = () => (
    dispatch: DispatchType,
    getState: any
) => {
    dispatch({
        type: types.GET_BANK_ACCOUNT_REQUEST,
    });
    const clientId = getState().header.activeClient._id;

    return getRequest(`/client/${clientId}/bankAccounts`)
        .then(res => {
            const bankListDetails = res.data || {};
            dispatch({
                type: types.GET_BANK_ACCOUNT_RESPONSE,
                payload: { bankListDetails },
            });
        })
        .catch(err => {
            dispatch(dispatchError(err));
        });
};

export const setDefaultBankAccount = (
    bankAccountId: string,
    accountNumber?: string
) => (dispatch: DispatchType, getState: any) => {
    const clientId = getState().header.activeClient._id;
    let data = {
        bankAccountId: bankAccountId,
    };

    postRequest(`/client/${clientId}/bankAccounts/setDefault`, data)
        .then(res => {
            if (res) {
                dispatch({
                    type: types.DEFAULT_BANK_ACCOUNT_REQUEST,
                    payload: data,
                });
                getSavedBankAccountList()(dispatch, getState);
            }
        })
        .catch(error => {
            dispatch(
                showPopup({
                    popupIdentifier: types.DEFAULT_BANK_ACCOUNT_REQUEST,
                    title: 'Default Bank Account',
                    type: 'warning',
                    html: `<span style="font-weight: 700">Warning:</span> You cannot add this bank account as default.`,
                    customContainerClass: 'popup-container',
                    confirmButtonClass: 'popup-confirm',
                    callbackFn: () => { },
                })
            );
            dispatch(dispatchError(error));
        });
};
/**
 * this action was used to fetch json which created add bank account form
 * currently we are keeping json in front-end itself so this action is not in use
 */
export const fetchAddBankForm = async () => {
    try {
        let response = await getRequest(
            `/receive/settings?countryCode=ALL&entity=BENEFICIARY_BANK_ACCOUNT_FORM`
        );
        const config = response.data.uiRenderingConfiguration;
        return {
            fieldSchema: config.fieldsConfiguration,
            uiSchema: config.uiConfiguration,
            formData: config.formDataConfiguration,
            multicurrencySupportedCountries: config.multicurrencySupportedCountries
        };
    } catch (error) {
        console.log(error);
        return null;
    }
};
export const ADD_BANK_SERVER_ERROR = 'ADD_BANK_SERVER_ERROR';
export const ADD_BANK_SUCCESS = 'ADD_BANK_SUCCESS';
export const addBankAccountAction = ({ payload }: any) => {
    return (dispatch: DispatchType, getState: any) => {
        const clientId = getState().header.activeClient._id;
        dispatch({ type: types.GET_BANK_ACCOUNT_REQUEST });
        postRequest(`/client/${clientId}/bankAccounts`, payload)
            .then(response => {
                dispatch({
                    type: ADD_BANK_SUCCESS,
                });
                dispatch(
                    showPopup({
                        popupIdentifier: ADD_BANK.REQUEST,
                        title: 'Bank Account Added Successfully',
                        type: 'success',
                        text: `Bank Account ${payload.accountNumber} is added to your account List`,
                        customContainerClass: 'popup-container',
                        confirmButtonClass: 'popup-confirm',
                        callbackFn: () => {
                            window.location.pathname =
                                '/r/transactions/manageBankAccounts';
                        },
                    })
                );
            })
            .catch(error => {
                let message = '';
                dispatch({
                    type: ADD_BANK_SERVER_ERROR,
                    payload: error.response?.data,
                });
                if (error?.response?.data?.message) {
                    message = error.response.data.message;
                }
                let title = `Please provide valid information `;
                if (message.indexOf('bankName') > -1) {
                    title = title + 'Bank Name ,';
                } if (message.indexOf('accountNumber') > -1) {
                    title = title + ' Account number';
                }

                dispatch(
                    showPopup({
                        popupIdentifier: ADD_BANK.REQUEST,
                        title: 'Error!',
                        type: 'error',
                        text: title,
                        customContainerClass: 'popup-container',
                        confirmButtonClass: 'popup-confirm',
                        callbackFn: () => { },
                    })
                );
            });
    };
};

