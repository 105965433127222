import './polyfill';

import React from 'react';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

// import registerServiceWorker from './registerServiceWorker';
import { ConnectedRouter, push } from 'connected-react-router';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { compose } from 'redux';
import { PersistGate } from 'redux-persist/integration/react';

// TODO: MConnectedRouterove store and axios initialization in to separate files
import App from './App';
import apm from './bootstrap/apm';
import { axiosInstance } from './bootstrap/axiosInstance';
import { storeConfig, history } from './bootstrap/store';
import { Spinner } from './components/functionalComponents';
import * as serviceWorker from './serviceWorker';
import { ErrorBoundary } from './views/TopLevelRoutes/ErrorBoundary';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
        Cypress?: any;
        __store__?: any;
        reactAppEnv: any;
    }
}

const { store, persistor } = storeConfig();

if (window.Cypress) {
    window.__store__ = store;
}

// apply csrf token from localStorage to each axios request headers
axiosInstance(store);

if (window.location !== window.parent.location) {
    window.parent.location.replace(window.location.href);
}

if ((window as any).location === '/concurrentlogin') {
    window.parent.location.replace(window.location.href);
}
apm.setInitialPageLoadName('initial load');

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ErrorBoundary>
                <ConnectedRouter history={history}>
                    <React.Suspense fallback={<Spinner type="global" />}>
                        <App />
                    </React.Suspense>
                </ConnectedRouter>
            </ErrorBoundary>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
