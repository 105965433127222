const accountStatementReducer = (
    state = {
        pagination: { items: [] },
        accounts: [],
        accountInformation: {},
        loading: true,
        accountLoading: true,
        downloading: false,
        refetchData: false,
    },
    action = {}
) => {
    switch (action.type) {
        case 'ACCOUNT_STATEMENT_DATA_REQUEST':
            return { ...state, loading: true, refetchData: false };
        case 'ACCOUNT_STATEMENT_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.data.data.response,
                    total: action.payload.data.data.recordsFiltered,
                },
            };
        case 'ACCOUNT_STATEMENT_DATA_FAILURE':
            return { ...state, loading: false };
        case 'ACCOUNT_STATEMENT_ACCOUNT_INFORMATION_REQUEST':
            return { ...state, accountLoading: true };
        case 'ACCOUNT_STATEMENT_ACCOUNT_INFORMATION_SUCCESS':
            return {
                ...state,
                accountInformation: action.payload.data,
                accountLoading: false,
            };
        case 'ACCOUNT_STATEMENT_ACCOUNT_INFORMATION_FAILURE':
            return { ...state, accountLoading: false };
        case 'ACCOUNT_STATEMENT_DOWNLOAD_REQUEST':
            return { ...state, downloading: true };
        case 'ACCOUNT_STATEMENT_DOWNLOAD_SUCCESS':
            return { ...state, downloading: false };
        case 'ACCOUNT_STATEMENT_DOWNLOAD_FAILURE':
            return { ...state, downloading: false };
        case 'ACCOUNT_STATEMENT_ACCOUNTS_BY_CLIENT':
            return { ...state, accounts: action.payload.data };
        case 'ACCOUNT_STATEMENT_FILTER_REQUEST':
            return { ...state, loading: true, refetchData: true };
        default:
            return state;
    }
};

export default accountStatementReducer;
