import React from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import { toggleSidebar } from '../../../../actions/sidebar-actions';
import { redirectTo } from '../../../../actions/common-actions';
import { scrollToTop } from '../../../Pages/Payin/utils/page';

const MenuButton = ({ item, openUrl, className }) => {
    const dispatch = useDispatch();

    const menuItemClicked = url => {
        dispatch(redirectTo(`/r${url}`));
        scrollToTop();
        dispatch(toggleSidebar());
    };

    return (
        <button
            data-test="menuItem"
            className={`${className} active-${openUrl === `/r${item.url}`}`}
            type="button"
            onClick={() => menuItemClicked(item.url)}
            id={item.key}
        >
            {item.label}
            <span className="menu-tag">
                {item.tags.includes('new') ? 'NEW' : ''}
            </span>
        </button>
    );
};

export default MenuButton;

MenuButton.propTypes = {
    item: PropTypes.object,
    openUrl: PropTypes.string,
    className: PropTypes.string,
};

MenuButton.defaultProps = {
    item: {},
    openUrl: '',
    className: '',
};
