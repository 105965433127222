import colorVariables from '../../../../../../scss/_variables.scss';

const getOptionBackgroundColor = state => {
    let backgroundColor = '';
    if (state.isSelected) {
        backgroundColor = colorVariables.secondaryBackground;
    } else if (state.isFocused) {
        backgroundColor = colorVariables.seperator;
    }
    return backgroundColor;
};

const getOptionTextColor = state => {
    let textColor = '';
    if (state.isSelected) {
        textColor = colorVariables.altText;
    } else if (state.isFocused) {
        textColor = colorVariables.infoSecondaryText;
    }
    return textColor;
};

const selectStyles = {
    menu: menuStyle => ({
        ...menuStyle,
        color: colorVariables.infoSecondaryText,
        margin: '0px',
        marginTop: '-2px',
        border: `2px solid ${colorVariables.black}`,
        borderRadius: '0px',
        outline: 'none',
        boxShadow: 'none',
        zIndex: 3,
        background: colorVariables.primaryBackground,
    }),
    menuList: menuListStyle => ({
        ...menuListStyle,
        paddingTop: '0px',
        paddingBottom: '0px',
        maxHeight: '250px',
        overflow: 'scroll',
    }),
    option: (optionStyle, state) => ({
        ...optionStyle,
        backgroundColor: getOptionBackgroundColor(state),
        color: getOptionTextColor(state),
        ':not(:last-child)': {
            borderBottom: `1px solid ${colorVariables.black}`,
        },
        ':active': {
            backgroundColor: colorVariables.seperator,
            color: colorVariables.infoSecondaryText,
        },
    }),
};

export default selectStyles;

export const customStyles = {
    control: (styles, state) => ({
        ...styles,
        boxShadow: 0,
        borderColor: state.isFocused ? '#46beed' : '#CED4DA',
        '&:hover': {
            borderColor: state.isFocused ? '#46beed' : '#CED4DA',
        },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? '#46beed' : null,
            color: '#333333',
        };
    },
};
