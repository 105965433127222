import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

const RadioButtons = ({
    data,
    selected = null,
    onChange,
    parentClassName = '',
}) => {
    const [toggled, setSelected] = useState(selected);

    function changeHandler(item) {
        setSelected(item);
        return onChange(item.value);
    }

    return (
        <div
            id="radioButtons"
            className={`flex user-type-selector ${parentClassName}`}
        >
            {data.map(item => {
                const isSelected = toggled && toggled.value === item.value;
                return (
                    <div
                        className={`item vertical-center flex mr-5 ${
                            isSelected ? 'selected' : ''
                        }`}
                        onClick={() => changeHandler(item)}
                    >
                        <FontAwesomeIcon
                            icon={isSelected ? faDotCircle : faCircle}
                            className="mr-3 option"
                        />
                        <p>{item.label}</p>
                    </div>
                );
            })}
        </div>
    );
};

RadioButtons.propTypes = {
    data: PropTypes.array,
    selected: PropTypes.object,
};

RadioButtons.defaultProps = {
    data: [],
};

export default RadioButtons;
