import React, { FC, ReactElement, useEffect } from 'react';
import Style from './Modal.module.scss';

interface IModal {
    children: ReactElement | string;
    className?: string;
    show: boolean;
    parentClassName?: string;
}

export const Modal: FC<IModal> = ({
    show = false,
    children,
    parentClassName = '',
    className = '',
}) => {
    useEffect(() => {
        if (show) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [show]);

    return show ? (
        <div className={`${Style.modal} ${parentClassName}`}>
            <div className={`${Style.modal_content} ${className}`}>
                {children}
            </div>
        </div>
    ) : null;
};
