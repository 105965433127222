import { init as initApm } from '@elastic/apm-rum';
import { getConfig } from '../utils/config';
const apm = initApm({
  serviceName: getConfig('REACT_APP_APM_SERVICE_NAME'),
  serverUrl: getConfig('REACT_APP_APM_ENDPOINT'),
  serviceVersion: '',
  environment: getConfig('REACT_APP_ENV'),
  active: getConfig('REACT_APP_ENV') === 'production',
});

export default apm;
