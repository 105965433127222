/**
 * This file should only contain client side routes
 * R symbolized Route , assumed to be app route
 */
export const R_CLIENT_DASHBOARD = '/r/dashboard/client';
export const R_ADD_BENEFICIARY = '/r/transactions/addbeneficiary';
export const R_BIZPAY_TRANSACTION_INFO =
    '/r/transactions/cardpayments/transactionInformation';
export const R_BIZPAY = '/r/transactions/bizpay';
export const R_BIZPAY_PAYMENT_INFO =
    '/r/transactions/cardpayments/transactionInformation/payments';

export const R_BIZPAY_PAYMENT_REVIEW_INFO =
    '/r/transactions/cardpayments/payments/paymentsReview';

export const R_BIZPAY_PAYMENT_STATUS =
    '/r/transactions/cardpayments/payments/paymentsReview/paymentStatus';

export const R_ADD_BANK_ACCOUNT = '/r/transactions/addBankAccount';
