import React from 'react';
import footerlogo from '../../../assets/img/brand/footer_nium_logo.png';

import './styles/Footer.scss';

const Footer = () => (
    <div className="footer flex wrap">
        <div className="logo col-md-2 center">
            <img src={footerlogo} width="55" height="auto" alt="NIUM" />
        </div>
        <div className="footer-content col-md-10 vertical-center">
            <div>
                <p className="dark-grey">
                    NIUM Pte Ltd. (formerly known as InstaReM Pte. Limited) is
                    the holding company of NIUM subsidiaries globally.
                </p>
                <p className="dark-grey">
                    It is regulated by MAS under RA No. 01454 in Singapore.
                    Copyright &copy; 2019 Instarem. All rights reserved.
                </p>
                <p className="mt-4">Contact us:</p>
                <a href="mailto: customer.success@nium.com" className="black">
                    customer.success@nium.com
                </a>
            </div>
        </div>
    </div>
);

export default Footer;
