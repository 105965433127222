import React, { useState, useEffect, useRef } from 'react';
import { DateRangePicker } from 'react-dates';
import { Label } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';
import MultiSelect from '@khanacademy/react-multi-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { ErrorMessage } from './Inputs';
import moment from 'moment';
import { customStyles } from '../views/Pages/Payin/components/ui/select/SelectStyles';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const LeftArrow = () => (
    <button className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
        <FontAwesomeIcon icon={faChevronLeft} />
    </button>
);

const RightArrow = () => (
    <button className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
        <FontAwesomeIcon icon={faChevronRight} />
    </button>
);

export const SingleDatePicker = ({
    children,
    title,
    onChange,
    className,
    parentClassName,
    value,
    ...props
}) => (
    <div
        className={`time-picker single-date-picker flex-column ${parentClassName}`}
    >
        <Label className="filter-title">{title}</Label>
        <Flatpickr
            placeholder="..."
            value={value}
            options={{ dateFormat: 'd m Y' }}
            onChange={onChange}
            {...props}
        />
        {children}
    </div>
);

export const DoubleDatePicker = ({
    title,
    fromDate,
    toDate,
    parentClassName,
    onDatesChange,
    ...props
}) => {
    const [focusedInput, inputFocusChange] = useState(null);
    const refDatePicker = useRef();

    const handleKeyDown = event => {
        const grantedKeyCode = [8, 37, 39];
        const onlyNums = event.key.replace(/[^\d\s]/g, '');

        if (!grantedKeyCode.includes(event.keyCode) && onlyNums.length === 0)
            event.preventDefault();
    };

    useEffect(() => {
        const { current } = refDatePicker;
        if (current) {
            current.addEventListener('keydown', handleKeyDown);
        }
        return () => current.removeEventListener('keydown', handleKeyDown);
    }, []);

    return (
        <div
            ref={refDatePicker}
            id="filterDate"
            className={`filter-item flex-column ${parentClassName}`}
        >
            <Label className="filter-title" for="startDateId">
                {title}
            </Label>
            <DateRangePicker
                displayFormat="DD MM YYYY"
                startDate={fromDate}
                endDate={toDate}
                onDatesChange={onDatesChange}
                focusedInput={focusedInput}
                onFocusChange={currentFocusedInput => inputFocusChange(currentFocusedInput)}
                minimumNights={0}
                isOutsideRange={day => day.isAfter(moment())}
                small
                startDateId="startDateId"
                endDateId="endDateId"
                noBorder
                startDatePlaceholderText="Choose"
                endDatePlaceholderText=""
                navPrev={<LeftArrow />}
                navNext={<RightArrow />}
                {...props}
            />
        </div>
    );
};

export const SingleSelect = ({
    id,
    name,
    children,
    title,
    value,
    onChange,
    errorMessage,
    errorMessageClass,
    parentClassName,
    options,
    dataName,
    isSearchable = false,
    ...props
}) => (
    <div data-test={dataName} className={`filter-item ${parentClassName}`}>
        <Label className="filter-title">{title}</Label>
        <Select
            styles={customStyles}
            id={id}
            name={name}
            value={value}
            placeholder="Choose"
            isSearchable={isSearchable}
            classNamePrefix="select"
            hideSelectedOptions={false}
            className={`basic-multi-select data-test-${dataName}`}
            onChange={onChange}
            options={options}
            instanceId={id}
            {...props}
        />
        {errorMessage ? (
            <ErrorMessage
                message={errorMessage}
                className={errorMessageClass}
            />
        ) : (
            children
        )}
    </div>
);

export const MultipleSelect = ({
    onSelectedChanged,
    selected,
    className,
    title,
    parentClassName,
    id,
    name,
    options,
    dataName,
    ...props
}) => (
    <div className={`filter-item ${parentClassName}`}>
        <Label className="filter-title">{title}</Label>
        <div data-test={dataName} className={className}>
            <MultiSelect
                id={id}
                name={name}
                overrideStrings={{
                    selectSomeItems: 'Choose',
                    allItemsAreSelected: 'All',
                    selectAll: 'All',
                }}
                disableSearch
                options={options}
                instanceId={id}
                selected={selected}
                onSelectedChanged={onSelectedChanged}
                {...props}
            />
        </div>
    </div>
);
