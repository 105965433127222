import * as types from '../constants/Types';
import { TransactionStatus } from '../constants/TransactionStatus';
import produce from 'immer';
import { getCurrencyLabelString } from '../constants/Constants';

const initialState = {
    clientCurrencies: [],
    currency: [],
    country: [],
    overlayOverRfiRequest: false,
    rfi: {} /** object containing list of all rfi entries irrespective of status mapped against transactionid */,
    rfiResponseDrafts: {} /** object containing rfiResponse mapped against rfiId */,
    vraCurrency: null,
};

export const payinReducer = (state: any = initialState, action: any = {}): any => {
    switch (action.type) {
        case types.EMPTY_PAYIN_CLIENT_DATA: {
            return {
                ...state,
                clientCurrencies: [],
            };
        }

        case types.PAYIN_CURRENCY_RECEIVE: {
            return { ...state,
                currency: [...state.currency, ...action.payload],
            };
        }

        case types.PAYIN_CLIENT_CURRENCY_FETCHED: {
            return {
                ...state,
                clientCurrencies: action.payload,
            };
        }

        case types.PAYIN_COUNTRY_RECEIVE: {
            return {
                ...state,
                country: action.payload,
            };
        }
        
        case types.PAYIN_GET_RFI_LIST_REQUEST: {
            return {
                ...state,
                gettingRfi: true,
            };
        }
        
        case types.PAYIN_GET_RFI_LIST_FAILURE: {
            return {
                ...state,
                gettingRfi: false,
            };
        }

        case types.PAYIN_GET_RFI_LIST_SUCCESS: {
            const { data } = action.payload;
            const requestedRFIs = data.filter(
                (rfiRow: any) =>
                    rfiRow?.status?.toUpperCase() ===
                    TransactionStatus.rfiStatus.RAISED
            );
            const respondedRfis = data.filter(
                (rfiRow: any) =>
                    rfiRow?.status?.toUpperCase() ===
                    TransactionStatus.rfiStatus.RESPONDED
            );
            return {
                ...state,
                gettingRfi: false,
                rfi: {
                    requested: requestedRFIs,
                    responded: respondedRfis,
                    counts: data.length,
                },
                rfiResponseDrafts: {
                    ...state.rfiResponseDrafts,
                },
            };
        }

        case types.PAYIN_SET_SUPPLIER_RFI_COMMENT: {
            const { rfiId, comment } = action.payload;
            return produce(state, (draft: any) => {
                if (draft.rfiResponseDrafts[rfiId]) {
                    draft.rfiResponseDrafts[rfiId].comment = comment;
                } else {
                    draft.rfiResponseDrafts[rfiId] = action.payload;
                }
            });
        }

        case types.PAYIN_SET_SUPPLIER_RFI_FILE: {
            const { rfiId, file } = action.payload;
            return produce(state, (draft: any) => {
                if (draft.rfiResponseDrafts[rfiId]) {
                    draft.rfiResponseDrafts[rfiId].file = file;
                } else {
                    draft.rfiResponseDrafts[rfiId] = action.payload;
                }
            });
        }

        case types.SET_NUMBER_OF_RFI_FOR_COMPLIANCE: {
            const { number } = action.payload;
            return {
                ...state,
                rfiResponseDrafts: {
                    ...state.rfiResponseDrafts,
                    numberOfRfiRequests: number,
                },
            };
        }

        case types.PAYIN_RESPOND_RFI_REQUEST: {
            return {
                ...state,
                overlayOverRfiRequest: true,
            };
        }

        case types.PAYIN_RESPOND_RFI_SUCCESS: {
            return {
                ...state,
                overlayOverRfiRequest: false,
            };
        }

        case types.DOWNLOAD_RFI_CLIENT_DOCUMENT_SUCCESS: {
            return {
                ...state,
            };
        }

        case types.EMPTY_RFI_CLIENT_RESPONSE: {
            return {
                ...state,
                rfiResponseDrafts: {},
            };
        }

        case types.SET_SELECTED_VRA_CURRENCY: {
            const { currencyCode, currencyLabel } = action.payload;
            const vraCurrency = state.clientCurrencies?.find(
                (currency: any) =>
                    getCurrencyLabelString(
                        currency.currencyCode,
                        currency.label
                    ) === getCurrencyLabelString(currencyCode, currencyLabel)
            );

            return {
                ...state,
                vraCurrency,
            };
        }

        default:
            return state;
    }
};
