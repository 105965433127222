const paymentApprovalReducer = (
    state = { pagination: { items: [] }, loading: false, refetchData: false },
    action = {}
) => {
    switch (action.type) {
        case 'PAYMENT_APPROVAL_BATCH_DOWNLOAD':
            return { ...state };
        case 'PAYMENT_APPROVAL_DATA_REQUEST':
            return { ...state, loading: true, refetchData: false };
        case 'REFETCH_DATA':
            return { ...state, refetchData: true };
        case 'PAYMENT_APPROVAL_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.data.data,
                    total: action.payload.data.recordsTotal,
                    filtered: action.payload.data.recordsFiltered,
                },
            };
        case 'PAYMENT_APPROVAL_FAILURE':
            return { ...state, loading: false };
        case 'PAYMENT_APPROVAL_STAGE1_REQUEST':
            return { ...state, loading: true };
        case 'PAYMENT_APPROVAL_STAGE2_REQUEST':
            return { ...state, loading: true };
        case 'PAYMENT_APPROVAL_REJECT_STAGE1_REQUEST':
            return { ...state, loading: true };
        case 'PAYMENT_APPROVAL_REJECT_STAG2_REQUEST':
            return { ...state, loading: true };
        case 'PAYMENT_APPROVAL_SUMMARY_DOWNLOAD_REQUEST':
            return { ...state };
        case 'CHANGE_PAGE_SIZE_REQUEST':
            return { ...state, loading: true, refetchData: true };
        case 'ERROR':
            return { ...state, loading: false };
        default:
            return state;
    }
};

export default paymentApprovalReducer;
