import React, { FC, ReactElement } from 'react';
import Style from './Modal.module.scss';

interface IModalFooter {
    children: ReactElement | string;
    className?: string;
}

export const ModalFooter: FC<IModalFooter> = ({ children, className = '' }) => (
    <div className={`${Style.modal_footer} ${className}`}>{children}</div>
);
