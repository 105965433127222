import * as types from '../../actions/types';

const onfidoIdentificationReducer = (state = { sdkToken: '' }, action = {}) => {
    switch (action.type) {
        case types.ONFIDO_SDK_TOKEN_REQUEST:
            return { ...state, sdkToken: action.payload.token };
        case types.ONFIDO_INITIATE_CHECK:
            return { ...state };
        default:
            return state;
    }
};

export default onfidoIdentificationReducer;
