const transactionHistoryReducer = (
    state = {
        pagination: {
            items: [],
        },
        loading: false,
        downloading: false,
        activeDownloads: [],
        sendingEmail: false,
        refetchData: false,
    },
    action = {}
) => {
    switch (action.type) {
        case 'TRANSACTION_HISTORY_DATA_REQUEST':
            return { ...state, loading: true, refetchData: false };
        case 'TRANSACTION_HISTORY_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.data,
                    total: action.payload.recordsFiltered,
                },
            };
        case 'TRANSACTION_HISTORY_DATA_FAILURE':
            return { ...state, loading: false };
        case 'TRANSACTION_HISTORY_CSV_DOWNLOAD_REQUEST':
            return { ...state, downloading: true };
        case 'TRANSACTION_HISTORY_CSV_DOWNLOAD_SUCCESS':
            return { ...state, downloading: false };
        case 'TRANSACTION_HISTORY_CSV_DOWNLOAD_FAILURE':
            return { ...state, downloading: false };
        case 'TRANSACTION_HISTORY_GENARATE_RECEIPT_REQUEST':
            return {
                ...state,
                activeDownloads: [...state.activeDownloads, action.id],
            };
        case 'TRANSACTION_HISTORY_GENARATE_RECEIPT_SUCCESS':
        case 'TRANSACTION_HISTORY_GENARATE_RECEIPT_FAILURE':
            return {
                ...state,
                activeDownloads: state.activeDownloads.filter(
                    item => item !== action.id
                ),
            };
        case 'TRANSACTION_HISTORY_NOTIFY_RECIPIENT_REQUEST':
            return { ...state, sendingEmail: true };
        case 'TRANSACTION_HISTORY_FILTER_GENERATE_REQUEST':
            return { ...state, loading: true, refetchData: true };
        default:
            return state;
    }
};

export default transactionHistoryReducer;
