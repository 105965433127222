const companyProfileReducer = (
    state = {
        pageError: null,
        clientUserEmails: [],
        autoReversalFX: undefined,
        companyInfo: {},
        accountsPairs: [],
    },
    action = {}
) => {
    switch (action.type) {
        case 'COMPANY_PROFILE_DATA_SUCCESS':
            return {
                ...state,
                pageError: null,
                clientUserEmails: action.payload.clientUserEmails,
                autoReversalFX:
                    action.payload.config.revertAutoBookFxOnPayoutReturn,
                companyInfo: action.payload.client,
            };
        case 'COMPANY_PROFILE_ACCOUNTS_PAIRS_DATA_SUCCESS':
            return {
                ...state,
                pageError: null,
                accountsPairs: action.payload.accountsPairs,
            };
        case 'COMPANY_PROFILE_PAGE_ERROR':
            return {
                ...state,
                pageError: { message: action.payload?.message },
            };
        default:
            return state;
    }
};

export default companyProfileReducer;
