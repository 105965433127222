const authApis = Object.freeze({
    resetPassword: '/resetPassword',
    logout: '/logout',
    generateOtp: '/authentication/generateOTP',
    login: '/login',
    requestPassword: 'r/api/requestPassword',
    setPassword: '/settings/userProfile/changePassword',
});

const allFxRateApis = Object.freeze({
    fxRate: '/lockFx/getFxRate',
    lockfxRate: '/lockFx/lockFxRate',
    getHoldFx: '/lockFx/getHoldFx',
});

const transactionHistoryApis = Object.freeze({
    transactionHistory: '/reports/transactions/pagination',
    transactionHistoryDetails: '/reports/transactions',
    transactionHistoryCSVDownload: '/reports/transactionHistory/download',
});

const contactApis = Object.freeze({
    beneficiaryValidationSchema: '/beneficiary/validations',
    bankSearchByRoutingCode: '/bankNameLookup',
    metadata: '/beneficiary/metadata',
    createBeneficiaries: (clientId: string): string =>
        `/clients/${clientId}/beneficiaries`,
    beneficiaryList: (clientId: string) => `/clients/${clientId}/beneficiaries`,
    updateBeneficiaryPayout: (
        clientId: string | undefined,
        beneficiaryId: string,
        payoutId: string
    ): string =>
        `/clients/${clientId}/beneficiaries/${beneficiaryId}/payouts/${payoutId}`,
    updateBeneficiary: (clientId: string, beneficiaryId: string): string =>
        `/clients/${clientId}/beneficiaries/${beneficiaryId}`,
    deleteBeneficiary: (clientId: string, beneficiaryId: string): string =>
        `/clients/${clientId}/beneficiaries/${beneficiaryId}`,
});

const rfiDocumentsApi = Object.freeze({
    rfiPagination: '/transactions/clientrfi/pagination',
    rfiDocsName: '/transactions/clientrfi/getRfiDocNames',
    rfiDocsDetails: '/transactions/getrfidocsdetails',
    rfiSubmit: '/transactions/clientrfi/respondToComplianceRequest',
});

const sendMoneyApis = Object.freeze({
    purposeCodes: '/common/purposeCodes',
    validatePayout: '/validatePayout',
    createPayout: '/createPayout',
    netSettlementFees: '/transactionFees',
    transactionDocuments: '/transaction/document',
});

const cardApis = Object.freeze({
    validBinNumbers: '/transactions/cardPaymentBins',
    cardPayment: '/transactions/cardPayment',
    getAllSavedCard: `/bpsp/getAllSavedCards`,
    saveCard: `/bpsp/saveCard`,
    chargeOnCard: `/bpsp/chargeSavedCard`,
    postRequestId: (requestId: string) =>
        `/transactions/cardPayment/updateStatus/${requestId}`,
    getRequestId: (requestId: string) =>
        `/transactions/cardPayment/${requestId}`,
});

const userSettingApis = Object.freeze({
    userUpdate: (userId: string | number) => `/settings/user/${userId}/update`,
    accountSummary: '/dashboard/client/getAccountSummary',
    createAccount: '/account',
    payinBank: '/banks/payinBanks',
});

export const apiRoutes = Object.freeze({
    ...authApis,
    ...transactionHistoryApis,
    ...allFxRateApis,
    ...contactApis,
    ...rfiDocumentsApi,
    ...cardApis,
    ...userSettingApis,
    ...sendMoneyApis,

    // HACK: only enable this when we will have partner implementation
    // clientInfo: '/user/info',
    // HACK: Use this URL for future file upload testing
    // testMulterUpload: '/test/multer/upload',
});
