const holidayCalendarReducer = (
    state = {
        pagination: {
            items: [],
            total: 0
        },
        loading: false,
        downloading: false,
        refetchData: false,
    },
    action = {}
) => {
    switch (action.type) {
        case 'HOLIDAY_CALENDAR_DATA_REQUEST':
            return { ...state, loading: true, refetchData: false };
        case 'HOLIDAY_CALENDAR_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.response,
                    total: action.payload.recordsFiltered,
                },
            };
        case 'HOLIDAY_CALENDAR_DATA_FAILURE':
            return { ...state, loading: false };
        case 'HOLIDAY_CALENDAR_CSV_DOWNLOAD_REQUEST':
            return { ...state, downloading: true };
        case 'HOLIDAY_CALENDAR_CSV_DOWNLOAD_SUCCESS':
            return { ...state, downloading: false };
        case 'HOLIDAY_CALENDAR_CSV_DOWNLOAD_FAILURE':
            return { ...state, downloading: false };
        case 'HOLIDAY_CALENDAR_FILTER_REQUEST':
            return { ...state, loading: true, refetchData: true };
        default:
            return state;
    }
};

export default holidayCalendarReducer;