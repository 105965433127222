import { GET_DASHBOARD_DATA } from '../actions/types'

const dashboardReducer = (state = { data: '' }, action = {}) => {
    if (action.type === GET_DASHBOARD_DATA) {
        return { data: action.payload.data };
    }
    return state;
};

export default dashboardReducer;
