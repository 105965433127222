import React, { Suspense } from 'react';

import { Route, Switch } from 'react-router-dom';

// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.scss';

import { topLevelRoutes } from './routes';
import { Spinner } from './components';

const App = (): JSX.Element => (
    <Suspense fallback={<Spinner type="global" />}>
        <Switch>
            {topLevelRoutes.map(route => {
                return route.component || route.render ? (
                    <Route
                        key={route.name}
                        exact={route.exact}
                        path={route.path}
                        name={route.name}
                        component={route.component}
                        render={route.render}
                    />
                ) : null;
            })}
        </Switch>
    </Suspense>
);

export default App;
