import React from 'react';
import Styles from './TitleContent.module.scss';
export interface ITitleContentProps {
    parentClassName?: string;
    children: any;
    titleClassname?: string;
    titleText: any;
}
export const TitleContent = ({
    titleText = '',
    children,
    titleClassname = 'table-heading',
    parentClassName,
}: ITitleContentProps) => (
    <div className={`${Styles.Container} ${parentClassName}`}>
        <div className={`${Styles.titleClass} ${titleClassname}`}>
            {titleText}
        </div>
        <div className={Styles.childrenWrapper}>{children}</div>
    </div>
);
