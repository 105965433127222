import React from 'react';
import { Label } from 'reactstrap';

export const ErrorMessage = ({ message, className = '' }) =>
    !message ? null : (
        <p className={`msg-error mt-2 ${className}`}>{message}</p>
    );

export const Input = ({
    value,
    onChange,
    name,
    title,
    className = '',
    errorClassName = null,
    errorMessage = null,
    maxLength,
    type,
}) => (
    <div className={className}>
        <Label className="title-h3-bold">{title}</Label>
        <input
            type={type}
            maxLength={maxLength}
            name={name}
            className="form-control"
            placeholder="..."
            value={value}
            onChange={onChange}
        ></input>
        <ErrorMessage message={errorMessage} className={errorClassName} />
    </div>
);
