export type TStringKeyValue = {
    [property: string]: string;
};
export const TransactionStatus: any = {
    transactionStatus: {
        TRANSACTION_RECEIVED: 'TRANSACTION_RECEIVED',
        COMPLIANCE_SCREENING: 'COMPLIANCE_SCREENING',
        COMPLIANCE_VERIFICATION: 'COMPLIANCE_VERIFICATION',
        COMPLIANCE_AUTO_APPROVED: 'COMPLIANCE_AUTO_APPROVED',
        COMPLIANCE_APPROVED: 'COMPLIANCE_APPROVED',
        COMPLIANCE_REJECTED: 'COMPLIANCE_REJECTED',
        COMPLETED: 'COMPLETED',
        TECHNICAL_ERROR: 'TECHNICAL_ERROR',
        CANCELLED: 'CANCELLED',
        PAYMENT_RECONCILIATION_PENDING: 'PAYMENT_RECONCILIATION_PENDING',
        COMPLIANCE_ERROR: 'COMPLIANCE_ERROR'
    },
    transactionSubStatus: {
        RFI_RAISED: 'RFI_RAISED',
        RFI_RESPONDED: 'RFI_RESPONDED',
    },
    /*
 'TRANSACTION_RECEIVED',
 PAYMENT_RECONCILIATION_PENDING
 'PAYMENT_RECONCILIATION_APPROVED',//1, these transactions are not visible on UI
 'PAYMENT_RECONCILIATION_AUTO_APPROVED',//1 these transactions are not visible on UI
 'COMPLIANCE_VERIFICATION',  //
 'COMPLIANCE_AUTO_APPROVED', // this will go to gl entry of operations-dashboard
 'COMPLIANCE_APPROVED',// this will go to gl entry of operations-dashboard
 'COMPLIANCE_REJECTED',
 'SENT_TO_BANK_FOR_VERIFICATION', // sent to pp team
 'BANK_VERIFICATION_APPROVED', // gl entry for INR
 'BANK_VERIFICATION_REJECTED',
 'FINANCE_APPROVED', // action on gl entry
 'FINANCE_REJECTED',
 'COMPLETED', /** this status can be used when client marks the payment request as completed
 'TECHNICAL_ERROR',
 'CANCELLED',
 'COMPLIANCE_ERROR',
    */
    rfiStatus: {
        RESPONDED: 'RESPONDED',
        RAISED: 'RAISED',
        REJECTED: 'REJECTED',
        APPROVED: 'APPROVED',
    },
    amlLogStatus: {
        HIT: 'HIT',
        CLEAR: 'CLEAR',
        ERROR: 'ERROR',
        TECHNICAL_ERROR: 'TECHNICAL_ERROR',
        COMPLIANCE_AUTO_APPROVED: 'COMPLIANCE_AUTO_APPROVED',
        AWAITING_RESPONSE: 'AWAITING_RESPONSE',
    },
    amlStatusText: {
        FAILED: 'Failed',
        SUCCESSFUL: 'Successful',
        ERROR: 'Error',
    },
    complianceStatusText: {
        INPROCESS: 'IN PROCESS',
        RFI_RAISED: 'RFI RAISED',
        RFI_RESPONDED: 'RFI RESPONDED',
        APPROVED: 'APPROVED',
        REJECTED: 'REJECTED',
        AUTO_APPROVED: 'AUTO APPROVED',
    },
    statusClassName: {
        HIT: 'hit',
        AWAITING: 'awaiting',
        ERROR: 'compliance-error',
        CLEAR: 'clear',
        SUCCESS: 'success',
        PENDING: 'pending',
        WARNING: 'warning',
    },
    rfiType: {
        RFI_TEXT: 'RFI Text',
        RFI_DATE: 'RFI Date',
        RFI_DOCUMENT: 'RFI Document',

    }
};
TransactionStatus['mayHaveActiveRfi'] = [
    TransactionStatus.transactionStatus.COMPLIANCE_VERIFICATION,
    TransactionStatus.transactionStatus.PAYMENT_RECONCILIATION_PENDING,
    TransactionStatus.transactionStatus.COMPLIANCE_ERROR,
];

export const getHasActiveRfi = (status: string, subStatus: string): boolean =>
    TransactionStatus['mayHaveActiveRfi'].includes(status) &&
    subStatus !== null;
