import React, { FC } from 'react';
import { Card } from '.';
import '../scss/components/_card-with-content.scss';
import { TitleContent } from '../views/Pages/Payin/components/ui/TitleContent';

interface IItem {
    key: string;
    value: string | number;
    titleClassName?: string;
    contentClassName?: string;
}

interface ICardAction {
    handleClick: (id: string) => void;
    label: string | JSX.Element;
    dataName?: string;
}

interface ICardWithContent {
    isSelected?: boolean;
    id: string;
    handleSelect: (id: string) => void;
    data: IItem[];
    icon?: HTMLImageElement;
    cardActions?: ICardAction[];
    parentClassName?: string;
}

export const CardWithContent: FC<ICardWithContent> = ({
    isSelected = false,
    id,
    handleSelect,
    data = [],
    icon,
    cardActions = [],
    parentClassName = '',
}) => {
    return (
        <Card
            isActive={isSelected}
            onClick={() => {
                handleSelect(id);
            }}
            parentClassName={parentClassName}
        >
            <>
                <div className="card-content-header">
                    <div className="card-radio">
                        <div className="card-radio-selected" />
                    </div>
                    <div className="card-icon">{icon}</div>
                </div>
                <div className="card-content-wrapper">
                    {data?.map(
                        ({
                            key,
                            value,
                            titleClassName = '',
                            contentClassName = '',
                        }: IItem) => (
                            <TitleContent
                                titleText={key}
                                titleClassname={`title-text ${titleClassName}`}
                                parentClassName="card-content"
                                key={`${id}_${value}`}
                            >
                                <div
                                    className={`content-text ${contentClassName}`}
                                >
                                    {value}
                                </div>
                            </TitleContent>
                        )
                    )}
                </div>

                {cardActions?.length > 0 && (
                    <div className="card-actions">
                        {cardActions?.map(
                            (
                                { handleClick, label, dataName }: ICardAction,
                                index
                            ) => (
                                <div
                                    className="card-action"
                                    onClick={() => handleClick(id)}
                                    key={`${id}_${index}`}
                                    data-key-id={dataName}
                                >
                                    {label}
                                </div>
                            )
                        )}
                    </div>
                )}
            </>
        </Card>
    );
};
