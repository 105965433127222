import * as types from '../constants/Types';

const initialState = {
    accounts: [],
    account: {},
    accountLoading: false,
};

const AccountReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case types.PAYIN_ACCOUNT_REQUEST: {
            return Object.assign({}, state, { accountLoading: true });
        }

        case types.PAYIN_ACCOUNT_RECEIVE: {
            return Object.assign({}, state, {
                account: action.account,
                accountLoading: false,
            });
        }

        default:
            return state;
    }
};

export default AccountReducer;
