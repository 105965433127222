import produce from 'immer';
import * as types from '../actions/types';

const initialState = {
    modalData: [],
    popupData: [],
    toastData: [],
};

const modifyData = (dataArray, type, action) => {
    let modified = false;
    dataArray.forEach((data, index) => {
        if (data[type] === action.data[type]) {
            dataArray[index] = action.data;
            modified = true;
        }
    });
    if (!modified) {
        dataArray.push(action.data);
    }
};

/**
 * following function is currently used to create mapStateToProps for Modal, Toast and Popup,
 * so this function will be called after the redux store is updated, and before component is to be updated
 *
 * @param {string} type can be one of the following ('modal', 'popup', 'toast')
 */
export const modalStatePropsMapper = type => ({ common }, ownProp) => {
    let data = undefined;
    common[`${type}Data`].forEach(specific => {
        if (specific[`${type}Identifier`] === ownProp[`${type}Identifier`]) {
            data = specific;
        }
    });
    return {
        open: data !== undefined,
        [`${type}Data`]: data,
    };
};
const commonReducer = (state = initialState, action = {}) => {
    return produce(state, draft => {
        switch (action.type) {
            case types.SHOW_MODAL: {
                modifyData(draft.modalData, 'modalIdentifier', action);
                break;
            }
            case types.HIDE_MODAL: {
                draft.modalData = draft.modalData.filter(
                    e => e.modalIdentifier !== action.modalIdentifier
                );
                break;
            }
            case types.SHOW_POPUP: {
                modifyData(draft.popupData, 'popupIdentifier', action);
                break;
            }
            case types.HIDE_POPUP: {
                draft.popupData = draft.popupData.filter(
                    e => e.popupIdentifier !== action.popupIdentifier
                );
                break;
            }

            case types.SHOW_TOAST: {
                modifyData(draft.toastData, 'toastIdentifier', action);
                break;
            }
            case types.HIDE_TOAST: {
                draft.toastData = draft.toastData.filter(
                    e => e.toastIdentifier !== action.toastIdentifier
                );
                break;
            }
            default:
                return draft;
        }
    });
};
export default commonReducer;
