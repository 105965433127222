import React, { lazy } from 'react';

import * as AppRoutes from './AppRouteConstants';
import DefaultLayout from './views/DefaultLayout/DefaultLayout';

// # Dashboards
const ClientDashboard = lazy(() => import('./views/Dashboards/ClientDashboard/ClientDashboard'));
const DefaultDashboard = lazy(() => import('./views/Dashboards/DefaultDashboard/DefaultDashboard'));

// # Top level Routes
const Login = lazy(() => import('./views/TopLevelRoutes/Login/Login'));
const Signup = lazy(() => import('./views/TopLevelRoutes/Signup/Signup'));
const Page404 = lazy(() => import('./views/TopLevelRoutes/Page404/Page404'));
const Page500 = lazy(() => import('./views/TopLevelRoutes/Page500/Page500'));
const ConcurrentSession = lazy(() => import('./views/TopLevelRoutes/ConcurrentSession/ConcurrentSession'));

// # Transactions
const BookFx = lazy(() => import('./views/Pages/BookFx/BookFx'));
const AccountFunding = lazy(() => import('./views/Pages/AccountFunding/AccountFunding'));
const FundByDeposit = lazy(() => import('./views/Pages/AccountFunding/FundByDeposit/FundByDeposit'));
const FundByCard = lazy(() => import('./views/Pages/AccountFunding/FundByCard/FundByCard'));
const RfiResponse = lazy(() => import('./views/Pages/RfiResponse/RfiResponse'));
const RfiSelected = lazy(() => import('./views/Pages/RfiResponse/RfiSelected'));
const PaymentApproval = lazy(() => import('./views/Pages/PaymentApproval/PaymentApproval'));
const UploadValidation = lazy(() => import('./views/Pages/UploadTransactions/UploadValidation'));
const UploadTransactions = lazy(() => import('./views/Pages/UploadTransactions/UploadTransactions'));
const FinalSubmition = lazy(() => import('./views/Pages/UploadTransactions/FinalSubmition'));


const PreCheckDocumentUpload = lazy(() => import('./views/Pages/PreCheckDocumentUpload/PreCheckDocumentUpload'));
const SinglePayment = lazy(() => import('./views/Pages/SinglePayment/SinglePayment'));
const CardPayments = lazy(() => import('./views/Pages/CardPayments/CardPayments'));
const TransactionInformation = lazy(() => import('./views/Pages/CardPayments/TransactionInformation'));
const Payments = lazy(() => import('./views/Pages/CardPayments/Payments'));
const DetailsReview = lazy(() => import('./views/Pages/CardPayments/DetailsReview'));
const PaymentStatus = lazy(() => import('./views/Pages/CardPayments/PaymentStatus'));
const AddBeneficiary = lazy(() => import('./views/Pages/AddBeneficiary/AddBeneficiary'));

// # Reports
const FXHistory = lazy(() => import('./views/Pages/FXHistory/FXHistory'));
const AccountSummary = lazy(() => import('./views/Pages/AccountSummary/AccountSummary'));
const UploadBatchReport = lazy(() => import('./views/Pages/UploadBatch/UploadBatchReport'));
const AccountStatement = lazy(() => import('./views/Pages/AccountStatement/AccountStatement'));
const TransactionHistory = lazy(() => import('./views/Pages/TransactionHistory/TransactionHistory'));

// # Settings
const UserProfile = lazy(() => import('./views/UserProfile/UserProfile'));
const CompanyProfile = lazy(() => import('./views/CompanyProfile/CompanyProfile'));
const BankDetails = lazy(() => import('./views/Pages/BankDetails/BankDetails'));
const PasswordChange = lazy(() => import('./views/PasswordChange/PasswordChange'));

// Payin
const PaymentRequest = lazy(() => import('./views/Pages/Payin/PaymentRequest'));
const TrackPaymentRequestHistory = lazy(() => import('./views/Pages/Payin/reports/TrackPaymentRequestHistory'));
const WithdrawFund = lazy(() => import('./views/Pages/Payin/Withdraw/FundWithdraw'));
const WithdrawFundHistory = lazy(() => import('./views/Pages/Payin/Withdraw/FundWithdrawHistory'));
const VirtualReceivingAccountStatement = lazy(() => import('./views/Pages/Payin/VRAStatement/VRAS'));

const VirtualReceivingAccount = lazy(
    () => import('./views/Pages/Payin/VirtualReceivingAccount/VirtualReceivingAccount')
        .then(module => ({ default: module.VirtualReceivingAccount }))
);
const ManagePayers = lazy(() => import('./views/Pages/Payin/Payers/ManagePayers'));
const BankAccountList = lazy(
    () => import('./views/Pages/Payin/BankAccount/ManageBankAccount/BankAccountList')
        .then(module => ({ default: module.BankAccountList }))
);
// ManageBankAccount
const AddBankAccount = lazy(
    () => import('./views/Pages/Payin/BankAccount/AddBankAccount')
        .then(module => ({ default: module.AddBankAccount }))
);

// # Promotions
const ReceiveIntroduction = lazy(() => import('./views/ReceiveIntroduction/ReceiveIntroduction'));
const FAQ = lazy(() => import('./views/FAQ/FAQ'));
const FundingResponse = lazy(() => import('./views/Pages/AccountFunding/FundingResponse'));
const ErrorBoundary = lazy(
    () => import('./views/TopLevelRoutes/ErrorBoundary')
        .then(module => ({ default: module.ErrorBoundary }))
);
const AddPayer = lazy(
    () => import('./views/Pages/Payin/AddPayer')
        .then(module => ({ default: module.AddPayer }))
);
const HolidayCalendar = lazy(() => import('./views/Pages/HolidayCalendar/HolidayCalendar'));
const OnfidoIdentification = lazy(() =>
    import('./views/TopLevelRoutes/OnfidoIdentification/OnfidoIdentification')
);

// # ClientSettings
const UserSettings = lazy(() => import('./views/Pages/UserSettings/UserSettings'));

export const loggedInRoutes = [
    // # Dashboards
    {
        path: AppRoutes.R_CLIENT_DASHBOARD,
        name: 'Client dashboard',
        component: ClientDashboard,
    },
    {
        path: '/r/dashboard/finance',
        name: 'Finance dashboard',
        component: ClientDashboard,
    },

    // # Transactions
    {
        path: '/r/transactions/bookfx',
        name: 'Currency Exchange',
        component: BookFx,
    },
    {
        path: '/r/transactions/preCheckDocumentUpload',
        name: 'Upload Pre Check Documents',
        component: PreCheckDocumentUpload,
    },
    {
        path: '/r/transactions/rfiresponse',
        exact: true,
        name: 'Compliance Response',
        component: RfiResponse,
    },
    {
        path: '/r/transactions/rfiresponse/:id',
        name: 'Compliance Response',
        component: RfiSelected,
    },
    {
        path: '/r/transactions/fundingentry',
        exact: true,
        name: 'Account Funding Selection',
        component: AccountFunding,
    },
    {
        path: '/r/transactions/fundingentry/deposit',
        name: 'Account Funding By Deposit',
        component: FundByDeposit,
    },
    {
        path: '/r/transactions/fundingentry/card',
        name: 'Account Funding by Card',
        component: FundByCard,
    },
    {
        path: '/r/transactions/fundingResponse',
        name: 'Account Funding',
        component: FundingResponse,
    },
    {
        path: '/r/transactions/batchapproval',
        name: 'Send To Bank Approval',
        component: PaymentApproval,
    },
    {
        path: '/r/transactions/uploadtransactions',
        exact: true,
        name: 'Send To Bank',
        component: UploadTransactions,
    },
    {
        path: '/r/transactions/uploadtransactions/validationresults',
        exact: true,
        name: 'Send To Bank',
        component: UploadValidation,
    },
    {
        path:
            '/r/transactions/uploadtransactions/validationresults/finalsubmit',
        name: 'Send To Bank',
        component: FinalSubmition,
    },
    {
        path: '/r/transactions/singlepayment',
        name: 'Single Payment',
        component: SinglePayment,
    },
    {
        path: '/r/transactions/paymentRequest',
        name: 'Request Payment',
        component: PaymentRequest,
    },
    {
        path: '/r/transactions/virtualReceivingAccount',
        name: 'Virtual Accounts',
        component: VirtualReceivingAccount,
    },
    {
        path: '/r/transactions/virtualReceivingAccountStatement/:vraId/:currencyCode',
        name: 'Virtual Receiving Account Statement',
        component: VirtualReceivingAccountStatement,
    },
    {
        path: '/r/transactions/bizpay',
        name: 'BIZPAY Payments',
        component: CardPayments,
    },
    {
        path: '/r/transactions/cardpayments/transactionInformation',
        exact: true,
        name: 'BIZPAY Payments',
        component: TransactionInformation,
    },
    {
        path: '/r/transactions/cardpayments/transactionInformation/payments',
        exact: true,
        name: 'BIZPAY Payments',
        component: Payments,
    },
    {
        path: '/r/transactions/cardpayments/payments/paymentsReview',
        exact: true,
        name: 'BIZPAY Payments',
        component: DetailsReview,
    },
    {
        path:
            '/r/transactions/cardpayments/payments/paymentsReview/paymentStatus',
        exact: true,
        name: 'BIZPAY Payments',
        component: PaymentStatus,
    },
    {
        path: '/r/transactions/addBeneficiary',
        name: 'BIZPAY Beneficiary Creation',
        component: AddBeneficiary,
    },
    {
        path: '/r/holidayCalendar',
        name: 'Holiday',
        component: HolidayCalendar,
    },

    // # Reports
    {
        path: '/r/reports/bookfx',
        name: 'Currency Exchange History',
        component: FXHistory,
    },
    {
        path: '/r/reports/dailyreport',
        name: 'Daily Balance',
        component: AccountSummary,
    },
    {
        path: '/r/reports/uploadbatch',
        name: 'Upload Batch Report',
        component: UploadBatchReport,
    },
    {
        path: '/r/reports/accountstatement',
        name: 'Account Statement',
        component: AccountStatement,
    },
    {
        path: '/r/reports/transactionshistory',
        name: 'Transaction History',
        component: TransactionHistory,
    },
    {
        path: '/r/reports/trackPaymentRequestHistory',
        name: 'Track Payment Request',
        component: TrackPaymentRequestHistory,
    },

    // # Settings
    {
        path: '/r/settings/user/myProfile',
        name: 'My Profile',
        component: UserProfile,
    },
    {
        path: '/r/settings/companyProfile',
        name: 'Company Profile',
        component: CompanyProfile,
    },
    {
        path: '/r/settings/user/bankDetails',
        name: 'Bank Details',
        component: BankDetails,
    },
    {
        path: '/r/settings/user/changePassword',
        name: 'Change User Password',
        component: PasswordChange,
    },

    // # ClientSettings
    {
        path: '/r/client_settings/users',
        name: 'Users',
        exact: true,
        component: UserSettings
    },

    // # Payin
    {
        path: '/r/payin/withdrawFund',
        name: 'Withdraw Funds',
        component: WithdrawFund,
    },
    {
        path: '/r/payin/withdrawFundHistory',
        name: 'Withdraw Funds History',
        component: WithdrawFundHistory,
    },
    {
        path: '/r/payin/addPayer',
        name: 'Add New Payer',
        component: AddPayer,
    },
    // manage bank account

    {
        path: '/r/transactions/addBankAccount',
        name: 'Add Bank Account',
        component: AddBankAccount,
    },
    {
        path: '/r/payin/payers',
        name: 'Payers',
        component: ManagePayers,
    },
    {
        path: '/r/transactions/manageBankAccounts',
        name: 'Manage Bank Account',
        component: BankAccountList,
    },

    // # Global pages
    {
        path: '/r/receiveIntroduction',
        name: 'Receive Introduction',
        component: ReceiveIntroduction,
    },
    {
        path: '/r/faq',
        name: 'FAQ',
        component: FAQ,
    },

    // # The rest
    { path: '/r/', name: '', component: DefaultDashboard },
];

export const topLevelRoutes = [
    { path: '/login', name: 'Login Page', component: Login },
    { path: '/signup', name: 'Signup page', component: Signup },
    {
        path: '/resetPassword/:id',
        name: 'Reset password page',
        component: Login,
    },
    { path: '/404', name: 'Page 404', component: Page404, exact: true },
    { path: '/500', name: 'Page 500', component: Page500, exact: true },
    {
        path: '/identification',
        name: 'Verification',
        component: OnfidoIdentification,
    },
    {
        path: '/concurrentlogin',
        name: 'Concurrent Login',
        component: ConcurrentSession,
    },
    {
        path: '/r/oops',
        name: 'Oops!',
        component: ErrorBoundary,
    },
    {
        path: '/',
        name: 'Home',
        render: (props: any) => {
            return <DefaultLayout {...props} />;
        },
    },
];
