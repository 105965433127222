import axios from 'axios';

import apm from '../bootstrap/apm';
import { EMPTY_PAYIN_CLIENT_DATA } from '../views/Pages/Payin/constants/Types';
import { dispatchError } from './constants-actions';
import { DispatchType } from './reduxInterface';
import {
    SELECT_ACTIVE_RELOAD,
    SELECT_ACTIVE_CLIENT,
    GET_USER_CLIENTS_LIST_REQUEST,
    GET_USER_CLIENTS_LIST_SUCCESS,
    GET_PAYIN_RESTRICTED_COUNTRY_REQUEST,
    GET_PAYIN_RESTRICTED_COUNTRY_SUCCESS,
    GET_PAYIN_RESTRICTED_COUNTRY_FAILURE,
} from './types';

export function clientSelect(
    selectedClientId: string,
    shouldPreventRerender = false
) {
    return async function(dispatch: DispatchType) {
        if (!shouldPreventRerender) {
            dispatch({ type: SELECT_ACTIVE_RELOAD });
            dispatch({ type: EMPTY_PAYIN_CLIENT_DATA });
        }
        const { data: { data: clientData } } = await axios.get(`/client/${selectedClientId}`);
        apm.setCustomContext({
            client: {
                id: clientData._id,
                name: clientData.client_name,
                country: clientData.client_country,
                legalEntity: clientData.client_legal_entity,
                institutionType: clientData.institution_type,
                utcOffset: clientData.utcOffset,
                isAutoBookFx: clientData.is_auto_book_fx,
            },
        });
        getRestrictedCountry()(dispatch);
        setImmediate(() => {
            return dispatch({
                type: SELECT_ACTIVE_CLIENT,
                payload: clientData,
            });
        });
    };
}

export function getClientsList() {
    return async function(dispatch: DispatchType) {
        try {
            dispatch({
                type: GET_USER_CLIENTS_LIST_REQUEST,
            });
            const res = await axios.get('/user/info');
            dispatch({
                type: GET_USER_CLIENTS_LIST_SUCCESS,
                payload: res,
            });
        } catch (err) {
            dispatch(dispatchError(err));
        }
    };
}
export const getRestrictedCountry = () => async (dispatch: DispatchType) => {
    try {
        dispatch({
            type: GET_PAYIN_RESTRICTED_COUNTRY_REQUEST,
        });
        const response = await axios.get('/country');
        const payinRestrictedCountry = response.data.reduce(
            (acc: any, current: any) => {
                if (
                    current?.autoSweep?.bookFx &&
                    current?.autoSweep?.withdraw
                ) {
                    acc = [...acc, current.countryName];
                }
                return acc;
            },
            []
        );
        dispatch({
            type: GET_PAYIN_RESTRICTED_COUNTRY_SUCCESS,
            payload: payinRestrictedCountry,
        });
    } catch (error) {
        dispatch({
            type: GET_PAYIN_RESTRICTED_COUNTRY_FAILURE,
            payload: error,
        });
    }
};
