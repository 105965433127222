import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { redirectTo } from '../../../../actions/common-actions';

const HeaderMenu = () => {
    const activeClient = useSelector(state => state.header.activeClient);
    const dispatch = useDispatch();

    return (
        <div
            data-test="header-menu"
            className="header-menu vertical-center pr-4"
        >
            {/* Will be removed once confirmed by CS team
            {
            !activeClient.is_receive_client && (
                <button
                    type="button"
                    data-test="bank-details-link"
                    className="menu-item px-4 letter-spacing"
                    onClick={() =>
                        dispatch(redirectTo('/r/settings/user/BankDetails'))
                    }
                >
                    BANK DETAILS
                </button>
            )} */}
            <a
                data-test="user-manual-link"
                className="menu-item px-4 letter-spacing"
                href="/common/userManual"
                target="_blank"
                download
            >
                USER MANUAL
            </a>
        </div>
    );
};

export default HeaderMenu;
