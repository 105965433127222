import { COUNTRIES } from '../actions/types';

const constantsReducer = (state = { commonConstants: '', countries: {} }, action = {}) => {
    switch (action.type) {
        case 'COMMON_CONSTANTS':
            return { ...state, commonConstants: action.payload };
        case COUNTRIES:
            return { ...state, countries: action.payload };
        default:
            return state;
    }
};

export default constantsReducer;
