const accountSummaryReducer = (
    state = {
        pagination: { items: [] },
        loading: false,
        refetchData: false,
        error: false,
    },
    action = {}
) => {
    switch (action.type) {
        case 'ACCOUNT_SUMMARY_DATA_REQUEST':
            return { ...state, loading: true, refetchData: false };
        case 'ACCOUNT_SUMMARY_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.data.data,
                    total: action.payload.data.total,
                },
            };
        case 'ACCOUNT_SUMMARY_DATA_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case 'ACCOUNT_SUMMARY_FILTER_REQUEST':
            return { ...state, loading: true, refetchData: true };
        default:
            return state;
    }
};

export default accountSummaryReducer;
