import axios from 'axios';
export const postRequest = (url, body) => {
    return axios({
        method: 'post',
        url,
        data: { ...body },
        config: { headers: { 'Content-Type': 'application/json' } },
    });
};

export const getRequest = url => {
    return axios.get(url);
};

export const updateRequest = (url, body) => {
    return axios({
        method: 'put',
        url,
        data: { ...body },
        config: { headers: { 'Content-Type': 'application/json' } },
    });
};

export const deleteRequest = url => {
    return axios({
        method: 'delete',
        url,
    });
};
export const logError = componentString => {
    return error => {
        console.log(componentString, error);
    };
};
