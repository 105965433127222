const initialState = {
    loading: false,
    purposeCodes: [],
    error: null,
    validationError: null,
    loadingPaymentData: false,
    loadingSinglePaymentRequest: false,
    beneficiaryIdentificationType: [],
    remitterLookupResult: [],
    beneficiaryLookupResult: [],
    remitterAutofillResult: [],
    beneficiaryAutofillResult: [],
};

const singlePaymentReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case 'SINGLE_PAYMENT_CONSTANTS': {
            return { ...state, beneficiaryIdentificationType: action.payload };
        }
        case 'CURRENT_CLIENT_ACCOUNT_LIST_SUCCESS': {
            return { ...state, loading: false };
        }
        case 'GET_CLIENT_PAYMENT_DATA': {
            return { ...state, loading: false };
        }
        case 'GET_CLIENT_PAYMENT_DATA_SUCCESS': {
            return {
                ...state,
                loadingPaymentData: false,
                purposeCodes: action.payload.purposeCodes,
            };
        }
        case 'GET_CLIENT_PAYMENT_DATA_FAIL': {
            return { ...state, loadingPaymentData: false, error: action.error };
        }
        case 'GET_REMITTER_NAME_LOOKUP': {
            return { ...state, remitterLookupResult: action.payload };
        }
        case 'GET_BENEFICIARY_NAME_LOOKUP': {
            return { ...state, beneficiaryLookupResult: action.payload };
        }
        case 'GET_REMITTER_AUTOFILL_DATA': {
            return { ...state, remitterAutofillResult: action.payload };
        }
        case 'GET_BENEFICIARY_AUTOFILL_DATA': {
            return { ...state, beneficiaryAutofillResult: action.payload };
        }
        case 'MAKE_SINGLE_PAYMENT': {
            return {
                ...state,
                loadingSinglePaymentRequest: true,
                validationError: null,
            };
        }
        case 'MAKE_SINGLE_PAYMENT_SUCCESS': {
            return {
                ...state,
                loadingSinglePaymentRequest: false,
                paymentSucceed: action.payload,
            };
        }
        case 'MAKE_SINGLE_PAYMENT_FAILURE': {
            return { ...state, validationError: action.payload.message };
        }
        default:
            return state;
    }
};

export default singlePaymentReducer;
