import { GET_PAYIN_RESTRICTED_COUNTRY_SUCCESS } from '../actions/types';
const headerReducer = (
    state = {
        clientsList: [],
        loading: false,
        clientChanging: false,
        activeClient: '',
        payinRestrictedCountry: [],
    },
    action = {}
) => {
    switch (action.type) {
        case 'GET_USER_CLIENTS_LIST_REQUEST':
            return { ...state, loading: true };
        case 'GET_USER_CLIENTS_LIST_SUCCESS':
            return {
                ...state,
                loading: false,
                clientsList: action.payload.data.client_access,
            };
        case 'SELECT_ACTIVE_RELOAD':
            return { ...state, clientChanging: true };
        case 'SELECT_ACTIVE_CLIENT':
            return {
                ...state,
                activeClient: action.payload,
                clientChanging: false,
            };
        case GET_PAYIN_RESTRICTED_COUNTRY_SUCCESS:
            return {
                ...state,
                payinRestrictedCountry: action.payload,
            };
        default:
            return state;
    }
};

export default headerReducer;
