const preCheckDocumentsReducer = (
    state = { pagination: { items: [] }, refetchData: false },
    action = {}
) => {
    switch (action.type) {
        case 'PRE_CHECK_DOCUMENTS_REQUEST':
            return { ...state, refetchData: false };
        case 'PRE_CHECK_DOCUMENTS_SUCCESS':
            return {
                ...state,
                pagination: {
                    items: action.payload.data.response || [],
                    total: action.payload.data.recordsTotal,
                    filtered: action.payload.data.recordsFiltered,
                },
            };
        case 'CHANGE_PAGE_SIZE_REQUEST':
            return { ...state, refetchData: true };
        default:
            return state;
    }
};

export default preCheckDocumentsReducer;
