import React, { useEffect } from 'react';
import '../scss/components/_tooltip.scss';
import PropTypes from 'prop-types';

const Tooltip = ({ parentClassName, tooltipText = '', children }) => {
    function applyTooltip() {
        const elem = document.getElementsByClassName('overflow-ellipsis');
        for (let i = 0; i < elem.length; i++) {
            if (elem[i].offsetWidth < elem[i].scrollWidth) {
                elem[i].classList.add('show_tooltip');
            }
        }
    }
    useEffect(() => {
        applyTooltip();
        window.addEventListener('resize', applyTooltip);
        return () => window.removeEventListener('resize', applyTooltip);
    }, []);
    return (
        <div
            className={`data-table-tooltip overflow-ellipsis ${parentClassName}`}
        >
            {children}
            <p className="tooltiptext">{tooltipText}</p>
        </div>
    );
};

Tooltip.propTypes = {
    parentClassName: PropTypes.string,
    tooltipText: PropTypes.string,
    children: PropTypes.any,
};

Tooltip.defaultProps = {
    parentClassName: '',
    tooltipText: '',
    children: null,
};

export default Tooltip;
