import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import avatar from '../../../../../assets/img/avatar/nium_avatar.png';
import ShowUserMenu from './components/ShowUserMenu';

const UserMenu = () => {
    const userName = useSelector(state => state.sidebar.userName);
    const clientName = useSelector(
        state => state.header.activeClient.client_name
    );

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const myRef = useRef();

    const handleClickOutside = e => {
        if (myRef.current.contains(e.target)) {
            return;
        }
        setMenuIsOpen(false);
    };

    useEffect(() => {
        menuIsOpen &&
            document.addEventListener('mousedown', handleClickOutside);
        return () =>
            document.removeEventListener('mousedown', handleClickOutside);
    }, [menuIsOpen]);

    return (
        <div ref={myRef}>
            <button
                type="button"
                data-test="user-menu"
                className="header-dropdown vertical-center px-5 full-width"
                onClick={() => setMenuIsOpen(!menuIsOpen)}
            >
                <div className="client-select vertical-center flex">
                    <img src={avatar} className="user-avatar" alt="avatar" />
                    {!userName ? (
                        <p className="mr-4">Menu</p>
                    ) : (
                        <div
                            data-test="user-name-display"
                            className="account-name mr-4 flex"
                        >
                            <p>{userName} /&nbsp;</p>
                            <p className="uppercase"> {clientName || '...'}</p>
                        </div>
                    )}
                    <FontAwesomeIcon
                        rotation={menuIsOpen ? 180 : null}
                        icon={faChevronDown}
                        className={
                            menuIsOpen ? 'fa-chevron-up' : 'fa-chevron-down'
                        }
                    />
                </div>
            </button>
            {menuIsOpen && <ShowUserMenu />}
        </div>
    );
};

export default UserMenu;
