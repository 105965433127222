import axios from 'axios';
import { push } from 'connected-react-router';

import apm from '../bootstrap/apm';
import { recordCleverTapProfile } from '../services/cleverTap';
import { dispatchError } from './constants-actions';
import { DispatchType } from './reduxInterface';
import {
    AUTH_ERROR,
    USER_LOGIN_AUTH,
    USER_LOGIN_LOADING,
    USER_LOGIN_SUCCESS,
    AUTH_ERROR_FORGOT,
    AUTH_GENCODE_ERROR,
    USER_FORGOT_PASSWORD_SUCCESS,
    USER_RESET_PASSWORD_SUCCESS,
    USER_SIGNUP_LOADING,
    USER_SIGNUP_FAILURE,
    USER_SIGNUP_SUCCESS,
    USER_LOGOUT,
    RESET_ERROR,
    HIDE_MESSAGES,
    SELECT_ACTIVE_CLIENT,
} from './types';
import encryptData from '../utils/rsaEncryption';

export function login(values: any) {
    return async function(dispatch: DispatchType) {
        try {
            dispatch({
                type: USER_LOGIN_LOADING,
            });
            const res = await axios.post('/authentication/generateOTP', {
                ...values,
                pwd: encryptData(values.pwd),
                isEncrypted: true,
            });
            localStorage.setItem('csrfToken', res.data.csrfToken);
            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: res,
            });
            return true;
        } catch (err) {
            dispatch({
                type: AUTH_ERROR,
                payload: err.response.data,
            });
            return false;
        }
    };
}

export function loginSuccess(values: any) {
    return async function(dispatch: DispatchType) {
        try {
            dispatch({
                type: USER_LOGIN_LOADING,
            });
            const res = await axios.post('/login', { 
                ...values, 
                pwd: encryptData(values.pwd), 
                isEncrypted: true 
            });
            const {
                generated_password_changed,
                homePage: { url },
                user_settings,
            } = res.data.user.user;
            dispatch({
                type: USER_LOGIN_AUTH,
                payload: { generated_password_changed, url, user_settings },
            });
            const { email, _id: userId, client_access: clients, name, client } =
                res?.data?.user?.user ?? {};
            dispatch({
                type: SELECT_ACTIVE_CLIENT,
                payload: client,
            });
            apm.setUserContext({
                id: userId,
                username: name,
                email,
            });
            recordCleverTapProfile({
                email,
                userId,
                clients,
            });
            localStorage.setItem('UserRootDirectory', url);
            return true;
        } catch (err) {
            dispatch({
                type: AUTH_GENCODE_ERROR,
            });
            return false;
        }
    };
}

export const logout = (callback?: () => void) => {
    return async function(dispatch: DispatchType) {
        try {
            await axios.get('/logout');
            dispatch({ type: USER_LOGOUT });
            return callback ? callback() : dispatch(push('/login'));
        } catch (err) {
            if (
                err?.response?.data?.name === 'user_not_logged_in' ||
                err?.response?.status === 302
            ) {
                dispatch({ type: USER_LOGOUT });
            }
            return callback ? callback() : dispatch(push('/login'));
        } finally {
            localStorage.removeItem('csrfToken');
            localStorage.removeItem('currentAccount');
        }
    };
};

export function forgotPasswordRequest(values: any) {
    return async function(dispatch: DispatchType) {
        try {
            dispatch({
                type: USER_LOGIN_LOADING,
            });
            const res = await axios.post('/requestPassword', values);
            dispatch({
                type: USER_FORGOT_PASSWORD_SUCCESS,
                payload: res,
            });
            return true;
        } catch (err) {
            dispatch({
                type: AUTH_ERROR_FORGOT,
            });
            return false;
        }
    };
}

export function resetPasswordRequest(values: any) {
    return async function(dispatch: DispatchType) {
        try {
            dispatch({
                type: USER_LOGIN_LOADING,
            });
            const res = await axios.post('/resetPassword', {
                resetToken: sessionStorage.getItem('resetToken'),
                password: encryptData(values.newPassword),
                isEncrypted: true,
            });
            dispatch({
                type: USER_RESET_PASSWORD_SUCCESS,
                payload: res,
            });
            return true;
        } catch (err) {
            dispatch(dispatchError(err, RESET_ERROR));
            return false;
        }
    };
}

export const resetMessages = () => {
    return {
        type: HIDE_MESSAGES,
    };
};

export function signup(values: any) {
    return async function(dispatch: DispatchType) {
        try {
            dispatch({
                type: USER_SIGNUP_LOADING,
            });

            await axios.post('/user/createSandboxUser', {
                name: values.name,
                email: values.email,
                privacy_notice_consent: values.privacyNoticeConsent,
            });

            dispatch({
                type: USER_SIGNUP_SUCCESS,
            });
            return true;
        } catch (err) {
            // Backend is returning special 409 err code for duplicated (already existing) users/emails
            // with expected response message ~"This Name or Email Address already exists."
            if (err.response.status === 409) {
                dispatch({
                    type: USER_SIGNUP_FAILURE,
                    payload: { message: err.response.data.message },
                });
            } else {
                dispatch({
                    type: USER_SIGNUP_FAILURE,
                    payload: { message: 'Unexpected error occured' },
                });
            }
            return false;
        }
    };
}

