export const requestConstant = type => `${type}_REQUEST`;
export const successConstant = type => `${type}_SUCCESS`;
export const errorConstant = type => `${type}_ERROR`;

// User
export const USER_LOGIN_AUTH = 'USER_LOGIN_AUTH';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const RESET_ERROR = 'RESET_ERROR';
export const AUTH_GENCODE_ERROR = 'AUTH_GENCODE_ERROR';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const AUTH_ERROR_FORGOT = 'AUTH_ERROR_FORGOT';
export const USER_LOGIN_LOADING = 'USER_LOGIN_LOADING';
export const USER_SIGNUP_LOADING = 'USER_SIGNUP_LOADING';
export const USER_SIGNUP_FAILURE = 'USER_SIGNUP_FAILURE';
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS';
export const HIDE_MESSAGES = 'HIDE_MESSAGES';
export const FAILED_LOGIN = 'FAILED_LOGIN';
export const RESET_LOGIN_FORM = 'RESET_LOGIN_FORM';
export const USER_PROFILE_DATA_REQUEST = 'USER_PROFILE_DATA_REQUEST';
export const USER_PROFILE_DATA_SUCCESS = 'USER_PROFILE_DATA_SUCCESS';
export const USER_PROFILE_CHANGE_PASSWORD_REQUEST =
    'USER_PROFILE_CHANGE_PASSWORD_REQUEST';
export const USER_PROFILE_CHANGE_PASSWORD_SUCCESS =
    'USER_PROFILE_CHANGE_PASSWORD_SUCCESS';
export const USER_PROFILE_CHANGE_PASSWORD_FAILURE =
    'USER_PROFILE_CHANGE_PASSWORD_FAILURE';
export const USER_PROFILE_SETTINGS_UPDATE_REQUEST =
    'USER_PROFILE_SETTINGS_UPDATE_REQUEST';
export const USER_PROFILE_SETTINGS_UPDATE_SUCCESS =
    'USER_PROFILE_SETTINGS_UPDATE_SUCCESS';
export const CLEAR_CHANGE_PASSWORD_STATUS_MESSAGES =
    'CLEAR_CHANGE_PASSWORD_STATUS_MESSAGES';

// Sidebar
export const GET_SIDEBAR_DATA = 'GET_SIDEBAR_DATA';
export const SESSIONID_EXPIRED = 'SESSIONID_EXPIRED';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';

// Header
export const GET_USER_CLIENTS_LIST_REQUEST = 'GET_USER_CLIENTS_LIST_REQUEST';
export const GET_USER_CLIENTS_LIST_SUCCESS = 'GET_USER_CLIENTS_LIST_SUCCESS';
export const SELECT_ACTIVE_CLIENT = 'SELECT_ACTIVE_CLIENT';
export const SELECT_ACTIVE_RELOAD = 'SELECT_ACTIVE_RELOAD';

// Dashboard
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';

export const GET_CLIENT_DASHBOARD_DATA_REQUEST =
    'GET_CLIENT_DASHBOARD_DATA_REQUEST';
export const GET_CLIENT_DASHBOARD_DATA_SUCCESS =
    'GET_CLIENT_DASHBOARD_DATA_SUCCESS';
export const GET_CLIENT_DASHBOARD_DATA_ERROR =
    'GET_CLIENT_DASHBOARD_DATA_ERROR';

// Common actions
export const COMMON_CONSTANTS = 'COMMON_CONSTANTS';
export const CHANGE_PAGE_SIZE_REQUEST = 'CHANGE_PAGE_SIZE_REQUEST';
export const REFETCH_DATA = 'REFETCH_DATA';
export const REFETCH_DATA_REQUEST = 'REFETCH_DATA_REQUEST';
export const COUNTRIES = 'COUNTRIES';
export const REDIRECT_TO = 'REDIRECT_TO';
export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const SHOW_POPUP = 'SHOW_POPUP';
export const HIDE_POPUP = 'HIDE_POPUP';

// =================== Pages =================== //
// Pre Check Documents
export const PRE_CHECK_DOCUMENTS_REQUEST = 'PRE_CHECK_DOCUMENTS_REQUEST';
export const PRE_CHECK_DOCUMENTS_SUCCESS = 'PRE_CHECK_DOCUMENTS_SUCCESS';
export const PRE_CHECK_DOCUMENT_REMOVED = 'PRE_CHECK_DOCUMENT_REMOVED';

export const FILE_UPLOADED = 'FILE_UPLOADED';
export const FILE_UPLOAD_FAILED = 'FILE_UPLOAD_FAILED';

// Clients
export const GET_BULK_CLIENTS = 'GET_BULK_CLIENTS';
export const API_ERROR = 'API_ERROR';

// Accounts
export const CURRENT_CLIENT_ACCOUNT_LIST_REQUEST =
    'CURRENT_CLIENT_ACCOUNT_LIST_REQUEST';
export const CURRENT_CLIENT_ACCOUNT_LIST_SUCCESS =
    'CURRENT_CLIENT_ACCOUNT_LIST_SUCCESS';
export const CURRENT_CLIENT_ACCOUNT_LIST_FAILURE =
    'CURRENT_CLIENT_ACCOUNT_LIST_FAILURE';

// Account summary
export const ACCOUNT_SUMMARY_DATA_REQUEST = 'ACCOUNT_SUMMARY_DATA_REQUEST';
export const ACCOUNT_SUMMARY_DATA_SUCCESS = 'ACCOUNT_SUMMARY_DATA_SUCCESS';
export const ACCOUNT_SUMMARY_DATA_FAILURE = 'ACCOUNT_SUMMARY_DATA_FAILURE';
export const ACCOUNT_SUMMARY_FILTER_REQUEST = 'ACCOUNT_SUMMARY_FILTER_REQUEST';

export const ERROR = 'ERROR';
export const APP_ERROR = 'APP_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

// Transaction History
export const TRANSACTION_HISTORY_DATA_REQUEST =
    'TRANSACTION_HISTORY_DATA_REQUEST';
export const TRANSACTION_HISTORY_DATA_SUCCESS =
    'TRANSACTION_HISTORY_DATA_SUCCESS';
export const TRANSACTION_HISTORY_DATA_FAILURE =
    'TRANSACTION_HISTORY_DATA_FAILURE';
export const TRANSACTION_HISTORY_CSV_DOWNLOAD_REQUEST =
    'TRANSACTION_HISTORY_CSV_DOWNLOAD_REQUEST';
export const TRANSACTION_HISTORY_CSV_DOWNLOAD_SUCCESS =
    'TRANSACTION_HISTORY_CSV_DOWNLOAD_SUCCESS';
export const TRANSACTION_HISTORY_CSV_DOWNLOAD_FAILURE =
    'TRANSACTION_HISTORY_CSV_DOWNLOAD_FAILURE';
export const TRANSACTION_HISTORY_FILTER_GENERATE_REQUEST =
    'TRANSACTION_HISTORY_FILTER_GENERATE_REQUEST';
export const TRANSACTION_HISTORY_NOTIFY_RECIPIENT_REQUEST =
    'TRANSACTION_HISTORY_NOTIFY_RECIPIENT_REQUEST';
export const TRANSACTION_HISTORY_GENARATE_RECEIPT_REQUEST =
    'TRANSACTION_HISTORY_GENARATE_RECEIPT_REQUEST';
export const TRANSACTION_HISTORY_GENARATE_RECEIPT_SUCCESS =
    'TRANSACTION_HISTORY_GENARATE_RECEIPT_SUCCESS';
export const TRANSACTION_HISTORY_GENARATE_RECEIPT_FAILURE =
    'TRANSACTION_HISTORY_GENARATE_RECEIPT_FAILURE';
export const TRANSACTION_HISTORY_CANCEL_TRANSACTION_REQUEST =
    'TRANSACTION_HISTORY_CANCEL_TRANSACTION_REQUEST';
export const TRANSACTION_HISTORY_CANCEL_TRANSACTION_SUCCESS =
    'TRANSACTION_HISTORY_CANCEL_TRANSACTION_SUCCESS';
export const TRANSACTION_HISTORY_CANCEL_TRANSACTION_FAILURE =
    'TRANSACTION_HISTORY_CANCEL_TRANSACTION_FAILURE';

// Upload Batch Report
export const UPLOAD_BATCH_DATA_REQUEST = 'UPLOAD_BATCH_DATA_REQUEST';
export const UPLOAD_BATCH_DATA_SUCCESS = 'UPLOAD_BATCH_DATA_SUCCESS';
export const UPLOAD_BATCH_DATA_FAILURE = 'UPLOAD_BATCH_DATA_FAILURE';
export const UPLOAD_BATCH_STATUS_DATA_REQUEST =
    'UPLOAD_BATCH_STATUS_DATA_REQUEST';
export const UPLOAD_BATCH_STATUS_DATA_SUCCESS =
    'UPLOAD_BATCH_STATUS_DATA_SUCCESS';
export const UPLOAD_BATCH_STATUS_DATA_FAILURE =
    'UPLOAD_BATCH_STATUS_DATA_FAILURE';
export const UPLOAD_BATCH_FILTER_GENERATE_REQUEST =
    'UPLOAD_BATCH_FILTER_GENERATE_REQUEST';
export const UPLOAD_BATCH_ACCOUNTS_BY_CLIENT =
    'UPLOAD_BATCH_ACCOUNTS_BY_CLIENT';
export const UPLOAD_BATCH_SUMMARY_DOWNLOAD_REQUEST =
    'UPLOAD_BATCH_SUMMARY_DOWNLOAD_REQUEST';
export const UPLOAD_BATCH_REPORT_DOWNLOAD_REQUEST =
    'UPLOAD_BATCH_REPORT_DOWNLOAD_REQUEST';
export const UPLOAD_BATCH_CSV_DOWNLOAD_REQUEST =
    'UPLOAD_BATCH_CSV_DOWNLOAD_REQUEST';
export const UPLOAD_BATCH_CSV_DOWNLOAD_SUCCESS =
    'UPLOAD_BATCH_CSV_DOWNLOAD_SUCCESS';
export const UPLOAD_BATCH_CSV_DOWNLOAD_FAILURE =
    'UPLOAD_BATCH_CSV_DOWNLOAD_FAILURE';

// Account Statement
export const ACCOUNT_STATEMENT_ACCOUNTS_BY_CLIENT =
    'ACCOUNT_STATEMENT_ACCOUNTS_BY_CLIENT';
export const ACCOUNT_STATEMENT_DATA_REQUEST = 'ACCOUNT_STATEMENT_DATA_REQUEST';
export const ACCOUNT_STATEMENT_DATA_SUCCESS = 'ACCOUNT_STATEMENT_DATA_SUCCESS';
export const ACCOUNT_STATEMENT_DATA_FAILURE = 'ACCOUNT_STATEMENT_DATA_FAILURE';
export const ACCOUNT_STATEMENT_FILTER_REQUEST =
    'ACCOUNT_STATEMENT_FILTER_REQUEST';
export const ACCOUNT_STATEMENT_DOWNLOAD_SUCCESS =
    'ACCOUNT_STATEMENT_DOWNLOAD_SUCCESS';
export const ACCOUNT_STATEMENT_DOWNLOAD_FAILURE =
    'ACCOUNT_STATEMENT_DOWNLOAD_FAILURE';
export const ACCOUNT_STATEMENT_DOWNLOAD_REQUEST =
    'ACCOUNT_STATEMENT_DOWNLOAD_REQUEST';
export const ACCOUNT_STATEMENT_ACCOUNT_INFORMATION_REQUEST =
    'ACCOUNT_STATEMENT_ACCOUNT_INFORMATION_REQUEST';
export const ACCOUNT_STATEMENT_ACCOUNT_INFORMATION_SUCCESS =
    'ACCOUNT_STATEMENT_ACCOUNT_INFORMATION_SUCCESS';
export const ACCOUNT_STATEMENT_ACCOUNT_INFORMATION_FAILURE =
    'ACCOUNT_STATEMENT_ACCOUNT_INFORMATION_FAILURE';

// Fx History Report
export const FX_HISTORY_DATA_REQUEST = 'FX_HISTORY_DATA_REQUEST';
export const FX_HISTORY_DATA_SUCCESS = 'FX_HISTORY_DATA_SUCCESS';
export const FX_HISTORY_DATA_FAILURE = 'FX_HISTORY_DATA_FAILURE';
export const FX_HISTORY_CSV_DOWNLOAD_REQUEST =
    'FX_HISTORY_CSV_DOWNLOAD_REQUEST';
export const FX_HISTORY_CSV_DOWNLOAD_SUCCESS =
    'FX_HISTORY_CSV_DOWNLOAD_SUCCESS';
export const FX_HISTORY_CSV_DOWNLOAD_FAILURE =
    'FX_HISTORY_CSV_DOWNLOAD_FAILURE';
export const FX_HISTORY_FILTER_GENERATE_REQUEST =
    'FX_HISTORY_FILTER_GENERATE_REQUEST';

// Send Funds
export const SEND_FUNDS_HISTORY_REQUEST = 'SEND_FUNDS_HISTORY_REQUEST';
export const SEND_FUNDS_HISTORY_SUCCESS = 'SEND_FUNDS_HISTORY_SUCCESS';
export const SEND_FUNDS_HISTORY_FAILURE = 'SEND_FUNDS_HISTORY_FAILURE';

// BookFx
export const BOOK_FX_ACCOUNTS_LIST_REQUEST = 'BOOK_FX_ACCOUNTS_LIST_REQUEST';
export const BOOK_FX_ACCOUNTS_LIST_REQUEST_SUCCESS =
    'BOOK_FX_ACCOUNTS_LIST_REQUEST_SUCCESS';
export const BOOK_FX_ACCOUNTS_LIST_REQUEST_FAILURE =
    'BOOK_FX_ACCOUNTS_LIST_REQUEST_FAILURE';
export const CURRENCY_EXCHANGE_HISTORY_REQUEST =
    'CURRENCY_EXCHANGE_HISTORY_REQUEST';
export const CURRENCY_EXCHANGE_HISTORY_SUCCESS =
    'CURRENCY_EXCHANGE_HISTORY_SUCCESS';
export const CURRENCY_EXCHANGE_HISTORY_FAILURE =
    'CURRENCY_EXCHANGE_HISTORY_FAILURE';
export const BOOK_FX_CALCULATE_AMOUNTS_REQUEST =
    'BOOK_FX_CALCULATE_AMOUNTS_REQUEST';
export const BOOK_FX_CALCULATE_AMOUNTS_SUCCESS =
    'BOOK_FX_CALCULATE_AMOUNTS_SUCCESS';
export const BOOK_FX_CALCULATE_AMOUNTS_FAILURE =
    'BOOK_FX_CALCULATE_AMOUNTS_FAILURE';

// Bank Details
export const GET_BANKS_DETAILS = 'GET_BANKS_DETAILS';
export const GET_BANKS_DETAILS_SUCCESS = 'GET_BANKS_DETAILS_SUCCESS';
export const GET_BANKS_DETAILS_FAIL = 'GET_BANKS_DETAILS_FAIL';

// Payment Approval
export const PAYMENT_APPROVAL_BATCH_DOWNLOAD =
    'PAYMENT_APPROVAL_BATCH_DOWNLOAD';
export const PAYMENT_APPROVAL_DATA_REQUEST = 'PAYMENT_APPROVAL_DATA_REQUEST';
export const PAYMENT_APPROVAL_DATA_SUCCESS = 'PAYMENT_APPROVAL_DATA_SUCCESS';
export const PAYMENT_APPROVAL_DATA_FAILURE = 'PAYMENT_APPROVAL_DATA_FAILURE';
export const PAYMENT_APPROVAL_SUMMARY_DOWNLOAD_REQUEST =
    'PAYMENT_APPROVAL_SUMMARY_DOWNLOAD_REQUEST';
export const PAYMENT_APPROVAL_BATCH_DOWNLOAD_REQUEST =
    'PAYMENT_APPROVAL_BATCH_DOWNLOAD_REQUEST';
export const PAYMENT_APPROVAL_STAGE1_REQUEST =
    'PAYMENT_APPROVAL_STAGE1_REQUEST';
export const PAYMENT_APPROVAL_STAGE2_REQUEST =
    'PAYMENT_APPROVAL_STAGE2_REQUEST';
export const PAYMENT_APPROVAL_REJECT_STAGE1_REQUEST =
    'PAYMENT_APPROVAL_REJECT_STAGE1_REQUEST';
export const PAYMENT_APPROVAL_REJECT_STAGE2_REQUEST =
    'PAYMENT_APPROVAL_REJECT_STAGE2_REQUEST';

// Rfi Response Compliance
export const RFI_RESPONSE_DATA_REQUEST = 'RFI_RESPONSE_DATA_REQUEST';
export const RFI_RESPONSE_DATA_SUCCESS = 'RFI_RESPONSE_DATA_SUCCESS';
export const RFI_RESPONSE_DOC_NAMES_SUCCESS = 'RFI_RESPONSE_DOC_NAMES_SUCCESS';
export const RFI_RESPONSE_TRANSACTION_DATA_REQUEST =
    'RFI_RESPONSE_TRANSACTION_DATA_REQUEST';
export const RFI_RESPONSE_TRANSACTION_DATA_SUCCESS =
    'RFI_RESPONSE_TRANSACTION_DATA_SUCCESS';
export const RFI_RESPONSE_CANCEL_TRANSACTION_REQUEST =
    'RFI_RESPONSE_CANCEL_TRANSACTION_REQUEST';
export const RFI_RESPONSE_CANCEL_TRANSACTION_SUCCESS =
    'RFI_RESPONSE_CANCEL_TRANSACTION_SUCCESS';
export const RFI_RESPONSE_SUBMIT_REQUEST = 'RFI_RESPONSE_SUBMIT_REQUEST';
export const RFI_RESPONSE_SUBMIT_SUCCESS = 'RFI_RESPONSE_SUBMIT_SUCCESS';
export const RFI_RESPONSE_SAVE_PARENT_STATE = 'RFI_RESPONSE_SAVE_PARENT_STATE';

export const COMPLIANCE_RFI_GET_RFIDOCSDETAILS_REQUEST =
    'COMPLIANCE_RFI_GET_RFIDOCSDETAILS_REQUEST';
export const COMPLIANCE_RFI_GET_RFIDOCSDETAILS_SUCCESS =
    'COMPLIANCE_RFI_GET_RFIDOCSDETAILS_SUCCESS';

// Upload Transactions
export const UPLOAD_TRANSACTIONS_TEMPLATE_DOWNLOAD_REQUEST =
    'UPLOAD_TRANSACTIONS_TEMPLATE_DOWNLOAD_REQUEST';
export const UPLOAD_TRANSACTIONS_TEMPLATE_DOWNLOAD_RESPONSE =
    'UPLOAD_TRANSACTIONS_TEMPLATE_DOWNLOAD_RESPONSE';
export const UPLOAD_TRANSACTIONS_TEMPLATE_DOWNLOAD_ERROR =
    'UPLOAD_TRANSACTIONS_TEMPLATE_DOWNLOAD_ERROR';
export const UPLOAD_TRANSACTIONS_VALIDATE_FILE_REQUEST =
    'UPLOAD_TRANSACTIONS_VALIDATE_FILE_REQUEST';
export const UPLOAD_TRANSACTIONS_VALIDATE_FILE_SUCCESS =
    'UPLOAD_TRANSACTIONS_VALIDATE_FILE_SUCCESS';
export const UPLOAD_TRANSACTIONS_VALIDATE_FILE_FAILURE =
    'UPLOAD_TRANSACTIONS_VALIDATE_FILE_FAILURE';
export const UPLOAD_TRANSACTIONS_SUBMIT_FILE_REQUEST =
    'UPLOAD_TRANSACTIONS_SUBMIT_FILE_REQUEST';
export const UPLOAD_TRANSACTIONS_SUBMIT_FILE_SUCCESS =
    'UPLOAD_TRANSACTIONS_SUBMIT_FILE_SUCCESS';
export const UPLOAD_TRANSACTIONS_SUBMIT_FILE_FAILURE =
    'UPLOAD_TRANSACTIONS_SUBMIT_FILE_FAILURE';
export const UPLOAD_TRANSACTIONS_ERROR_FILE_REQUEST =
    'UPLOAD_TRANSACTIONS_ERROR_FILE_REQUEST';
export const UPLOAD_TRANSACTIONS_ACCOUNTS_BY_CLIENT =
    'UPLOAD_TRANSACTIONS_ACCOUNTS_BY_CLIENT';

// SinglePayment
export const GET_CLIENT_PAYMENT_DATA = 'GET_CLIENT_PAYMENT_DATA';
export const SINGLE_PAYMENT_CONSTANTS = 'SINGLE_PAYMENT_CONSTANTS';
export const GET_CLIENT_PAYMENT_DATA_SUCCESS =
    'GET_CLIENT_PAYMENT_DATA_SUCCESS';
export const GET_CLIENT_PAYMENT_DATA_FAIL = 'GET_CLIENT_PAYMENT_DATA_FAIL';
export const MAKE_SINGLE_PAYMENT = 'MAKE_SINGLE_PAYMENT';
export const MAKE_SINGLE_PAYMENT_SUCCESS = 'MAKE_SINGLE_PAYMENT_SUCCESS';
export const MAKE_SINGLE_PAYMENT_FAILURE = 'MAKE_SINGLE_PAYMENT_FAILURE';

export const GET_REMITTER_NAME_LOOKUP = 'GET_REMITTER_NAME_LOOKUP';
export const GET_BENEFICIARY_NAME_LOOKUP = 'GET_BENEFICIARY_NAME_LOOKUP';
export const GET_REMITTER_AUTOFILL_DATA = 'GET_REMITTER_AUTOFILL_DATA';
export const GET_BENEFICIARY_AUTOFILL_DATA = 'GET_BENEFICIARY_AUTOFILL_DATA';

// Company Profile
export const COMPANY_PROFILE_DATA_REQUEST = 'COMPANY_PROFILE_DATA_REQUEST';
export const COMPANY_PROFILE_DATA_SUCCESS = 'COMPANY_PROFILE_DATA_SUCCESS';
export const COMPANY_PROFILE_SETTINGS_UPDATE_REQUEST =
    'COMPANY_PROFILE_SETTINGS_UPDATE_REQUEST';
export const COMPANY_PROFILE_SETTINGS_UPDATE_SUCCESS =
    'COMPANY_PROFILE_SETTINGS_UPDATE_SUCCESS';
export const COMPANY_PROFILE_ACCOUNTS_PAIRS_DATA_REQUEST =
    'COMPANY_PROFILE_ACCOUNTS_PAIRS_DATA_REQUEST';
export const COMPANY_PROFILE_ACCOUNTS_PAIRS_DATA_SUCCESS =
    'COMPANY_PROFILE_ACCOUNTS_PAIRS_DATA_SUCCESS';
export const COMPANY_PROFILE_CREATE_ACC_PAIRS_REQUEST =
    'COMPANY_PROFILE_CREATE_ACC_PAIRS_REQUEST';
export const COMPANY_PROFILE_CREATE_ACC_PAIRS_SUCCESS =
    'COMPANY_PROFILE_CREATE_ACC_PAIRS_SUCCESS';
export const COMPANY_PROFILE_DELETE_ACC_PAIRS_REQUEST =
    'COMPANY_PROFILE_DELETE_ACC_PAIRS_REQUEST';
export const COMPANY_PROFILE_DELETE_ACC_PAIRS_SUCCESS =
    'COMPANY_PROFILE_DELETE_ACC_PAIRS_SUCCESS';
export const COMPANY_PROFILE_PAGE_ERROR = 'COMPANY_PROFILE_PAGE_ERROR';

// OnfidoIdentification
export const ONFIDO_SDK_TOKEN_REQUEST = 'ONFIDO_SDK_TOKEN_REQUEST';
export const ONFIDO_INITIATE_CHECK = 'ONFIDO_INITIATE_CHECK';

// Fund Account by Card
export const FUND_ACCOUNT_BY_CARD_REQUEST = 'FUND_ACCOUNT_BY_CARD_REQUEST';
export const FUND_ACCOUNT_BY_CARD_SUCCESS = 'FUND_ACCOUNT_BY_CARD_SUCCESS';
export const FUND_ACCOUNT_BY_CARD_FAILURE = 'FUND_ACCOUNT_BY_CARD_FAILURE';

export const GET_TRANSACTION_STATUS = 'GET_TRANSACTION_STATUS';
export const GET_TRANSACTION_STATUS_SUCCESS = 'GET_TRANSACTION_STATUS_SUCCESS';
export const GET_TRANSACTION_STATUS_FAILURE = 'GET_TRANSACTION_STATUS_FAILURE';
export const GET_TRANSACTION_DATA_REQUEST = 'GET_TRANSACTION_DATA_REQUEST';
export const GET_TRANSACTION_DATA_SUCCESS = 'GET_TRANSACTION_DATA_SUCCESS';
export const GET_TRANSACTION_DATA_FAILURE = 'GET_TRANSACTION_DATA_FAILURE';
export const GET_VALID_BIN_NUMBERS_REQUEST = 'GET_VALID_BIN_NUMBERS_REQUEST';
export const GET_VALID_BIN_NUMBERS_SUCCESS = 'GET_VALID_BIN_NUMBERS_SUCCESS';
export const GET_VALID_BIN_NUMBERS_FAILURE = 'GET_VALID_BIN_NUMBERS_FAILURE';

// Fund Account by Deposit
export const SEND_FUNDS_CREATE_NEW_FUND_REQUEST =
    'SEND_FUNDS_CREATE_NEW_FUND_REQUEST';
export const SEND_FUNDS_CREATE_NEW_FUND_SUCCESS =
    'SEND_FUNDS_CREATE_NEW_FUND_SUCCESS';
export const SEND_FUNDS_CREATE_NEW_FUND_FAILURE =
    'SEND_FUNDS_CREATE_NEW_FUND_FAILURE';

// Biz-Payment fetching beneficiary

export const GET_BIZPAY_BENEFICIARY_DETAILS_REQUEST =
    'GET_BIZPAY_BENEFICIARY_DETAILS_REQUEST';
export const GET_BIZPAY_BENEFICIARY_DETAILS_SUCCESS =
    'GET_BIZPAY_BENEFICIARY_DETAILS_SUCCESS';
export const GET_BIZPAY_BENEFICIARY_DETAILS_FAILURE =
    'GET_BIZPAY_BENEFICIARY_DETAILS_FAILURE';

//biz-payment fetch payout

export const GET_BIZPAY_BENEFICIARY_PAYOUT_REQUEST =
    'GET_BIZPAY_BENEFICIARY_PAYOUT_REQUEST';
export const GET_BIZPAY_BENEFICIARY_PAYOUT_SUCCESS =
    'GET_BIZPAY_BENEFICIARY_PAYOUT_SUCCESS';
export const GET_BIZPAY_BENEFICIARY_PAYOUT_FAILURE =
    'GET_BIZPAY_BENEFICIARY_PAYOUT_FAILURE';
export const GET_BIZPAY_BENEFICIARY_PAYOUT_CLEAR =
    'GET_BIZPAY_BENEFICIARY_PAYOUT_CLEAR';
export const CREATE_NEW_PAYOUT_METHOD_SUCCESS =
    'CREATE_NEW_PAYOUT_METHOD_SUCCESS';
export const UPDATE_PAYOUT_METHOD_SUCCESS = 'UPDATE_PAYOUT_METHOD_SUCCESS';
// OPGSP -Payin restricted country
export const GET_PAYIN_RESTRICTED_COUNTRY_REQUEST =
    'GET_PAYIN_RESTRICTED_COUNTRY_REQUEST';
export const GET_PAYIN_RESTRICTED_COUNTRY_SUCCESS =
    'GET_PAYIN_RESTRICTED_COUNTRY_SUCCESS';
export const GET_PAYIN_RESTRICTED_COUNTRY_FAILURE =
    'GET_PAYIN_RESTRICTED_COUNTRY_FAILURE';

// BPSP - Save Cards
export const GET_SAVED_CARD = 'GET_SAVED_CARD';
export const GET_SAVED_CARD_SUCCESS = 'GET_SAVED_CARD_SUCCESS';
export const GET_SAVED_CARD_FAILURE = 'GET_SAVED_CARD_FAILURE';
export const SAVE_CARD_REQUEST = 'SAVE_CARD_REQUEST';
export const GET_ALL_SAVED_CARDS = 'GET_ALL_SAVED_CARDS';
export const GET_ALL_SAVED_CARDS_SUCCESS = 'GET_ALL_SAVED_CARDS_SUCCESS';
export const GET_ALL_SAVED_CARDS_FAILURE = 'GET_ALL_SAVED_CARDS_FAILURE';

// lock FX rate signature

export const GET_FX_RATE_SIGNATURE_REQUEST = 'GET_FX_RATE_SIGNATURE_REQUEST';
export const GET_FX_RATE_SIGNATURE_SUCCESS = 'GET_FX_RATE_SIGNATURE_SUCCESS';
export const GET_FX_RATE_SIGNATURE_FAILURE = 'GET_FX_RATE_SIGNATURE_FAILURE';

// lock FX rate

export const GET_LOCK_FX_RATE_REQUEST = 'GET_LOCK_FX_RATE_REQUEST';
export const GET_LOCK_FX_RATE_SUCCESS = 'GET_LOCK_FX_RATE_SUCCESS';
export const GET_LOCK_FX_RATE_FAILURE = 'GET_LOCK_FX_RATE_FAILURE';

// create new payout

export const GET_NEW_PAYOUT_OBJECT_REQUEST = 'GET_NEW_PAYOUT_OBJECT_REQUEST';
export const GET_NEW_PAYOUT_OBJECT_SUCCESS = 'GET_NEW_PAYOUT_OBJECT_SUCCESS';
export const GET_NEW_PAYOUT_OBJECT_FAILURE = 'GET_NEW_PAYOUT_OBJECT_FAILURE';

// validate payout
export const VALIDATE_PAYOUT_REQUEST = 'VALIDATE_PAYOUT_REQUEST';
export const VALIDATE_PAYOUT_SUCCESS = 'VALIDATE_PAYOUT_SUCCESS';
export const VALIDATE_PAYOUT_FAILURE = 'VALIDATE_PAYOUT_FAILURE';

// holiday calendar
export const HOLIDAY_CALENDAR_DATA_REQUEST = 'HOLIDAY_CALENDAR_DATA_REQUEST';
export const HOLIDAY_CALENDAR_DATA_SUCCESS = 'HOLIDAY_CALENDAR_DATA_SUCCESS';
export const HOLIDAY_CALENDAR_DATA_FAILURE = 'HOLIDAY_CALENDAR_DATA_FAILURE';
export const HOLIDAY_CALENDAR_FILTER_REQUEST = 'HOLIDAY_CALENDAR_FILTER_REQUEST';
export const HOLIDAY_CALENDAR_DOWNLOAD_REQUEST = 'HOLIDAY_CALENDAR_DOWNLOAD_REQUEST';
export const HOLIDAY_CALENDAR_DOWNLOAD_SUCCESS = 'HOLIDAY_CALENDAR_DOWNLOAD_SUCCESS';
export const HOLIDAY_CALENDAR_DOWNLOAD_FAILURE = 'HOLIDAY_CALENDAR_DOWNLOAD_FAILURE';

// Client Settings
export const CLIENT_SETTINGS_ACTION = 'CLIENT_SETTINGS_ACTION';