import produce from 'immer';
import * as types from '../constants/Types';
import {
    requestConstant,
    successConstant,
    errorConstant,
} from '../../../../actions/types';
import { IClientVraResponse, IOfferedCurrencies } from '../VRAStatement/interfaces/IClientVraResponse';
import { getCurrencyLabelString } from '../constants/Constants';
import {
    IVrasData,
    VRASTFilterEnum,
} from '../VRAStatement/interfaces/VRAInterfaces';
import { IVrasTransaction } from '../VRAStatement/interfaces/IVrasTransaction';

export const initialVRASTCountByStatus = (() => {
    return Object.values(VRASTFilterEnum).reduce((acc: any, e) => {
        acc[e] = 0;
        return acc;
    }, {});
})();
export const initialVRAS: IVrasData = {
    currencyCode: '',
    payerId: '',
    payerName: '',
    transactionCountByStatus: initialVRASTCountByStatus,
    transactions: [],
    virtualAccountNumber: '',
    virtualReceivingAccountId: '',
};

const initialState = {
    isVraLoading: false,
    isVraAllocating: false,
    wallets: [],
    receiveCurrencies: [],
    isVRASLoading: false,
    vraStatement: initialVRAS,
};

export const virtualReceivingAccountReducer = (state: any = initialState, action: any = {}): any => {
    switch (action.type) {
        case types.PAYIN_VIRTUAL_ACCOUNTS_FILTER_SUCCESS: {
            const { currencyCode, virtualAccounts, label } = action.payload;
            /* there is bug in filterVra api request, so we are only
            capturing virtualAccounts and updating it in existing wallet
            list
             */
            return produce(state, (draft: any) => {
                const selectedWallet: IClientVraResponse = draft.wallets.find(
                    (wallet: IClientVraResponse) =>
                        getCurrencyLabelString(
                            wallet?.currencyCode ?? '',
                            wallet?.label ?? []
                        ) === getCurrencyLabelString(currencyCode, label)
                );
                selectedWallet.virtualAccounts = virtualAccounts;
            });
        }

        case types.CLIENT_VRA_ALLOCATION_REQUEST: {
            return {
                ...state,
                isVraAllocating: true,
            };
        }

        case types.CLIENT_VRA_REQUEST_SUCCESS:
        case types.CLIENT_VRA_ALLOCATION_FAILURE: {
            return {
                ...state,
                isVraAllocating: false,
            };
        }

        case types.CLIENT_VRA_ALLOCATION_SUCCESS: {
            return {
                ...state,
                isVraAllocating: false,
                wallets: [...state.wallets, ...action.payload],
            };
        }

        case types.PAYIN_GET_VIRTUAL_ACCOUNTS_REQUEST: {
            return { ...state, isVraLoading: true };
        }

        case types.PAYIN_GET_VIRTUAL_ACCOUNTS_SUCCESS: {
            const vraAccounts = [...action.payload].reduce((vraAccountsFormatted: IClientVraResponse[], vraAccount: IClientVraResponse) => {
                if(vraAccount?.offeredCurrencies && vraAccount?.offeredCurrencies?.length > 0) {
                    const vraAccountModified: IClientVraResponse[] | [] = vraAccount.offeredCurrencies?.map((currencyItem: IOfferedCurrencies): IClientVraResponse  => {
                            return {
                                ...currencyItem,
                                currencyCode: currencyItem.currencyCode,
                                totalVirtualAccounts: vraAccount?.totalVirtualAccounts,
                                virtualAccounts: vraAccount?.virtualAccounts
                            }
                        }) || [];
                    return [
                        ...vraAccountsFormatted,
                        ...vraAccountModified
                    ];
                } else {
                    return [
                        ...vraAccountsFormatted,
                        vraAccount
                    ];
                }
            }, []);
            return { 
                ...state, 
                wallets: vraAccounts,
                isVraLoading: false,
            };
        }

        case types.PAYIN_GET_VIRTUAL_ACCOUNTS_FAILURE: {
            return {
                ...state,
                isVraLoading: false,
            };
        }

        case requestConstant(types.GET_VRAS): {
            return { 
                ...state,
                isVRASLoading: true,
                vrasRequestPayload: action.payload,
            };
        }

        case successConstant(types.GET_VRAS): {
            return {
                ...state,
                isVRASLoading: false,
                vraStatement: action.payload,
            };
        }
        
        case errorConstant(types.GET_VRAS): {
            return {
                ...state,
                isVRASLoading: false,
            };
        }

        case types.CHANGE_TRANSACTION_COMPLIANCE_STATUS: {
            const transactions = state.vraStatement.transactions.map(
                (vrasTransaction: IVrasTransaction) => {
                    if (vrasTransaction.id === action.payload.transactionId) {
                        vrasTransaction.subStatus = action.payload.status;
                    }
                    return vrasTransaction;
                }
            );
            return {
                ...state,
                vraStatement: {
                    ...state.vraStatement,
                    transactions,
                },
            };
        }

        case types.VRA_TAG_UNTAG_PAYER_REQUEST: {
            return {
                ...state,
                isVraLoading: true,
            };
        }

        case types.PAYIN_RECEIVE_CURRENCIES_SUCCESS: {
            return {
                ...state,
                receiveCurrencies: action.payload.receiveCurrencies,
            };
        }

        default:
            return state;
    }
};
