const uploadTransactionsReducer = (
    state = {
        accounts: [],
        templateDownloading: false,
        templateDownloadingErr: false,
        validationResults: null,
        submitting: false,
        uploading: false,
        error: null,
    },
    action = {}
) => {
    switch (action.type) {
        case 'UPLOAD_TRANSACTIONS_TEMPLATE_DOWNLOAD_REQUEST': 
            return { ...state, templateDownloading: true, templateDownloadingErr: false };
        case 'UPLOAD_TRANSACTIONS_TEMPLATE_DOWNLOAD_RESPONSE': 
            return { ...state, templateDownloading: false, templateDownloadingErr: false };
        case 'UPLOAD_TRANSACTIONS_TEMPLATE_DOWNLOAD_ERROR': 
            return { ...state, templateDownloading: false, templateDownloadingErr: action.payload };
        case 'UPLOAD_TRANSACTIONS_VALIDATE_FILE_REQUEST':
            return { ...state, uploading: true, error: null };
        case 'UPLOAD_TRANSACTIONS_VALIDATE_FILE_SUCCESS':
            return {
                ...state,
                uploading: false,
                validationResults: action.payload.data,
            };
        case 'UPLOAD_TRANSACTIONS_VALIDATE_FILE_FAILURE':
            return { ...state, uploading: false, error: action.payload };
        case 'UPLOAD_TRANSACTIONS_SUBMIT_FILE_REQUEST':
            return { ...state, submitting: true };
        case 'UPLOAD_TRANSACTIONS_SUBMIT_FILE_SUCCESS':
            return {
                ...state,
                submitting: false,
                submittionResults: action.payload.data,
            };
        case 'UPLOAD_TRANSACTIONS_SUBMIT_FILE_FAILURE':
            return { ...state, submitting: false };
        case 'UPLOAD_TRANSACTIONS_ERROR_FILE_REQUEST':
            return { ...state };
        case 'UPLOAD_TRANSACTIONS_ACCOUNTS_BY_CLIENT':
            return { ...state, accounts: action.payload.data };
        default:
            return state;
    }
};

export default uploadTransactionsReducer;
