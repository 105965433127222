import React, { FC, ReactElement, MouseEvent } from 'react';
import Style from './Modal.module.scss';

type closeEvent = (event: MouseEvent<HTMLInputElement>) => void;
interface IModalFooter {
    children: ReactElement | string;
    className?: string;
    closeModal: closeEvent;
}

export const ModalHeader: FC<IModalFooter> = ({
    closeModal,
    children,
    className = '',
}) => (
    <div className={`${Style.modal_header} ${className}`}>
        <span className={Style.title}>{children}</span>
        <span className={Style.close} onClick={closeModal}>
            &times;
        </span>
    </div>
);
