import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { clientSelect } from '../../../../../../actions/header-actions';

const UserMenuCategory = ({ filteredClients, onToggle }) => {
    const [categoryIsOpen, setCategoryIsOpen] = useState(false);
    const dispatch = useDispatch();

    const activeClientId = useSelector(state => state.header.activeClient._id);

    return (
        <div className="category-header">
            <button
                type="button"
                data-test="clients-category-button"
                className={`category-button full-width active-${categoryIsOpen}`}
                onClick={() => {
                    setCategoryIsOpen(!categoryIsOpen);
                    onToggle();
                }}
            >
                <p>Clients</p>
                <FontAwesomeIcon
                    rotation={categoryIsOpen ? 180 : null}
                    icon={faChevronDown}
                    className={
                        categoryIsOpen ? 'fa-chevron-up' : 'fa-chevron-down'
                    }
                />
            </button>
            {categoryIsOpen && (
                <div className="menu-category flex-column">
                    {filteredClients.map(item => (
                        <button
                            key={item._id}
                            type="button"
                            data-test="client-button"
                            className={`dropdown-button ${item._id ===
                                activeClientId && 'selected'}`}
                            onClick={() => dispatch(clientSelect(item._id))}
                        >
                            {item.client_name}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

UserMenuCategory.propTypes = {
    filteredClients: PropTypes.array,
    onToggle: PropTypes.func,
};

UserMenuCategory.defaultProps = {
    filteredClients: [],
    onToggle: () => { },
};

export default UserMenuCategory;
