import * as types from '../../actions/types';

const bizPaymentReducer = (
    state = {
        beneficiaryDetails: [],
        beneficiaryPayoutInfo: [],
        fetchNewPayoutObject: {},
        loading: false,
        error: null,
    },
    action = {}
) => {
    switch (action.type) {
        case types.GET_BIZPAY_BENEFICIARY_DETAILS_REQUEST:
            return { ...state, loading: true };
        case types.GET_BIZPAY_BENEFICIARY_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                beneficiaryDetails: action.payload,
            };
        case types.GET_BIZPAY_BENEFICIARY_DETAILS_FAILURE:
            return { ...state, loading: false, error: action.error };
        case types.GET_BIZPAY_BENEFICIARY_PAYOUT_REQUEST:
            return { ...state, loading: true };
        case types.GET_BIZPAY_BENEFICIARY_PAYOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                beneficiaryPayoutInfo: action.payload,
            };
        case types.GET_BIZPAY_BENEFICIARY_PAYOUT_FAILURE:
            return { ...state, loading: false, error: action.error };
        case types.GET_BIZPAY_BENEFICIARY_PAYOUT_CLEAR:
            return { ...state, loading: false, beneficiaryPayoutInfo: [] };
        case types.GET_NEW_PAYOUT_OBJECT_REQUEST:
            return { ...state, loading: true };
        case types.GET_NEW_PAYOUT_OBJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                fetchNewPayoutObject: action.payload,
            };
        case types.GET_NEW_PAYOUT_OBJECT_FAILURE:
            return { ...state, loading: false, error: action.error };
        case types.CREATE_NEW_PAYOUT_METHOD_SUCCESS:
            return {
                ...state,
                loading: false,
                beneficiaryPayoutInfo: [
                    ...state.beneficiaryPayoutInfo,
                    action.payload,
                ],
            };
        case types.UPDATE_PAYOUT_METHOD_SUCCESS: {
            const { beneficiaryPayoutInfo } = state;
            const updatedPayoutMethodIndex = beneficiaryPayoutInfo?.findIndex(
                payoutMethod =>
                    payoutMethod?._id === action.payload?.payoutMethodId
            );
            let updatedPayoutMethodInfo = [...beneficiaryPayoutInfo];
            updatedPayoutMethodInfo[updatedPayoutMethodIndex] = {
                ...action.payload?.updatedPayoutMethod,
            };
            return {
                ...state,
                loading: false,
                beneficiaryPayoutInfo: updatedPayoutMethodInfo,
            };
        }

        default:
            return state;
    }
};

export default bizPaymentReducer;
