export const urls: any = {
    "/r/dashboard/client": [],
    "/r/dashboard/finance": [],
    "/r/receiveIntroduction": [],
    "/r/faq": [],
    "/r/settings/user/changePassword": [],
    "/r/settings/user/myProfile": [],
    "/r/settings/companyProfile": [],
    "/r/settings/user/bankDetails": [],
    "/r/transactions/bookfx": ["/transactions/bookfx"],
    "/r/transactions/preCheckDocumentUpload": ["/transactions/preCheckDocumentUpload"],
    "/r/transactions/rfiresponse": ["/transactions/rfiresponse"],
    "/r/transactions/fundingentry": ["/transactions/fundingentry"],
    "/r/transactions/fundingentry/deposit": ["/transactions/fundingentry"],
    "/r/transactions/fundingentry/card": ["/transactions/bizpay"],
    "/r/transactions/fundingResponse": ["/transactions/fundingentry"],
    "/r/transactions/batchapproval": ["/transactions/batchapproval"],
    "/r/transactions/uploadtransactions": ["/transactions/uploadtransactions"],
    "/r/transactions/uploadtransactions/validationresults": ["/transactions/uploadtransactions"],
    "/r/transactions/singlepayment": ["/transactions/singlepayment"],
    "/r/transactions/paymentRequest": ["/transactions/paymentRequest"],
    "/r/transactions/virtualReceivingAccount": ["/transactions/virtualReceivingAccount"],
    "/r/transactions/bizpay": ["/transactions/bizpay"],
    "/r/transactions/cardpayments/transactionInformation": ["/transactions/bizpay"],
    "/r/transactions/cardpayments/transactionInformation/payments": ["/transactions/bizpay"],
    "/r/transactions/cardpayments/payments/paymentsReview": ["/transactions/bizpay"],
    "/r/transactions/cardpayments/payments/paymentsReview/paymentStatus": ["/transactions/bizpay"],
    "/r/transactions/addBeneficiary": ["/transactions/bizpay"],
    "/r/reports/bookfx": ["/reports/bookfx"],
    "/r/reports/dailyreport": ["/reports/dailyreport"],
    "/r/reports/uploadbatch": ["/reports/uploadbatch"],
    "/r/reports/accountstatement": ["/reports/accountstatement"],
    "/r/reports/transactionshistory": ["/reports/transactionshistory"],
    "/r/reports/trackPaymentRequestHistory": ["/reports/trackPaymentRequestHistory"],
    "/r/payin/withdrawFund": ["/payin/withdrawFundHistory"],
    "/r/payin/withdrawFundHistory": ["/payin/withdrawFundHistory"],
    "/r/payin/addPayer": ["/payin/payers"],
    "/r/transactions/addBankAccount": ["/transactions/manageBankAccounts"],
    "/r/payin/payers": ["/payin/payers"],
    "/r/transactions/manageBankAccounts": ["/transactions/manageBankAccounts"],
    "/r/holidayCaldendar": [],
}