const userInfoReducer = (
    state = {
        loading: '',
        loadingChangePwd: '',
        client_access: [],
        userProfile: [],
        receivePost: undefined,
        statusMessageSuccess: '',
        statusMessageFailure: '',
        generated_password_changed: undefined,
    },
    action = {}
) => {
    switch (action.type) {
        case 'GET_USER_CLIENTS_LIST_SUCCESS':
            return {
                ...state,
                client_access: action.payload.data.client_access,
                generated_password_changed:
                    action.payload.data.generated_password_changed,
            };
        case 'USER_PROFILE_DATA_REQUEST':
            return { ...state, loading: true };
        case 'USER_PROFILE_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                userProfile: action.payload,
                receivePost: action.payload.userSettings.postPaidEmailAlerts,
            };
        case 'USER_PROFILE_CHANGE_PASSWORD_REQUEST':
            return {
                ...state,
                loadingChangePwd: true,
                statusMessageSuccess: '',
                statusMessageFailure: '',
            };
        case 'USER_PROFILE_CHANGE_PASSWORD_SUCCESS':
            return {
                ...state,
                loadingChangePwd: false,
                statusMessageSuccess:
                    'Your password has been changed successfully!',
                statusMessageFailure: '',
            };
        case 'USER_PROFILE_CHANGE_PASSWORD_FAILURE':
            return {
                ...state,
                loadingChangePwd: false,
                statusMessageSuccess: '',
                statusMessageFailure: action.payload.message,
            };
        case 'CLEAR_CHANGE_PASSWORD_STATUS_MESSAGES':
            return {
                ...state,
                statusMessageSuccess: '',
                statusMessageFailure: '',
            };
        default:
            return state;
    }
};

export default userInfoReducer;
