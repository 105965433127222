import * as types from '../actions/types';

const accountsReducer = (state = { currentClientAccounts: [] }, action = {}) => {
    if (action.type === types.CURRENT_CLIENT_ACCOUNT_LIST_SUCCESS) {
        return { ...state, currentClientAccounts: action.payload.accounts };
    }
    return state;
};

export default accountsReducer;
