import React from 'react';
import PropTypes from 'prop-types';

import '../scss/components/_stepCounterComponent.scss';

const StepHeader = ({
    number,
    text,
    isActive,
    isCompleted,
    isSuccess,
    isError,
}) => {
    return (
        <div
            className={`flex-column mb-2 col-md-4 ${isActive ? '' : 'opacity'}`}
        >
            <p className="page-title">{number}</p>
            <p className="step-header-text uppercase">
                {text}{' '}
                {(isCompleted || isSuccess) && <div className="completed" />}
            </p>
        </div>
    );
};
StepHeader.propTypes = {
    text: PropTypes.string,
    number: PropTypes.string,
    isActive: PropTypes.bool,
    isCompleted: PropTypes.bool,
    isSuccess: PropTypes.bool,
    isError: PropTypes.bool,
};

StepHeader.defaultProps = {
    text: '',
    number: '',
    isActive: false,
};

const StepCounterComponent = ({
    step,
    parentClassName,
    text1,
    text2,
    text3,
    color,
    status,
}) => (
    <div className={`col-md-12 step-counter ${parentClassName}`}>
        <div className="row">
            <StepHeader
                number="1."
                text={text1}
                isActive={step >= 1}
                isCompleted={step > 1}
                isError={step > 1}
            />
            <StepHeader
                number="2."
                text={text2}
                isActive={step >= 2}
                isCompleted={step > 2}
                isError={step > 2}
            />
            <StepHeader
                number="3."
                text={text3}
                isActive={step >= 3}
                isCompleted={step > 3 || status === 'success'}
                isError={step > 3}
            />
        </div>
        <div className="row separator-container">
            <div
                className={`col-md-4 separator ${step >= 1 ? `step-part ${step === 1 && color}` : ''
                    }`}
            />
            <div
                className={`col-md-4 separator ${step >= 2 ? `step-part ${step === 2 && color}` : ''
                    }`}
            />
            <div
                className={`col-md-4 separator ${step === 3 ? `step-part ${step === 3 && color}` : ''
                    }`}
            />
        </div>
    </div>
);

StepCounterComponent.propTypes = {
    parentClassName: PropTypes.string,
    step: PropTypes.number,
    color: PropTypes.string,
    text1: PropTypes.string,
    text2: PropTypes.string,
    text3: PropTypes.string,
    success: PropTypes.bool,
    error: PropTypes.bool,
};

StepCounterComponent.defaultProps = {
    parentClassName: '',
    step: 1,
    color: '',
    text1: '',
    text2: '',
    text3: '',
    success: false,
    error: false,
    isCompleted: false,
    isError: false,
};

export default StepCounterComponent;
