import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import HeaderMenu from './components/HeaderMenu';
import UserMenu from './components/UserMenu/UserMenu';
import { redirectTo } from '../../../actions/common-actions';
import { toggleSidebar } from '../../../actions/sidebar-actions';
import headerLogo from '../../../assets/img/brand/nium_dashlogo.png';
import bizpayHeaderLogo from '../../../assets/img/brand/svg/bizpay_dashlogo.svg';

import './styles/Header.scss';
import { scrollToTop } from '../../Pages/Payin/utils/page';

const Header = ({ supplier_payments_customer }) => {
    const dispatch = useDispatch();
    const rootUrl = localStorage.getItem('UserRootDirectory');
    return (
        <header data-test="header" className="header flex">
            <div className="flex">
                <button
                    data-test="sidebar-toggler"
                    className="sidebar-toggler"
                    type="button"
                    onClick={() => {
                        dispatch(toggleSidebar());
                    }}
                >
                    <i className="icon-menu" />
                </button>
                <button
                    type="button"
                    data-test="header-logo-button"
                    onClick={() => {
                        dispatch(redirectTo(`/r${rootUrl}`));
                        scrollToTop();
                    }}
                >
                    <img
                        data-test="logo"
                        className={`header-logo`}
                        src={headerLogo}
                        alt="NIUM"
                    />
                </button>
            </div>
            <div className="flex">
                <HeaderMenu />
                <UserMenu />
            </div>
        </header>
    );
};

export default memo(Header);

Header.propTypes = {
    supplier_payments_customer: PropTypes.bool,
};

Header.defaultProps = {
    supplier_payments_customer: false,
};
