const uploadBatchReducer = (
    state = {
        pagination: { items: [] },
        accounts: [],
        loading: '',
        downloading: '',
        error: null,
        refetchData: false,
        detailsLoading: false,
        cancelationToken: null,
    },
    action = {}
) => {
    switch (action.type) {
        case 'UPLOAD_BATCH_DATA_REQUEST':
            return { ...state, loading: true, refetchData: false, error: null };
        case 'UPLOAD_BATCH_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.data.response,
                    total: action.payload.data.recordsFiltered,
                },
            };
        case 'UPLOAD_BATCH_DATA_FAILURE':
            return { ...state, loading: false, error: action.payload.message };
        case 'UPLOAD_BATCH_STATUS_DATA_REQUEST':
            return {
                ...state,
                detailsLoading: true,
                error: null,
                cancelationToken: action.payload,
            };
        case 'UPLOAD_BATCH_STATUS_DATA_SUCCESS':
            return {
                ...state,
                detailsLoading: false,
                detailsStore: action.payload,
                cancelationToken: null,
            };
        case 'UPLOAD_BATCH_STATUS_DATA_FAILURE':
            return {
                ...state,
                detailsLoading: false,
                cancelationToken: null,
                error: action.payload.message,
            };
        case 'CHANGE_PAGE_SIZE_REQUEST':
            return { ...state, loading: true, refetchData: true };
        case 'UPLOAD_BATCH_CSV_DOWNLOAD_REQUEST':
            return { ...state, downloading: true, error: null };
        case 'UPLOAD_BATCH_CSV_DOWNLOAD_SUCCESS':
            return { ...state, downloading: false };
        case 'UPLOAD_BATCH_CSV_DOWNLOAD_FAILURE':
            return {
                ...state,
                downloading: false,
                error: action.payload.message,
            };
        case 'UPLOAD_BATCH_FILTER_GENERATE_REQUEST':
            return { ...state, loading: true, refetchData: true };
        case 'UPLOAD_BATCH_ACCOUNTS_BY_CLIENT':
            return { ...state, accounts: action.payload.data };
        case 'UPLOAD_BATCH_SUMMARY_DOWNLOAD_REQUEST':
            return { ...state };
        case 'UPLOAD_BATCH_REPORT_DOWNLOAD_REQUEST':
            return { ...state };
        default:
            return state;
    }
};

export default uploadBatchReducer;
