import * as types from '../constants/Types';

const initialState = {
    paymentRequestList: {
        items: [],
    },
    trackPRLoading: false,
    makingPaymentRequest: false,
    disableResubmitPaymentRequest: false,
    downloading: false,
    lastSuccessfulPaymentRequest: {},
    paymentRequestFilter: [],
};

export const paymentRequestReducer = (state: any = initialState, action: any = {}): any => {
    switch (action.type) {
        case types.TRACK_PR_SUCCESS: {
            return { 
                ...state, 
                trackPRLoading: false,
                paymentRequestList: {
                    items: action.payload?.filteredRecords,
                    totalRecords: action.payload?.totalRecords,
                },
            };
        }
        
        case types.TRACK_PR_REQUEST: {
            return {
                ...state,
                trackPRLoading: true,
            };
        }

        case types.TRACK_PR_ERROR: {
            return {
                ...state,
                trackPRLoading: false,
            };
        }

        case types.TRACK_PR_CSV_DOWNLOAD_SUCCESS: {
            return { ...state, downloading: false };
        }

        case types.TRACK_PR_CSV_DOWNLOAD_ERROR: {
            return { ...state, downloading: false };
        }

        case types.TRACK_PR_CSV_DOWNLOAD_REQUEST:
            return { ...state, downloading: true };

        case types.RESET_PAYMENT_REQUEST: {
            return {
                ...state,
                disableResubmitPaymentRequest: false,
                makingPaymentRequest: false,
            };
        }

        case types.PAYIN_MAKE_PAYMENT_REQUEST: {
            return {
                ...state,
                disableResubmitPaymentRequest: true,
                makingPaymentRequest: true,
            };
        }

        case types.PAYIN_MAKE_PAYMENT_REQUEST_SUCCESSFUL: {
            return {
                ...state,
                makingPaymentRequest: false,
                lastSuccessfulPaymentRequest: action.payload,
            };
        }

        case types.PAYIN_MAKE_PAYMENT_REQUEST_ERRORED: {
            return {
                ...state,
                makingPaymentRequest: false,
            };
        }

        case types.PAYIN_PAYMENT_REQUEST_REMIND_RESPONSE: {
            const { paymentRequestId, lastRemindedAt } = action.payload;
            const { paymentRequestList } = state;
            const items = [...paymentRequestList.items];
            const paymentRequestObjectTobeUpdated = items.find(
                item => item.id === paymentRequestId
            );
            const paymentRequestObjectTobeUpdatedIndex = items.findIndex(
                item => item.paymentRequestId === paymentRequestId
            );

            paymentRequestObjectTobeUpdated.lastRemindedAt = lastRemindedAt;
            paymentRequestObjectTobeUpdated.time_elapsed = false;
            items[
                paymentRequestObjectTobeUpdatedIndex
            ] = paymentRequestObjectTobeUpdated;
            paymentRequestList.items = items;
            return {
                ...state,
                paymentRequestList,
            };
        }

        case types.MARK_PAID_SUCCESS: {
            let totalRecords = state.paymentRequestList.totalRecords;
            const newList = state.paymentRequestList.items
                .map((item: any) => {
                    if (item.id === action.payload.id) {
                        const newItem = {
                            ...item,
                            status: 'PAID',
                        };
                        if (
                            state.paymentRequestFilter.status === '' ||
                            newItem.status === state.paymentRequestFilter.status
                        ) {
                            return newItem;
                        } else {
                            totalRecords -= 1;
                            return null;
                        }
                    } else {
                        return item;
                    }
                })
                .filter((e: any) => e !== null);
            return {
                ...state,
                paymentRequestList: {
                    items: newList,
                    totalRecords,
                },
            };
        }

        case types.MARK_CANCELLED_SUCCESS: {
            let totalRecords = state.paymentRequestList.totalRecords;
            const newList = state.paymentRequestList.items
                .map((item: any) => {
                    if (item.id === action.payload.id) {
                        const newItem = {
                            ...item,
                            status: 'CANCELLED',
                        };
                        if (
                            state.paymentRequestFilter.status === '' ||
                            newItem.status === state.paymentRequestFilter.status
                        ) {
                            return newItem;
                        } else {
                            totalRecords -= 1;
                            return null;
                        }
                    } else {
                        return item;
                    }
                })
                .filter((e: any) => e !== null);
            return {
                ...state,
                paymentRequestList: {
                    items: newList,
                    totalRecords,
                },
            };
        }

        case types.SAVE_TRACK_PR_FILTER: {
            return {
                ...state,
                paymentRequestFilter: action.payload,
            };
        }

        default:
            return state;
    }
};
