const initialState = {
    loading: false,
    error: '',
    gencodeError: false,
    msg: null,
    url: '/r/',
    user_settings: {},
    isDemoUser: false,
};
export default function (state = initialState, action = {}) {
    switch (action.type) {
        case 'USER_SIGNUP_LOADING':
            return { ...state, loading: true, msg: null, error: null };
        case 'USER_SIGNUP_SUCCESS':
            return { ...state, loading: false };
        case 'USER_SIGNUP_FAILURE':
        case 'AUTH_ERROR':
            return {
                ...state,
                loading: false,
                error: action.payload.message || '* Unexpected error ocurred',
            };
        case 'USER_LOGIN_LOADING':
            return { ...state, loading: true, msg: null };
        case 'USER_LOGIN_SUCCESS':
            return {
                ...state,
                loading: false,
                isDemoUser: action.payload.data.status === 2,
            };
        case 'USER_LOGIN_AUTH':
            return {
                ...state,
                loading: false,
                user_settings: action.payload.user_settings,
                url: action.payload.generated_password_changed
                    ? `/r${action.payload.url}`
                    : '/r/settings/user/changePassword',
            };
        case 'AUTH_GENCODE_ERROR':
            return {
                ...state,
                loading: false,
                gencodeError: true,
                error:
                    '* Authentication error! Please check your dynamic access code and try again.',
            };
        case 'HIDE_MESSAGES':
            return { ...state, error: null, gencodeError: false, msg: null };
        case 'USER_FORGOT_PASSWORD_SUCCESS':
            return {
                ...state,
                loading: false,
                msg:
                    '* Password reset link will be sent to you in a few moments.',
            };
        case 'USER_RESET_PASSWORD_SUCCESS':
            return {
                ...state,
                loading: false,
                msg: '* Your password has been changed successfully!',
            };
        case 'RESET_ERROR':
            return { ...state, loading: false, error: action.payload.message };
        case 'AUTH_ERROR_FORGOT':
            return {
                ...state,
                loading: false,
                error:
                    '* This email does not exist in our system. Contact our support.',
            };
        default:
            return state;
    }
}
