const bookFxReducer = (
    state = {
        pagination: { items: [] },
        loading: false,
        error: null,
        refetchData: false,
        accountsList: [],
        loadingDataTable: false,
        loadingAmounts: false,
    },
    action = {}
) => {
    switch (action.type) {
        case 'REFETCH_DATA':
            return { ...state, refetchData: true };
        case 'BOOK_FX_ACCOUNTS_LIST_REQUEST':
            return { ...state, gettingAccounts: true, error: null };
        case 'BOOK_FX_ACCOUNTS_LIST_REQUEST_SUCCESS':
            return {
                ...state,
                accountsList: action.payload,
                refetchData: false,
                gettingAccounts: false,
            };
        case 'BOOK_FX_ACCOUNTS_LIST_REQUEST_FAILURE':
            return {
                ...state,
                error: action.payload.message,
                refetchData: false,
                gettingAccounts: false,
            };
        case 'BOOK_FX_CALCULATE_AMOUNTS_REQUEST':
            return { ...state, loadingAmounts: true, error: null };
        case 'BOOK_FX_CALCULATE_AMOUNTS_SUCCESS':
            return {
                ...state,
                calculatedAmounts: action.payload,
                loadingAmounts: false,
            };
        case 'BOOK_FX_CALCULATE_AMOUNTS_FAILURE':
            return {
                ...state,
                error: action.payload.message,
                loadingAmounts: false,
            };
        case 'CURRENCY_EXCHANGE_HISTORY_REQUEST':
            return {
                ...state,
                loading: true,
                loadingDataTable: true,
                refetchData: false,
                error: null,
            };
        case 'CURRENCY_EXCHANGE_HISTORY_SUCCESS':
            return {
                ...state,
                loading: false,
                loadingDataTable: false,
                pagination: {
                    items: action.payload.data,
                    total: action.payload.recordsFiltered,
                },
            };
        case 'CURRENCY_EXCHANGE_HISTORY_FAILURE':
            return {
                ...state,
                loading: false,
                loadingDataTable: false,
                refetchData: false,
                error: action.payload.message,
            };
        default:
            return state;
    }
};

export default bookFxReducer;
