import React, { FC } from 'react';
import '../scss/components/_card.scss';

type buttonClickEvent = (event: React.MouseEvent<HTMLInputElement>) => void;

interface ICard {
    parentClassName?: string;
    children?: React.ReactElement | string;
    isActive?: boolean;
    onClick?: buttonClickEvent;
}

const Card: FC<ICard> = ({
    parentClassName = '',
    isActive = false,
    children,
    onClick,
}) => (
    <div
        className={`card-container ${parentClassName} ${
            isActive ? 'active' : ''
        }`}
        onClick={onClick}
    >
        {children}
    </div>
);

export default Card;
