import axios from 'axios';
import { Store } from 'redux';

import { logout } from '../actions/user-actions';
import { apiRoutes } from '../constants/apiRoutes';
import { CustomError } from '../services/customError';
import { Logger } from '../services/logger';
import apm from './apm';

const FILE_NAMESPACE = 'axiosInstance';

let axiosCancelToken = axios.CancelToken.source();

export const cancelAxiosRequest = (): void => {
    axiosCancelToken.cancel();
    axiosCancelToken = axios.CancelToken.source();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const traceLogging = (error: any): void => {
    // except for card details we can capture all
    const ignoreUrls = [
        apiRoutes.cardPayment,
        apiRoutes.saveCard,
        apiRoutes.chargeOnCard,
        apiRoutes.login,
        apiRoutes.resetPassword,
        apiRoutes.generateOtp,
        apiRoutes.requestPassword,
        apiRoutes.setPassword,
    ];

    Logger(FILE_NAMESPACE).error(
        `TraceLogging from axios ${error?.response?.config?.url}`,
        error,
        {
            requestUrl: error?.response?.config?.url,
            requestBody: ignoreUrls.includes(error?.response?.config?.url)
                ? 'truncated body'
                : JSON.stringify(error?.response?.config?.data),
            requestParams: JSON.stringify(error?.response?.config?.params),
            response: JSON.stringify(error?.response?.data),
        }
    );
    apm.setCustomContext({
        requestUrl: error?.response?.config?.url,
        requestBody: ignoreUrls.includes(error?.response?.config?.url)
            ? 'truncated body'
            : JSON.stringify(error?.response?.config?.data),
        requestParams: JSON.stringify(error?.response?.config?.params),
        response: JSON.stringify(error?.response?.data),
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const axiosInstance = (store: Store<any, any>): void => {
    // apply csrf token from sessionStorage to each axios request headers
    axios.interceptors.request.use(config => {
        if (config.url?.includes('https://crm.zoho.com')) {
            return config;
        }

        if (!config.headers) {
            config.headers = {};
        }
        if (localStorage.getItem('csrfToken'))
            config.headers.csrfToken = localStorage.getItem('csrfToken');
        config.headers['X-Requested-With'] = 'XMLHttpRequest';
        config.headers.SOURCE = 'masspay';
        config.cancelToken = axiosCancelToken.token;
        return config;
    });

    axios.interceptors.response.use(
        response => {
            // do something with response data
            return response;
        },
        async error => {
            // Do something with error response
            if (error?.response?.data?.name === 'csrf_token_mismatch') {
                Logger(FILE_NAMESPACE).error('csrf_token_mismatch:', error);
                // cancel all ongoing requests
                cancelAxiosRequest();
                store.dispatch(logout());
            }

            if (error?.response?.data?.name === 'concurrent_login') {
                Logger(FILE_NAMESPACE).error('concurrent_login:', error);
                // cancel all ongoing requests
                cancelAxiosRequest();
                store.dispatch(logout());
            }

            if (
                error?.response?.config?.url !== apiRoutes.logout &&
                error?.response?.data?.name === 'user_not_logged_in'
            ) {
                Logger(FILE_NAMESPACE).error('user_not_logged_in:', error);
                // cancel all ongoing requests
                cancelAxiosRequest();
                store.dispatch(logout());
            }

            if (error.response?.data?.name === 'concurrent_login') {
                // store.dispatch(concurrentlogin());
                // history.push('/concurrentlogin');
            }

            // case: Network Failure
            if (!error.response && !axios.isCancel(error)) {
                Logger(FILE_NAMESPACE).error('Unknown Axios Error', error);
                apm.captureError(
                    new CustomError('Unknown Axios Error', error, {
                        source: 'axiosInstance',
                    })
                );
            } else {
                traceLogging(error);
            }

            throw error;
        }
    );
};
