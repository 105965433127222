import { Action } from '../../../../actions/reduxInterface';
import {
    ADD_BANK_SERVER_ERROR,
    ADD_BANK_SUCCESS,
} from '../actions/bankAccount-action';
import { IBankListDetails } from './IBankList';
import * as types from '../constants/Types';
export interface IBankReducer {
    bankListDetails: IBankListDetails[];
    isLoading: boolean;
    defaultAccountDetails: any;
    initial?: boolean;
    serverErrors: any;
}
const initialState: IBankReducer = {
    bankListDetails: [],
    isLoading: false,
    initial: true,
    defaultAccountDetails: {},
    serverErrors: {},
};

export const BankAccountReducer = (
    state: IBankReducer = initialState,
    action: Action = { type: '' }
) => {
    switch (action.type) {
        case types.GET_BANK_ACCOUNT_REQUEST: {
            return { ...state, isLoading: true };
        }

        case types.GET_BANK_ACCOUNT_RESPONSE: {
            const { bankListDetails } = action.payload;
            return {
                ...state,
                bankListDetails,
                isLoading: false,
                initial: false
            };
        }

        case types.DEFAULT_BANK_ACCOUNT_REQUEST: {
            const { defaultAccountDetails } = action.payload;

            return {
                ...state,
                defaultAccountDetails,
            };
        }
        case ADD_BANK_SERVER_ERROR: {
            return {
                ...state,
                isLoading: false,
                serverErrors: action.payload,
            };
        }
        case ADD_BANK_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                serverErrors: {},
            };
        }

        default:
            return state;
    }
};
