export class CustomError extends Error {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [a: string]: any;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(message: string, error: Error, dump?: object) {
        super(message);
        this.manuallyThrown = true;
        this.stack = error?.stack;
        this.name = 'CustomError';
        if (dump) {
            // remove overlapping content betweeen custom error and dump
            const cloneDump = JSON.parse(JSON.stringify(dump));
            delete cloneDump.manuallyThrown;
            delete cloneDump.stack;

            // retaining the original value with dump as prefix in key
            cloneDump.dumpMessage = cloneDump.message;
            delete cloneDump.message;

            // retaining the original value with dump as prefix in key
            cloneDump.dumpName = cloneDump.name;
            delete cloneDump.name;

            Object.assign(this, cloneDump);
        }
    }
}
