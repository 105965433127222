import React, { memo } from 'react';
import { goBack } from 'connected-react-router';
import { connect } from 'react-redux';
import Button from '../../components/Button';
/**
 * ErrorFallback component to be rendered by Error Boundary when some error occurs in the application
 * @param props
 */
// TODO: buttons' dependency can further be removed through anchor/button and css
const ErrorFallback = (props: any) => {
    const setPage = () => {
        props.goBack();
        setTimeout(() => {
            window.location.reload();
        }, 100);
    };

    const goToDashboard = () => {
        const dashboardUrl = localStorage.getItem('UserRootDirectory');
        window.location.href = `/r${dashboardUrl}`;
    };
    return (
        <div className="animated fadeIn errorBoundary">
            <div className="container center">
                <div className="flex flex-column">
                    <p className="title">OOPS!</p>
                    <p className="description">
                        Something went wrong. The page you are looking for is
                        temporarily unavailable.
                    </p>
                    <div className="margin-t-80px flex flex-row">
                        <Button
                            size="xlg"
                            color="light"
                            parentClassName="padding-r-15px"
                            onClick={() => setPage()}
                        >
                            GO TO PREVIOUS PAGE
                        </Button>
                        <Button
                            size="xlg"
                            color="dark"
                            parentClassName="padding-l-15px"
                            onClick={() => goToDashboard()}
                        >
                            GO TO DASHBOARD
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default memo(connect(null, {
    goBack
})(ErrorFallback));
