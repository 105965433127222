import { IVrasTransaction } from './IVrasTransaction';

export enum ComplianceStatus {
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    DRAFT = 'DRAFT',
    RECEIVED = 'RECEIVED',
}

export enum VRASTFilterEnum {
    ALL = 'ALL',
    APPROVED = 'APPROVED',
    'IN-PROCESS' = 'IN-PROCESS',
    REJECTED = 'REJECTED',
    REFUNDED = 'REFUNDED',
}

export type VRASTCountByStatus = {
    [key in VRASTFilterEnum]: number;
};
export type setVRASFilter = (tab: VRASTFilterEnum) => void;
export interface IVRAS_FilterButton {
    activeTab: VRASTFilterEnum;
    setVRASFilter: setVRASFilter;
    transactionCountByStatus: VRASTCountByStatus;
}

export interface IVrasData {
    currencyCode: string;
    payerId: string;
    payerName: string;
    transactionCountByStatus: VRASTCountByStatus;
    transactions: IVrasTransaction[];
    virtualAccountNumber: string;
    virtualReceivingAccountId: string;
}
