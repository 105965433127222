import apm from '../bootstrap/apm';
import {
    ServicesCleverTapProfile,
    CleverTapObject,
} from '../declarations/Global';
import { CustomError } from './customError';
import { Logger } from './logger';

const CleverTap = window.clevertap;
const FILE_NAMESPACE = 'Services.CleverTap';

export const recordCleverTapEvent = (
    name: string,
    data?: CleverTapObject
): void => {
    try {
        Logger(FILE_NAMESPACE).silly('recordCleverTapEvent', { name, data });
        CleverTap.event.push(name, {
            ...(data || {
                url: window.location.href,
            }),
        });
    } catch (error) {
        Logger(FILE_NAMESPACE).error('recordCleverTapEvent', error);
        apm.captureError(
            new CustomError('CleverTapError', error, {
                source: 'recordCleverTapEvent',
            })
        );
    }
};

export const recordCleverTapProfile = (
    profile: ServicesCleverTapProfile
): void => {
    try {
        Logger(FILE_NAMESPACE).silly('recordCleverTapProfile', { profile });
        CleverTap.onUserLogin.push({
            Site: profile,
        });
    } catch (error) {
        Logger(FILE_NAMESPACE).error('recordCleverTapProfile', error);
        apm.captureError(
            new CustomError('CleverTapError', error, {
                source: 'recordCleverTapProfile',
            })
        );
    }
};

export const beautifyComponentError = (error: any, info: any) => ({
    message: error.message,
    info: JSON.stringify(info),
    errorName: error.name,
    stack: error.stack,
});
