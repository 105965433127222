import { push } from 'connected-react-router';
import { DispatchType } from './reduxInterface';
import {
    CLEAR_ERROR,
    ERROR,
    REFETCH_DATA,
    CHANGE_PAGE_SIZE_REQUEST,
    REDIRECT_TO,
    SHOW_MODAL,
    HIDE_MODAL,
    SHOW_TOAST,
    HIDE_TOAST,
    SHOW_POPUP,
    HIDE_POPUP,
} from './types';

export function showError(message: string, type: any = null) {
    return function(dispatch: DispatchType) {
        dispatch({
            type: ERROR,
            payload: { message, type },
        });
    };
}
export function clearError() {
    return function(dispatch: DispatchType) {
        dispatch({
            type: CLEAR_ERROR,
        });
    };
}
export function refetchDataAction() {
    return function(dispatch: DispatchType) {
        dispatch({
            type: REFETCH_DATA,
        });
    };
}
export function changePageSize() {
    return function(dispatch: DispatchType) {
        dispatch({
            type: CHANGE_PAGE_SIZE_REQUEST,
        });
    };
}
export function redirectTo(url: string) {
    return function(dispatch: DispatchType) {
        dispatch({
            type: REDIRECT_TO,
        });
        dispatch(push(url));
    };
}
export const showModal = (data: any) => {
    return {
        type: SHOW_MODAL,
        data,
    };
};

export const hideModal = (modalIdentifier: any) => {
    return {
        type: HIDE_MODAL,
        modalIdentifier,
    };
};
interface IshowToast {
    title: string;
    type: string;
    toastIdentifier: string;
}
export const showToast = ({ title, type, toastIdentifier }: IshowToast) => {
    return {
        type: SHOW_TOAST,
        data: {
            title,
            type,
            toastIdentifier,
        },
    };
};

export const hideToast = (toastIdentifier: any) => {
    return {
        type: HIDE_TOAST,
        toastIdentifier,
    };
};

export const showPopup = (data: any) => {
    return {
        type: SHOW_POPUP,
        data,
    };
};

export const hidePopup = (popupIdentifier: any) => {
    return {
        type: HIDE_POPUP,
        popupIdentifier,
    };
};
