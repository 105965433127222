const fxHistoryReducer = (
    state = {
        pagination: { items: [] },
        loading: '',
        downloading: '',
        refetchData: false,
    },
    action = {}
) => {
    switch (action.type) {
        case 'FX_HISTORY_DATA_REQUEST':
            return { ...state, loading: true, refetchData: false };
        case 'FX_HISTORY_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.data.data,
                    total: action.payload.data.recordsTotal,
                    filtered: action.payload.data.recordsFiltered,
                },
            };
        case 'FX_HISTORY_DATA_FAILURE':
            return { ...state, loading: false };
        case 'FX_HISTORY_CSV_DOWNLOAD_REQUEST':
            return { ...state, downloading: true };
        case 'FX_HISTORY_CSV_DOWNLOAD_SUCCESS':
            return { ...state, downloading: false };
        case 'FX_HISTORY_CSV_DOWNLOAD_FAILURE':
            return { ...state, downloading: false };
        case 'CHANGE_PAGE_SIZE_REQUEST':
            return { ...state, loading: true, refetchData: true };
        default:
            return state;
    }
};

export default fxHistoryReducer;
