import * as types from '../../actions/types';

const initialState = {
    statusLoading: false,
    dataLoading: false,
    transactionSuccess: false,
    cardFundResponseObj: null,
    cardFundResponseObjNo3D: null,
    statusData: null,
    transactionData: null,
    loading: false,
    error: '',
    validBinNumbers: '',
    getAllSavedCardsLoading: false,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.GET_SAVED_CARD:
            return { ...state, statusLoading: true, statusError: null };
        case types.GET_SAVED_CARD_SUCCESS:
            return { ...state, statusLoading: false, savedCards: action.payload };
        case types.GET_SAVED_CARD_FAILURE:
            return { ...state, statusLoading: false, savedCards: false };
        case types.GET_ALL_SAVED_CARDS:
            return { ...state, getAllSavedCardsLoading: true, statusError: null };
        case types.GET_ALL_SAVED_CARDS_SUCCESS:
            return { ...state, getAllSavedCardsLoading: false, allSavedCards: action.payload };
        case types.GET_ALL_SAVED_CARDS_FAILURE:
            return { ...state, getAllSavedCardsLoading: false, allSavedCards: false };
        case types.GET_TRANSACTION_STATUS:
            return { ...state, statusLoading: true, statusError: null };
        case types.GET_VALID_BIN_NUMBERS_SUCCESS:
            return { ...state, validBinNumbers: action.payload };
        case types.GET_TRANSACTION_STATUS_SUCCESS:
            return {
                ...state,
                statusLoading: false,
                transactionSuccess: action.payload.status === 'APPROVED',
                statusData: action.payload,
            };
        case types.GET_TRANSACTION_STATUS_FAILURE:
            return {
                ...state,
                statusLoading: false,
                transactionSuccess: false,
            };
        case types.GET_TRANSACTION_DATA_REQUEST:
            return { ...state, dataLoading: true };
        case types.GET_TRANSACTION_DATA_SUCCESS:
            return {
                ...state,
                dataLoading: false,
                transactionData: action.payload,
            };
        case types.GET_TRANSACTION_DATA_FAILURE:
            return { ...state, dataLoading: false };
        case types.FUND_ACCOUNT_BY_CARD_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
                cardFundResponseObj: null,
            };
        case types.FUND_ACCOUNT_BY_CARD_SUCCESS:
            return {
                ...state,
                loading: false,
                cardFundResponseObj: action.payload,
            };
        case types.FUND_ACCOUNT_BY_CARD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                cardFundResponseObj: null,
            };
        case types.CLEAR_ERROR:
            return { ...state, error: '' };
        default:
            return state;
    }
}
