import { LOCATION_CHANGE } from 'react-router-redux';
import { CLEAR_ERROR, ERROR } from '../actions/types';

const initialState = null;

const errorReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case ERROR:
            return {
                message: action.payload && action.payload.message,
                type: (action.payload && action.payload.type) || action.type,
                context: action?.payload?.context,
            };
        case CLEAR_ERROR: {
            return null;
        }
        case LOCATION_CHANGE: {
            return null;
        }
        default:
            return state;
    }
};

export default errorReducer;
