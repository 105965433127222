import { createSlice, createSelector } from '@reduxjs/toolkit';
import { splitCamelCaseWords } from '../../../../utils/payinUtils';
const initialState: any = {
    activeModals: [],
    activePopups: [],
    activeToasts: [],
    paginate: {},
};
type infoId = 'ModalId' | 'PopupId' | 'ToastId';
/**
 *
 * @param infoArray either of activeModals, activePopups or activeToasts
 * @param keyName type infoId
 * @param action redux action
 */
const modifyData = (infoArray: any[], keyName: infoId, action: any) => {
    let modified = false;
    infoArray.forEach((infoItem, index) => {
        if (infoItem[keyName] === action.payload[keyName]) {
            infoArray[index] = action.payload;
            modified = true;
        }
    });
    if (!modified) {
        infoArray.push(action.payload);
    }
};
type infoType = 'Modal' | 'Popup' | 'Toast';
/**
 * following function is currently used to create mapStateToProps for Modal, Toast and Popup,
 * so this function will be called after the redux store is updated, and before component is to be updated
 *
 * @param {string} type can be one of the following ('modal', 'popup', 'toast')
 */
export const modalStatePropsMapper = (type: infoType) => (
    state: any,
    ownProp: any
) => {
    let data = undefined;
    state.common[`active${type}s`].forEach((infoItem: any) => {
        if (infoItem[`${type}Id`] === ownProp[`${type}Id`]) {
            data = infoItem;
        }
    });
    return {
        open: data !== undefined,
        [`${type}Data`]: data,
    };
};
export type infoDataType = {
    shouldShow: boolean;
    PopupData?: any;
    ModalData?: any;
    ToastData?: any;
};
export declare type getInfoDataType = (id: any) => infoDataType;
/**
 * id must end with either Modal, Popup or Toast
 * @param id identifier associated with either modal, toast or popup
 */
export const selectInfo = (id: string): getInfoDataType => {
    const type = splitCamelCaseWords(id).pop();
    return createSelector(
        (state: any) => {
            return state.info;
        },
        (infoItems: any) => {
            let data = undefined;
            infoItems[`active${type}s`].forEach((infoItem: any) => {
                if (infoItem[`${type}Id`] === id) {
                    data = infoItem;
                }
            });
            return {
                shouldShow: data !== undefined,
                [`${type}Data`]: data,
            };
        }
    );
};

const slice = createSlice({
    name: 'info',
    initialState,
    reducers: {
        showModal(state, action) {
            modifyData(state.activeModals, 'ModalId', action);
        },
        hideModal(state, action) {
            state.activeModals = state.activeModals.filter(
                (modal: any) => modal.modalId !== action.payload.modalId
            );
        },
        showPopup(state, action) {
            modifyData(state.activePopups, 'PopupId', action);
        },
        hidePopup(state, action) {
            state.activePopups = state.activePopups.filter(
                (popup: any) => popup.popupId !== action.payload.popupId
            );
        },
        showToast(state, action) {
            modifyData(state.activeToasts, 'ToastId', action);
        },
        hideToast(state, action) {
            state.activeToasts = state.activeToasts.filter(
                (toast: any) => toast.toastId !== action.payload.toastId
            );
        },
        setCurrentPage(state, action) {
            state.paginate[action.payload.paginationName].currentPage =
                action.payload.currentPage;
        },
        setPerPage(state, action) {
            state.paginate[action.payload.paginationName].perPage =
                action.payload.perPage;
            /** for perPage change reset the current page to 1 */
            state.paginate[action.payload.paginationName].currentPage = 1;
        },
        initiatePagination(state, action) {
            if (!state.paginate[action.payload])
                state.paginate[action.payload] = {
                    currentPage: 1,
                    perPage: 10,
                    paginationName: action.payload,
                };
        },
        disbursePagination(state, action) {
            delete state.paginate[action.payload];
        },
    },
});

export const { reducer: info, actions } = slice;
export const {
    showModal,
    hideModal,
    showPopup,
    hidePopup,
    showToast,
    hideToast,
    initiatePagination,
    disbursePagination,
    setCurrentPage,
    setPerPage,
} = actions;

export const selectPagination = (paginationName: string) => ({ info: { paginate } }: any) => {
    return paginate?.[paginationName];
};
