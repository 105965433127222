import axios, { AxiosError } from 'axios';
import { DispatchType } from './reduxInterface';
import { COMMON_CONSTANTS, ERROR, COUNTRIES } from './types';

export function getCommonConstants() {
    return async function(dispatch: DispatchType) {
        try {
            const res = await axios.get('/common/constants');
            dispatch({
                type: COMMON_CONSTANTS,
                payload: res.data,
            });
        } catch (err) {
            dispatch(dispatchError(err));
        }
    };
}

export function getCountries() {
    return async function(dispatch: DispatchType) {
        try {
            const res = await axios.get(
                '/common/constants/countryCodesAndFullNamesMap'
            );
            dispatch({ type: COUNTRIES, payload: res.data });
        } catch (err) {
            dispatch(dispatchError(err));
        }
    };
}

export const dispatchError = (err: AxiosError | any, type?: string) => {
    return {
        type: type ?? ERROR,
        payload: {
            message: err?.response?.data?.message ?? '',
            context: `${err?.response?.status}: ${err?.request?.statusText}\n${err?.response?.config.url}`,
        },
    };
};

export const dispatchCancelError = (message: string, type?: string) => {
    return {
        type: type ?? ERROR,
        payload: {
            message: `${ERROR} ${message}`,
        },
    };
};
