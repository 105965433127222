import {
    CLIENT_SETTINGS_ACTION,
} from '../../actions/types';

const initialState = {
    users: {
        items: [],
        total: 0
    },
    userRoles: [],
    loading: false,
    refetchData: false,
    error: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case CLIENT_SETTINGS_ACTION:
            delete action.type;
            return { ...state, ...(action || {}) };
        default:
            return state;
    }
}
