import React, { Component, ErrorInfo } from 'react';

import apm from '../../bootstrap/apm';
import {
    beautifyComponentError,
    recordCleverTapEvent,
} from '../../services/cleverTap';
import { CustomError } from '../../services/customError';
import { Logger } from '../../services/logger';
import ErrorFallback from './ErrorFallback';

const FILE_NAMESPACE = 'ErrorBoundry';
export class ErrorBoundary extends Component<
    {},
    {
        hasError: boolean;
    }
> {
    constructor(props: {}) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError(): { hasError: boolean } {
        return { hasError: true };
    }

    componentDidCatch(error: Error, info: ErrorInfo): void {
        Logger(FILE_NAMESPACE).error(error.name, info);
        apm.captureError(
            new CustomError(`ErrorBoundary: ${error.name}`, error, {
                ...info,
                source: 'componentDidCatch',
            })
        );
        recordCleverTapEvent(
            'componentError',
            beautifyComponentError(error, info)
        );
        if (error.name === 'ChunkLoadError') {
            // Redirect to home page to reload main chunk first
            window.location.href = '/';
        }
    }

    render(): React.ReactNode {
        const rightUrl = window.location.href === '/r/oops';
        if (this.state.hasError || rightUrl) {
            return <ErrorFallback />;
        }
        return this.props.children;
    }
}
