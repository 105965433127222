import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { USER_LOGOUT } from '../actions/types';
import accountsReducer from '../reducers/accounts-reducer';
import accountSummaryReducer from '../reducers/accountSummary-reducer';
import clientDashboardReducer from '../reducers/clientDashboard-reducer';
import commonReducer from '../reducers/common-reducer';
import companyProfileReducer from '../reducers/companyProfile-reducer';
import constantsReducer from '../reducers/constants-reducer';
import dashboardReducer from '../reducers/dashboard-reducer';
import errorReducer from '../reducers/error-reducer';
import headerReducer from '../reducers/header-reducer';
import accountStatementReducer from '../reducers/page-reducers/accountStatement-reducer';
import bankDetailsReducer from '../reducers/page-reducers/bankDetails-reducer';
import bizPaymentReducer from '../reducers/page-reducers/bizPayment-reducer';
import bookFxReducer from '../reducers/page-reducers/bookFx-reducer';
import fundByCardReducer from '../reducers/page-reducers/fundByCard-reducer';
import fxHistoryReducer from '../reducers/page-reducers/fxHistory-reducer';
import holidayCalendarReducer from '../reducers/page-reducers/holidayCalendar-reducer';
import onfidoIdentificationReducer from '../reducers/page-reducers/onfidoIdentification-reducer';
import paymentApprovalReducer from '../reducers/page-reducers/paymentApproval-reducer';
import preCheckDocumentsReducer from '../reducers/page-reducers/preCheckDocuments-reducer';
import rfiResponseReducer from '../reducers/page-reducers/RfiResponse-reducer';
import sendFundsReducer from '../reducers/page-reducers/sendFunds-reducer';
import singlePaymentReducer from '../reducers/page-reducers/singlePayment-reducer';
import transactionHistoryReducer from '../reducers/page-reducers/transactionHistory-reducer';
import uploadBatchReducer from '../reducers/page-reducers/uploadBatch-reducer';
import uploadTransactionsReducer from '../reducers/page-reducers/uploadTransactions-reducer';
import sidebarReducer from '../reducers/sidebar-reducer';
import userReducer from '../reducers/user-reducer';
import userInfoReducer from '../reducers/userInfo-reducer';
import payinAccountReducer from '../views/Pages/Payin/reducers/account-reducer';
import clientSettingsReducer from '../reducers/page-reducers/clientSettings-reducer';
import { BankAccountReducer } from '../views/Pages/Payin/reducers/bankAccount-reducer';
import { info } from '../views/Pages/Payin/reducers/infoSlice';
import payinPayerReducer from '../views/Pages/Payin/reducers/payer-reducer';
import { payinReducer } from '../views/Pages/Payin/reducers/payin-reducer';
import { paymentRequestReducer } from '../views/Pages/Payin/reducers/paymentRequest-reducer';
import { virtualReceivingAccountReducer } from '../views/Pages/Payin/reducers/virtualReceivingAccount-reducer';
import withdrawFundReducer from '../views/Pages/Payin/reducers/withdrawfund-reducer';
import { persistConfig, localForage } from './persistConfig';

const reducerConfig = {
    info,
    user: userReducer,
    form: formReducer,
    error: errorReducer,
    header: headerReducer,
    sidebar: sidebarReducer,
    userInfo: userInfoReducer,
    accounts: accountsReducer,
    constants: constantsReducer,
    dashboard: dashboardReducer,
    companyProfile: companyProfileReducer,
    clientDashboard: clientDashboardReducer,
    common: commonReducer,

    // Page reducers
    bookFx: bookFxReducer,
    fxHistory: fxHistoryReducer,
    sendFunds: sendFundsReducer,
    fundByCard: fundByCardReducer,
    bankDetails: bankDetailsReducer,
    uploadBatch: uploadBatchReducer,
    singlePayment: singlePaymentReducer,
    accountSummary: accountSummaryReducer,
    paymentApproval: paymentApprovalReducer,
    accountStatement: accountStatementReducer,
    preCheckDocuments: preCheckDocumentsReducer,
    transactionHistory: transactionHistoryReducer,
    uploadTransactions: uploadTransactionsReducer,
    onfidoIdentification: onfidoIdentificationReducer,
    rfiResponse: rfiResponseReducer,
    payin: payinReducer,
    payinWithdrawFund: withdrawFundReducer,
    payinAccount: payinAccountReducer,
    payinPayers: payinPayerReducer,
    paymentRequest: paymentRequestReducer,
    virtualReceivingAccount: virtualReceivingAccountReducer,
    // manage bank account reducer
    bankAccounts: BankAccountReducer,
    bizPaymentReducer,
    holidayCalendar: holidayCalendarReducer,
    clientSettings: clientSettingsReducer
};

const history = createBrowserHistory();

const rootReducer = combineReducers({
    router: connectRouter(history),
    ...reducerConfig,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducers = (state: any, action: any): any => {
    if (action.type === USER_LOGOUT) {
        localForage.removeItem(`persist:${persistConfig.key}`);
        state = undefined;
    }
    return rootReducer(state, action);
};

export { reducers, history };
