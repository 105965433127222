const initialState = {
    userName: '',
    data: '',
    sidebarStatus: '',
    showSidebar: false,
    showCompanyProfile: false,
};

const sidebarReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case 'GET_SIDEBAR_DATA':
            return {
                ...state,
                data: action.payload.data,
                showCompanyProfile: action.payload.showCompanyProfile,
                userName: action.payload.data.user_profile.name,
            };
        case 'SESSIONID_EXPIRED':
            return {
                ...state,
                showSidebar: false,
                sidebarStatus: 'SESSIONID_EXPIRED',
            };
        case 'OPEN_SIDEBAR':
            return { ...state, showSidebar: true };
        case 'CLOSE_SIDEBAR':
            return { ...state, showSidebar: false };
        case 'TOGGLE_SIDEBAR':
            return { ...state, showSidebar: !state.showSidebar };
        default:
            return state;
    }
};

export default sidebarReducer;
