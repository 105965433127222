import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';

import { persistConfig } from './persistConfig';
import { reducers, history } from './reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const storeConfig = () => {
    const persistedReducer = persistReducer(persistConfig, reducers);

    const store = createStore(
        persistedReducer,
        composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
    );

    const persistor = persistStore(store);
    return { store, persistor };
};

export { storeConfig, history };
