export const PAYIN_BUYER_CREATE = 'PAYIN_BUYER_CREATE';
export const CREATE_PAYER_SUCCESS = 'CREATE_PAYER_SUCCESS';
export const PAYIN_PAYER_TYPE_INDIVIDUAL = 'INDIVIDUAL';
export const PAYIN_PAYER_TYPE_COMPANY = 'COMPANY';

export const CREATE_PAYER_FAILURE = 'CREATE_PAYER_FAILURE';
export const PAYIN_ALL_PAYERS_FETCHED = 'PAYIN_ALL_PAYERS_FETCHED';
export const PAYIN_CURRENCY_RECEIVE = 'PAYIN_CURRENCY_RECEIVE';
export const PAYIN_CLIENT_CURRENCY_FETCHED = 'PAYIN_CLIENT_CURRENCY_FETCHED';

export const PAYIN_COUNTRY_RECEIVE = 'PAYIN_COUNTRY_RECEIVE';
export const CREATE_PAYER_REQUEST = 'CREATE_PAYER_REQUEST';

export const RESET_PAYMENT_REQUEST = 'RESET_PAYMENT_REQUEST';
export const PAYIN_MAKE_PAYMENT_REQUEST = 'PAYIN_MAKE_PAYMENT_REQUEST';
export const PAYIN_MAKE_PAYMENT_REQUEST_SUCCESSFUL =
    'PAYIN_MAKE_PAYMENT_REQUEST_SUCCESSFUL';
export const PAYIN_MAKE_PAYMENT_REQUEST_ERRORED =
    'PAYIN_MAKE_PAYMENT_REQUEST_ERRORED';

export const PAYIN_GET_VIRTUAL_ACCOUNTS = 'PAYIN_GET_VIRTUAL_ACCOUNTS';
export const PAYIN_GET_VIRTUAL_ACCOUNTS_REQUEST =
    'PAYIN_GET_VIRTUAL_ACCOUNTS_REQUEST';
export const PAYIN_GET_VIRTUAL_ACCOUNTS_FAILURE =
    'PAYIN_GET_VIRTUAL_ACCOUNTS_FAILURE';
export const PAYIN_GET_VIRTUAL_ACCOUNTS_SUCCESS =
    'PAYIN_GET_VIRTUAL_ACCOUNTS_SUCCESS';
export const PAYIN_RECEIVE_CURRENCIES_REQUEST = 'PAYIN_RECEIVE_CURRENCIES_REQUEST';
export const PAYIN_RECEIVE_CURRENCIES_SUCCESS = 'PAYIN_RECEIVE_CURRENCIES_SUCCESS';
export const PAYIN_RECEIVE_CURRENCIES_FAILURE = 'PAYIN_RECEIVE_CURRENCIES_FAILURE';

export const BANK_DETAIL_MODAL = 'BANK_DETAIL_MODAL';
export const BENEFICIARY_BANK_DETAIL = 'BENEFICIARY_BANK_DETAIL';
export const PAYIN_TRACK_PR_REPORT = 'PAYIN_TRACK_PR_REPORT';
/* PR= PAYMENT_REQUEST */
export const TRACK_PR_REQUEST = 'TRACK_PR_REQUEST';
export const TRACK_PR_SUCCESS = 'TRACK_PR_SUCCESS';
export const TRACK_PR_ERROR = 'TRACK_PR_ERROR';
export const TRACK_PR_CSV_DOWNLOAD_SUCCESS = 'TRACK_PR_CSV_DOWNLOAD_SUCCESS';
export const TRACK_PR_CSV_DOWNLOAD_REQUEST = 'TRACK_PR_CSV_DOWNLOAD_REQUEST';
export const TRACK_PR_CSV_DOWNLOAD_ERROR = 'TRACK_PR_CSV_DOWNLOAD_ERROR';
export const PAYMENT_REQUEST_SET_CURRENT_PAYER =
    'PAYMENT_REQUEST_SET_CURRENT_PAYER';
export const SHOW_ADD_PAYER_FORM = 'SHOW_ADD_PAYER_FORM';

export const VRA_STATEMENT_REQUEST = 'VRA_STATEMENT_REQUEST';

export const PAYIN_WITHDRAW_FUND_CREATE_RESET =
    'PAYIN_WITHDRAW_FUND_CREATE_RESET';
export const PAYIN_WITHDRAW_FUND_CREATE_REQUEST =
    'PAYIN_WITHDRAW_FUND_CREATE_REQUEST';
export const PAYIN_WITHDRAW_FUND_CREATE_RECEIVE =
    'PAYIN_WITHDRAW_FUND_CREATE_RECEIVE';
export const PAYIN_WITHDRAW_FUND_HISTORY_REQUEST =
    'PAYIN_WITHDRAW_FUND_HISTORY_REQUEST';
export const PAYIN_WITHDRAW_FUND_HISTORY_RECEIVE =
    'PAYIN_WITHDRAW_FUND_HISTORY_RECEIVE';
export const PAYIN_ACCOUNT_REQUEST = 'PAYIN_ACCOUNT_REQUEST';
export const PAYIN_ACCOUNT_RECEIVE = 'PAYIN_ACCOUNT_RECEIVE';
// VRAS means virtual receiving account statement, i.e. list of transactions
export const GET_VRAS = 'GET_VRAS';

export const POST_ADD_BANK_REQUEST = 'POST_ADD_BANK_REQUEST';
export const POST_ADD_BANK_SUCCESS = 'POST_ADD_BANK_SUCCESS';
export const POST_ADD_BANK_FAILURE = 'POST_ADD_BANK_FAILURE';

export const ADD_BANK = {
    REQUEST: 'ADD_BANK_REQUEST',
    SUCCESS: 'ADD_BANK_SUCCESS',
    FAILURE: 'ADD_BANK_FAILURE',
};
// rfi
export const PAYIN_GET_RFI_LIST_REQUEST = 'PAYIN_GET_RFI_LIST_REQUEST';
export const PAYIN_GET_RFI_LIST_SUCCESS = 'PAYIN_GET_RFI_LIST_SUCCESS';
export const PAYIN_GET_RFI_LIST_FAILURE = 'PAYIN_GET_RFI_LIST_FAILURE';

export const PAYIN_RESPOND_RFI_REQUEST = 'PAYIN_RESPOND_RFI_REQUEST';
export const PAYIN_RESPOND_RFI_SUCCESS = 'PAYIN_RESPOND_RFI_SUCCESS';
export const PAYIN_RESPOND_RFI_FAILURE = 'PAYIN_RESPOND_RFI_FAILURE';

export const PAYIN_SET_SUPPLIER_RFI_COMMENT = 'SET_SUPPLIER_COMPLIANCE_COMMENT';
export const PAYIN_SET_SUPPLIER_RFI_FILE = 'SET_SUPPLIER_RFI_FILE';
export const PAYIN_SUBMIT_ALL_RAISED_RFI_REQUESTS =
    'PAYIN_SUBMIT_ALL_RAISED_RFI_REQUESTS';
export const SET_NUMBER_OF_RFI_FOR_COMPLIANCE =
    'SET_NUMBER_OF_RFI_FOR_COMPLIANCE';
export const DOWNLOAD_RFI_CLIENT_DOCUMENT_REQUEST =
    'DOWNLOAD_RFI_CLIENT_DOCUMENT_REQUEST';
export const DOWNLOAD_RFI_CLIENT_DOCUMENT_SUCCESS =
    'DOWNLOAD_RFI_CLIENT_DOCUMENT_SUCCESS';
export const DOWNLOAD_RFI_CLIENT_DOCUMENT_FAILURE =
    'DOWNLOAD_RFI_CLIENT_DOCUMENT_FAILURE';
export const EMPTY_RFI_CLIENT_RESPONSE = 'EMPTY_RFI_CLIENT_RESPONSE';
export const CHANGE_TRANSACTION_COMPLIANCE_STATUS =
    'CHANGE_TRANSACTION_COMPLIANCE_STATUS';

export const VRA_TAG_UNTAG_PAYER_REQUEST = 'VRA_TAG_UNTAG_PAYER_REQUEST';

export const PAYIN_PAYERS_LIST_REQUEST = 'PAYIN_PAYERS_LIST_REQUEST';
export const PAYIN_PAYERS_LIST_RECEIVE = 'PAYIN_PAYERS_LIST_RECEIVE';

export const PAYIN_PAYERS_LOOKUP_REQUEST = 'PAYIN_PAYERS_LOOKUP_REQUEST';
export const PAYIN_PAYERS_LOOKUP_RECEIVE = 'PAYIN_PAYERS_LOOKUP_RECEIVE';

export const PAYIN_PAYMENT_REQUEST_REMIND_RESPONSE =
    'PAYIN_PAYMENT_REQUEST_REMIND_RESPONSE';

export const MARK_PAID_SUCCESS = 'MARK_PAID_SUCCESS';
export const MARK_CANCELLED_SUCCESS = 'MARK_CANCELLED_SUCCESS';
export const PAYMENT_REMINDER = 'PAYMENT_REMINDER';

//popup constants
export const MARK_PAID = 'MARK_PAID';
export const MARK_CANCEL = 'MARK_CANCEL';
export const CANCEL_PAYMENT_REQUEST = 'CANCEL_PAYMENT_REQUEST';

export const SAVE_TRACK_PR_FILTER = 'SAVE_TRACK_PR_FILTER';

export const SET_SELECTED_VRA_CURRENCY = 'SET_SELECTED_VRA_CURRENCY';

export const PAYIN_VIRTUAL_ACCOUNTS_FILTER_REQUEST =
    'PAYIN_VIRTUAL_ACCOUNTS_FILTER_REQUEST';
export const PAYIN_VIRTUAL_ACCOUNTS_FILTER_SUCCESS =
    'PAYIN_VIRTUAL_ACCOUNTS_FILTER_SUCCESS';
export const PAYIN_VIRTUAL_ACCOUNTS_FILTER_FAILURE =
    'PAYIN_VIRTUAL_ACCOUNTS_FILTER_FAILURE';

export const CLIENT_VRA_ALLOCATION_REQUEST = 'CLIENT_VRA_ALLOCATION_REQUEST';
export const CLIENT_VRA_ALLOCATION_SUCCESS = 'CLIENT_VRA_ALLOCATION_SUCCESS';
export const CLIENT_VRA_ALLOCATION_FAILURE = 'CLIENT_VRA_ALLOCATION_FAILURE';
export const CLIENT_VRA_REQUEST_SUCCESS = 'CLIENT_VRA_REQUEST_SUCCESS';
// bank account detail request
export const GET_BANK_ACCOUNT_REQUEST = 'GET_BANK_ACCOUNT_REQUEST';
export const GET_BANK_ACCOUNT_RESPONSE = 'GET_BANK_ACCOUNT_RESPONSE';
export const DEFAULT_BANK_ACCOUNT_REQUEST = 'DEFAULT_BANK_ACCOUNT_REQUEST';
export const DEFAULT_BANK_ACCOUNT_RESPONSE = 'DEFAULT_BANK_ACCOUNT_RESPONSE';

//Client Changed
export const EMPTY_PAYIN_CLIENT_DATA = 'EMPTY_PAYIN_CLIENT_DATA';
export const ADD_PAYER_LOCALLY = 'ADD_PAYER_LOCALLY';

export const PAYER_INPUT = 'PAYER_INPUT';
export const SHOW_PAYERS_LIST = 'SHOW_PAYERS_LIST';